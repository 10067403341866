import React, { useEffect, useState } from "react";
import ReviewReportDetails from "../ReviewReportDetails";
import RevisionPaperForm from "../RevisionPaperForm";
import Button from "components/form/Button";
import { icons } from "utils/constants";
import { useParams } from "react-router-dom";
// import { useDispatch } from "react-redux";

const UserReviewState = ({
  data,
  paperData,
  handleSuccess,
  setIsAddRevisionAbstract,
}) => {
  // const dispatch = useDispatch();
  const params = useParams();
  const {
    // paperType,
    paperId,
  } = params;
  const [isOldReport, setIsOldReport] = useState(null);
  // const [isReviewReport, setIsReviewReport] = useState(false);
  const [isReviewRevisions, setIsReviewRevisions] = useState(false);
  // const [isRevisionDetails, setIsRevisionDetails] = useState(null);
  // const [expandedIndexes, setExpandedIndexes] = useState({});

  const {
    status,
    // reviewData,
    // plagiarismStatus,
    is_remodify,
  } = data;

  const {
    submittedPapersStatus,
    // new_reviewer_revisoin,
    // old_reviewer_revisoin,
    abstract_reviews: oldReports,
    // final_review_report,
  } = paperData || {};
  const {
    // reject_remarks,
    revision_status,
    is_plagiarism_done,
  } = submittedPapersStatus || {};

  // const plagiarismAccepted = +plagiarismStatus === 1;

  const isReview = ["2", "3"].includes(is_remodify);
  const isReviewRevision = is_remodify === "1";
  const isActiveReview = ["1", "2", "3"].includes(is_remodify);

  // const maxWords = 70;
  // const toggleRevisionReadMore = (index) => {
  //   setExpandedIndexes((prev) => ({
  //     ...prev,
  //     [index]: !prev[index],
  //   }));
  // };
  useEffect(() => {
    if (oldReports?.length >= 0) {
      setIsOldReport(oldReports?.length - 1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldReports]);

  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div
          className={`steps-block ${
            isActiveReview && status >= 2 ? "active" : "not-active"
          }`}
        >
          {isReview ? <img src={icons?.RightIcon} alt="right" /> : 3}
        </div>
        <div>
          <div
            className={`text-16-500-20 ${
              isActiveReview && status >= 2 ? "color-new-car" : "color-6866"
            }`}
          >
            Review
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <div className="sparator-block">
          <span className="hr-line" />
        </div>
        {is_plagiarism_done === "1" || status >= 3 ? (
          /* {plagiarismAccepted && status >= 3 ? ( */
          <>
            <div className="cpt-14 cpb-20 w-100 ">
              {isReview ? (
                <div className="w-100">
                  {oldReports?.map((oldReport, i) => {
                    return (
                      <div
                        className="w-100 text-14-400-18 color-black-olive border br-5 w-100 cmb-8"
                        key={i + 1}
                      >
                        <div
                          className="cpt-14 cpb-14 d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                          onClick={() => {
                            setIsOldReport(isOldReport === i ? null : i);
                          }}
                        >
                          {i === 0 && <span>Your Review Report</span>}
                          {i > 0 && <span>Revision {i}</span>}
                          <i
                            className={`${
                              isOldReport === i
                                ? "bi bi-chevron-up"
                                : "bi bi-chevron-down"
                            }`}
                            style={{ fontSize: "15px" }}
                          />
                        </div>
                        {isOldReport === i && (
                          <>
                            <ReviewReportDetails
                              elem={oldReport}
                              paperData={paperData}
                              isOldReport
                            />
                            {/* {new_reviewer_revisoin?.length === 0 &&
                                    old_reviewer_revisoin?.length === 0 &&
                                    revision_status === "2" &&
                                    status === 3 && ( */}
                          </>
                        )}
                      </div>
                    );
                  })}
                  {/* {paperData?.final_review_report ? (
                    <React.Fragment>
                      <div className="review-block border br-5 w-100 cmb-18">
                        <div
                          className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12"
                          onClick={() => {
                            setIsReviewReport(!isReviewReport);
                          }}
                        >
                          <span> Your Final Review Report </span>
                          <i
                            className={`${
                              isReviewReport
                                ? "bi bi-chevron-up"
                                : "bi bi-chevron-down"
                            }`}
                            style={{ fontSize: "15px" }}
                          />
                        </div>

                        {isReviewReport && (
                          <ReviewReportDetails
                            elem={final_review_report}
                            paperData={paperData}
                            isFinalReport
                          />
                        )}
                      </div>
                      <div className="text-20-400-25 color-1717 cmb-13">
                        {`Your score is ${final_review_report?.overall} ${
                          final_review_report?.overall > 0.0
                            ? `out of 5 (${
                                final_review_report?.overall <= 1
                                  ? "Poor"
                                  : final_review_report?.overall <= 2
                                  ? "Fair"
                                  : final_review_report?.overall <= 3
                                  ? "Good"
                                  : "Excellent"
                              })`
                            : 0
                        }`}
                      </div>
                      <div className="text-16-300-20 color-1717 cmb-19">
                        {` Review has been completed!!`}
                      </div>
                    </React.Fragment>
                  ) : (
                    reviewData?.map((elem, index) => {
                      const { overall } = elem || {};
                      return (
                        <React.Fragment key={index}>
                          <div className="review-block border br-5 w-100 cmb-18">
                            <div
                              className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12"
                              onClick={() => {
                                setIsReviewReport(!isReviewReport);
                              }}
                            >
                              <span>Your Review Report {index + 1}</span>
                              <i
                                className={`${
                                  isReviewReport
                                    ? "bi bi-chevron-up"
                                    : "bi bi-chevron-down"
                                }`}
                                style={{ fontSize: "15px" }}
                              />
                            </div>

                            {isReviewReport && (
                              <ReviewReportDetails
                                elem={elem}
                                paperData={paperData}
                              />
                            )}
                          </div>
                          <div className="text-20-400-25 color-1717 cmb-13">
                            {`Your score is ${overall} ${
                              overall > 0.0
                                ? `out of 5 (${
                                    overall <= 1
                                      ? "Poor"
                                      : overall <= 2
                                      ? "Fair"
                                      : overall <= 3
                                      ? "Good"
                                      : "Excellent"
                                  })`
                                : 0
                            }`}
                          </div>
                          <div className="text-16-300-20 color-1717 cmb-19">
                            {` Review has been ${
                              is_remodify === "3" ? "rejected" : "completed"
                            } !!`}
                          </div>
                          {is_remodify === "3" && (
                            <div className="text-16-300-20 color-1717 cmb-19">
                              Reject Reason: {titleCaseString(reject_remarks)}
                            </div>
                          )}
                        </React.Fragment>
                      );
                    })
                  )} */}
                </div>
              ) : isReviewRevision ? (
                <>
                  {isReviewRevisions ? (
                    <RevisionPaperForm
                      abstractId={paperId}
                      onHide={() => {
                        setIsReviewRevisions(false);
                      }}
                      handleSuccess={handleSuccess}
                      isReviewState
                    />
                  ) : (
                    <>
                      {/* {new_reviewer_revisoin?.length === 0 && ( */}
                      <>
                        {oldReports?.map((oldReport, i) => {
                          return (
                            <div
                              className="w-100 text-14-400-18 color-black-olive border br-5 w-100 cmb-8"
                              key={i + 1}
                            >
                              <div
                                className="cpt-14 cpb-14 d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                                onClick={() => {
                                  setIsOldReport(isOldReport === i ? null : i);
                                }}
                              >
                                {i === 0 && <span>Your Review Report</span>}
                                {i > 0 && <span>Revision {i}</span>}
                                <i
                                  className={`${
                                    isOldReport === i
                                      ? "bi bi-chevron-up"
                                      : "bi bi-chevron-down"
                                  }`}
                                  style={{ fontSize: "15px" }}
                                />
                              </div>
                              {isOldReport === i && (
                                <>
                                  <ReviewReportDetails
                                    elem={oldReport}
                                    paperData={paperData}
                                    isOldReport
                                  />
                                  {/* {new_reviewer_revisoin?.length === 0 &&
                                    old_reviewer_revisoin?.length === 0 &&
                                    revision_status === "2" &&
                                    status === 3 && ( */}
                                  {oldReports?.length - 1 === i &&
                                    is_plagiarism_done === "0" &&
                                    revision_status === "2" &&
                                    status === 3 &&
                                    isReviewRevision && (
                                      <div className="text-14-400-17 color-6866 cmb-18 cps-24">
                                        <div className="d-flex">
                                          <Button
                                            text={`+ Add Abstract`}
                                            btnStyle="primary-dark"
                                            className="text-15-500 cps-15 cpe-15 h-30"
                                            onClick={() => {
                                              setIsAddRevisionAbstract(true);
                                              // setIsReviewRevisions(true);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                </>
                              )}
                            </div>
                          );
                        })}

                        {/* {old_reviewer_revisoin?.map((oldReport, i) => {
                          const newIndex = oldReports?.length + i;
                          return (
                            <div className="submitted-paper-block border br-5 w-100 cmb-8">
                              <div
                                className="cpt-14 cpb-14 d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                                onClick={() => {
                                  setIsOldReport(
                                    isOldReport === newIndex ? null : newIndex
                                  );
                                }}
                              >
                                <span>Old Peer Review Report {i + 2}</span>
                                <i
                                  className={`${
                                    isOldReport === newIndex
                                      ? "bi bi-chevron-up"
                                      : "bi bi-chevron-down"
                                  }`}
                                  style={{ fontSize: "15px" }}
                                />
                              </div>
                              {isOldReport === newIndex && (
                                <>
                                  <ReviewReportDetails
                                    elem={oldReport}
                                    paperData={paperData}
                                    isNewReportDetails
                                    isOldReport
                                  />
                                  {new_reviewer_revisoin?.length === 0 &&
                                    old_reviewer_revisoin?.length > 0 &&
                                    old_reviewer_revisoin?.length - 1 === i &&
                                    revision_status === "2" &&
                                    status === 3 && (
                                      <div className="text-14-400-17 color-6866 cmb-18 cmt-16 cps-24">
                                        <div className="d-flex">
                                          <Button
                                            text={`+ Add Abstract`}
                                            btnStyle="primary-dark"
                                            className="text-15-500 cps-15 cpe-15 h-30"
                                            onClick={() => {
                                              setIsReviewRevisions(true);
                                            }}
                                          />
                                        </div>
                                      </div>
                                    )}
                                </>
                              )}
                            </div>
                          );
                        })} */}
                      </>
                      {/* )} */}

                      {/* {new_reviewer_revisoin?.length > 0 && (
                        <div className="w-100">
                          {new_reviewer_revisoin?.map((details, index) => {
                            const {
                              abstract_path,
                              revision_date,
                              paper_abstract,
                              reviewer_result,
                            } = details;
                            const isOpen = isRevisionDetails === index;
                            const isExpanded = expandedIndexes[index];
                            const isReviewReportDetails =
                              reviewer_result?.overall;

                            return (
                              <React.Fragment key={index}>
                                <div
                                  className="revision-block border br-5 w-100 cmb-8"
                                  key={index + 1}
                                >
                                  <div
                                    className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12"
                                    onClick={() => {
                                      setIsRevisionDetails(
                                        isOpen ? null : index
                                      );
                                    }}
                                  >
                                    <span>{`${
                                      isReviewReportDetails
                                        ? "New Peer Review Report"
                                        : "Revision"
                                    } ${index + 1}`}</span>
                                    <i
                                      className={`${
                                        isOpen
                                          ? "bi bi-chevron-up"
                                          : "bi bi-chevron-down"
                                      }`}
                                      style={{ fontSize: "15px" }}
                                    />
                                  </div>

                                  {isOpen &&
                                    (isReviewReportDetails ? (
                                      <ReviewReportDetails
                                        elem={details}
                                        paperData={paperData}
                                        isNewReportDetails
                                      />
                                    ) : (
                                      <div className="cps-24 cpt-12 cpe-24 cpb-12">
                                        {revision_date && (
                                          <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 cmb-14">
                                            <img
                                              src={icons.calendarCheck}
                                              alt="calendar"
                                            />
                                            {formatDate(revision_date)}
                                          </div>
                                        )}
                                        {paper_abstract && (
                                          <div className="cmb-16 text-14-300-24 color-black-olive">
                                            <div>
                                              {isExpanded ? (
                                                <span
                                                  dangerouslySetInnerHTML={{
                                                    __html: paper_abstract,
                                                  }}
                                                />
                                              ) : (
                                                <>
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html: paper_abstract
                                                        ?.replace(/<\/?p>/g, "")
                                                        ?.replace(
                                                          /<\/?span[^>]*>/g,
                                                          ""
                                                        )
                                                        ?.split(" ")
                                                        ?.slice(0, maxWords)
                                                        ?.join(" "),
                                                    }}
                                                  />
                                                  {paper_abstract?.split(" ")
                                                    ?.length > maxWords && (
                                                    <>
                                                      {"... "}
                                                      <span
                                                        onClick={() =>
                                                          toggleRevisionReadMore(
                                                            index
                                                          )
                                                        }
                                                        className="text-14-500-23 color-new-car pointer"
                                                      >
                                                        Read More
                                                      </span>
                                                    </>
                                                  )}
                                                </>
                                              )}
                                            </div>

                                            {isExpanded && (
                                              <span
                                                onClick={() =>
                                                  toggleRevisionReadMore(index)
                                                }
                                                className="text-14-500-23 color-new-car pointer"
                                              >
                                                Read Less
                                              </span>
                                            )}
                                          </div>
                                        )}
                                        <div
                                          className="cmb-16 text-16-500-20 color-7cff pointer"
                                          onClick={async () => {
                                            if (abstract_path) {
                                              let downloadContent = "";
                                              if (paperType === "abstract") {
                                                downloadContent =
                                                  await generatePreSignedUrl(
                                                    abstract_path,
                                                    eventAbstarctPath
                                                  );
                                              }
                                              dispatch(
                                                downloadFile(downloadContent)
                                              );
                                            }
                                          }}
                                        >
                                          Download Abstract File
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      )} */}
                    </>
                  )}
                </>
              ) : (
                <div className="text-14-400-17 color-6866 cmb-18 cmt-16">
                  Review is under processing
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="w-100 cpt-20 cpb-20"></div>
        )}
      </div>
    </>
  );
};

export default UserReviewState;

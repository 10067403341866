import React from "react";

const AcceptanceTemplatePreview = ({ values, templatePreviewRef }) => {
  const {
    client_name,
    conference_organizer,
    paper_title,
    presentation_type,
    conference_name,
    date,
    location,
    paper_id,
    coAuthor_name,
    session_name,
    relevant_field,
    video_link,
    registration_deadline,
    complete_registration,
    support_email,
    conference_theme,
    your_organization_name,
    your_contact_information,
    your_website,
  } = values || {};
  return (
    <div ref={templatePreviewRef}>
      <p>
        {client_name
          ? `Dear ${client_name},`
          : ` Dear [Client Name] / Dr. [Client Name],`}
      </p>

      <p>
        Greetings from{" "}
        {conference_organizer
          ? conference_organizer
          : `[Conference Organizer/IFERP]`}
        !
      </p>
      <p>
        We are delighted to inform you that your article titled
        <strong>
          {paper_title ? ` "${paper_title}" ` : ` "{PaperTitle}" `}
        </strong>{" "}
        has been accepted for{" "}
        <strong>
          {presentation_type ? presentation_type : `[Presentation Type]`}
        </strong>{" "}
        at the upcoming{" "}
        <strong>
          {conference_name ? conference_name : `[Conference Name]`}
        </strong>
        , scheduled to take place on <strong>{date ? date : `[Date]`}</strong>{" "}
        in <strong>{location ? location : `[Location]`}</strong>.
      </p>

      <p>
        <strong>Key Details:</strong>
      </p>

      <ul>
        <li>
          <strong>Paper ID:</strong>{" "}
          <strong>{paper_id ? paper_id : `[paperID]`}</strong>
        </li>
        {coAuthor_name && (
          <li>
            <strong>Authors:</strong> You and your co-author,{" "}
            {
              <strong>
                {coAuthor_name ? coAuthor_name : `[CoAuthor Name]`}
              </strong>
            }
          </li>
        )}
        <li>
          <strong>Session:</strong>{" "}
          <strong>{session_name ? session_name : `[Session Name]`}</strong>
        </li>
      </ul>
      <p>
        Your submission has successfully passed our rigorous double-blind peer
        review and plagiarism check, demonstrating the significance and quality
        of your work. Congratulations on this milestone!
      </p>

      <p>
        <strong>Session Information:</strong>
      </p>
      <ul>
        <li>
          <strong>Focus Area:</strong> Groundbreaking research in{" "}
          <strong>
            {relevant_field ? relevant_field : `[Relevant Field]`}
          </strong>
        </li>
        <li>
          <strong>Session Time:</strong> Your paper will be featured in a
          session fostering vibrant discussions and knowledge exchange.
        </li>
        <li>
          <strong>Engagement Tools:</strong> We encourage you to share a{" "}
          {video_link ? (
            <a href={video_link}>{video_link} </a>
          ) : (
            <>
              <strong>video teaser and infographic</strong> (if available) to
              highlight your key themes to the audience.
            </>
          )}
        </li>
      </ul>

      <p>
        <strong>Next Steps:</strong>
      </p>
      <p>
        To complete your participation, kindly register for the conference by{" "}
        <strong>
          {registration_deadline
            ? registration_deadline
            : `[Registration Deadline]`}
        </strong>
        . We also recommend preparing any additional materials that may be
        required for your session.
      </p>
      <p>
        <strong>CTA: </strong>
        {complete_registration ? (
          <a href={complete_registration}>{complete_registration}</a>
        ) : (
          `[Complete Registration]`
        )}
      </p>

      <p>
        For further details, special requests, or support, feel free to contact
        us at{" "}
        <strong>{support_email ? support_email : `[SupportEmail]`}</strong>.
      </p>

      <p>
        <strong>Indexing and Publication:</strong>
      </p>
      <p>
        Your paper will be submitted for evaluation and indexing in Web of
        Science (BkCI) and SCOPUS (terms and conditions apply). This will
        enhance the visibility and impact of your research.
      </p>

      <p>
        <strong>Conference Overview:</strong>
      </p>
      <p>
        <strong>
          {conference_name ? conference_name : `[Conference Name]`}
        </strong>{" "}
        will bring together leading academics and scholars to discuss{" "}
        <strong>
          {conference_theme ? conference_theme : `[Conference Theme]`}
        </strong>
        . The event aligns with the United Nations Sustainable Development Goals
        (SDGs), fostering research that contributes to global progress.
      </p>

      <p>
        We look forward to your valuable contribution to{" "}
        <strong>
          {conference_name ? conference_name : `[Conference Name]`}
        </strong>{" "}
        and are excited to see the impact your research will have!
      </p>

      <div>Best regards,</div>
      <div>
        {your_organization_name ? (
          your_organization_name
        ) : (
          <strong> [Your Organization’s Name] </strong>
        )}
      </div>
      <div>
        {your_contact_information
          ? your_contact_information
          : `[Your Contact Information]`}
      </div>
      <div>
        {your_website ? (
          <a href={your_website}>{your_website}</a>
        ) : (
          `[Your Website]`
        )}
      </div>
    </div>
  );
};

export default AcceptanceTemplatePreview;

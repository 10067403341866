import React, { useEffect, useState } from "react";
import { objectToFormData, titleCaseString } from "utils/helpers";
import TableV2 from "components/Layout/TableV2";
import { useDispatch } from "react-redux";
import { getmentorApprovalsList } from "store/slices";
import "./RejectedRequests.scss";
import moment from "moment";

const RejectedRequests = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchPayload, setSearchPayload] = useState({
    mentor_name: "",
    membership_type: "",
    mentor_id: "",
    name: "",
    set_on: "",
    status: "",
  });
  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: 10,
    reject_status: "rejected",
  });
  const fetchMentorRejectedList = async (obj) => {
    setIsLoading(true);
    let formData = objectToFormData(obj);
    const response = await dispatch(getmentorApprovalsList(formData));

    let resList = [];
    let resResultCount = 0;
    if (response?.data?.session_list) {
      resList = response?.data?.session_list || [];
      resResultCount = response?.data?.result_count || 0;
    }
    setList(resList);
    setFilterData({
      ...obj,
      total: resResultCount,
    });
    window.scrollTo(0, 0);
    setIsLoading(false);
  };

  const handelChangeSearch = (searchData) => {
    setIsLoading(true);
    let newData = filterData;
    setSearchPayload(searchData);
    newData = { ...newData, ...searchData, offset: 0 };
    setFilterData(newData);
    fetchMentorRejectedList(newData);
  };

  const handelChangePagination = (offset) => {
    setIsLoading(true);
    let newData = { ...filterData, ...searchPayload };
    newData = { ...newData, offset: offset };
    setFilterData(newData);
    fetchMentorRejectedList(newData);
  };

  useEffect(() => {
    fetchMentorRejectedList({ ...searchPayload, ...filterData });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = [
    {
      isSearch: true,
      searchInputName: "mentor_name",
      title: <div className="text-nowrap">Mentor Name</div>,
    },
    {
      isSearch: true,
      searchInputName: "mentor_id",
      title: <div className="text-nowrap">Mentor ID</div>,
    },
    {
      isSearch: true,
      searchInputName: "membership_type",
      title: <div className="text-nowrap">Membership Type</div>,
    },
    {
      isSearch: true,
      searchInputName: "set_on",
      isDatePicker: true,
      title: <div className="text-nowrap">Sent On</div>,
    },
    {
      isSearch: true,
      searchInputName: "status",
      isSearchDropdown: true,
      dropdownOptions: {
        options: [
          { id: "0", label: "Pending" },
          { id: "2", label: "Rejected" },
        ],
        key: "id",
        value: "label",
      },
      title: <div className="text-nowrap">Status</div>,
    },
    {
      isSearch: false,
      searchLable: "",
      title: "Rejected Reason",
    },
  ];

  const rowData = [];
  list?.forEach((elem) => {
    const { name, mentor_id, membership_type, set_on, status, reason } = elem;
    let obj = [
      {
        value: (
          <div className="text-14-500 text-nowrap">{titleCaseString(name)}</div>
        ),
      },
      {
        value: mentor_id,
      },
      {
        value: (
          <div className="text-nowrap">{titleCaseString(membership_type)}</div>
        ),
      },
      {
        value: (
          <div className="text-nowrap">
            {set_on ? moment(set_on).format("DD MMM YYYY") : "-"}
          </div>
        ),
      },
      {
        value: <div className="text-nowrap">{titleCaseString(status)}</div>,
      },
      {
        value: <div className="text-nowrap">{titleCaseString(reason)}</div>,
      },
    ];
    rowData?.push({ data: obj });
  });
  return (
    <div id="all-mentors-container">
      <div className="overflow-auto">
        <TableV2
          isLoading={isLoading}
          header={header}
          rowData={rowData}
          filterData={filterData}
          searchPayload={searchPayload}
          searchInputChange={handelChangeSearch}
          changeOffset={handelChangePagination}
        />
      </div>
    </div>
  );
};

export default RejectedRequests;

import Card from "components/Layout/Card";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { titleCaseString } from "utils/helpers";

const MyOldData = ({ myTaskDetails }) => {
  const [scores, setScores] = useState([
    { id: "originality", label: "Originality", score: 0 },
    {
      id: "contribution_to_the_field",
      label: "Contribution to the Field",
      score: 0,
    },
    { id: "technical_quality", label: "Technical Quality", score: 0 },
    {
      id: "clarity_of_presentation",
      label: "Clarity of Presentation",
      score: 0,
    },
    { id: "depth_of_research", label: "Depth of Research", score: 0 },
  ]);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (myTaskDetails?.result) {
      const updatedScores = scores?.map((s) => {
        if (Object.keys(myTaskDetails?.result)?.includes(s.id)) {
          return {
            ...s,
            score: myTaskDetails?.result[s?.id], // Update the score with the API value
          };
        }
        return s;
      });

      setScores(updatedScores);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTaskDetails?.result]);

  const toggleReadMore = () => {
    setIsExpanded((prev) => !prev);
  };

  const {
    conference_name,
    paper_title,
    allocated_point,
    paper_keywords,
    paper_abstract,
    allocated_time_limit,
    paper_abstract_id,
    abstract_result,
    general_comment,
    recommendation,
  } = myTaskDetails || {};

  const abstractText = paper_abstract
    ?.replace(/<\/?p>/g, "")
    ?.replace(/<\/?span[^>]*>/g, "")
    ?.trim();

  const abstractWords = abstractText?.split(" ")?.filter((word) => word);
  const maxWords = 70;

  const averageScore = useMemo(() => {
    const totalScore = scores?.reduce((sum, item) => sum + item?.score, 0);
    return (totalScore / scores?.length)?.toFixed(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scores]);

  return (
    <>
      <Card className="cps-44 cpt-32 cpe-44 cpb-32 cmb-20">
        <h2 className="text-16-400-20 color-new-car cmb-20">
          Conference & Abstract Info :
        </h2>
        <div className="text-14-400-17 color-new-car">Conference Name:</div>
        {conference_name && (
          <p className="text-14-300-24 color-black-olive cmb-20">
            {titleCaseString(conference_name)}
          </p>
        )}

        <div className="row cmb-20">
          <div className="col-lg-6">
            <div className="text-14-400-17 color-new-car">Paper Title:</div>
            {paper_title && (
              <p className="text-14-300-24 color-black-olive cmb-20">
                {titleCaseString(paper_title)}
              </p>
            )}
          </div>

          <div className="col-lg-6">
            <div className="text-14-400-17 color-new-car">Allocate Points:</div>
            {allocated_point && (
              <span className="text-14-300-24 color-black-olive">
                {allocated_point}
              </span>
            )}
          </div>
        </div>

        {paper_keywords && (
          <div className="d-flex align-items-center gap-4">
            <div className="text-14-400-17 color-new-car">Keywords:</div>
            <div className="d-flex align-items-center gap-2 flex-wrap">
              {paper_keywords?.split(",")?.map((words, index) => {
                return (
                  <span
                    key={index}
                    className="bg-f4ff color-new-car cps-8 cpe-8 cpt-2 cpb-2 br-2 text-14-400-17"
                  >
                    {words}
                  </span>
                );
              })}
            </div>
          </div>
        )}
      </Card>

      <Card className="cps-44 cpt-32 cpe-44 cpb-32">
        <h2 className="text-16-500-20 color-new-car cmb-28">Abstract :</h2>

        <div className="text-14-300-24 color-black-olive cmb-28">
          <div>
            {isExpanded ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: paper_abstract,
                }}
              />
            ) : (
              <>
                <span
                  dangerouslySetInnerHTML={{
                    __html: paper_abstract
                      ?.replace(/<\/?p>/g, "")
                      ?.replace(/<\/?span[^>]*>/g, "")
                      ?.split(" ")
                      ?.slice(0, maxWords)
                      ?.join(" "),
                  }}
                />

                {abstractWords?.length > maxWords && (
                  <>
                    {"... "}
                    <span
                      onClick={toggleReadMore}
                      className="text-14-500-23 color-new-car pointer"
                    >
                      Read More
                    </span>
                  </>
                )}
              </>
            )}
          </div>

          {isExpanded && (
            <span
              onClick={toggleReadMore}
              className="text-14-500-23 color-new-car pointer"
            >
              Read Less
            </span>
          )}
        </div>

        <>
          {allocated_time_limit && (
            <div className="text-16-400-20 color-1717 cmb-29">
              {`You have to complete the review on or before ${moment(
                allocated_time_limit
              ).format("DD-MMM-YYYY")}, ${moment(allocated_time_limit).format(
                "HH:MM A"
              )} and Allocated ${allocated_point} points for this paper.`}
            </div>
          )}
          <div className="text-16-400-20 color-1717 cpb-16 cmb-24 border-bottom">
            Peer Review Report :
          </div>

          <div className="row gy-3 mb-md-4 mb-lg-0">
            <div className="col-lg-7">
              <div className="text-14-500-18 color-1717 cmb-12">
                Abstract ID :
              </div>
              {paper_abstract_id && (
                <div className="text-14-300-18 color-6866 cmb-24">
                  {paper_abstract_id}
                </div>
              )}

              <div className="text-14-500-18 color-1717 cmb-12">
                Manuscript Title :
              </div>
              {paper_title && (
                <div className="text-14-300-18 color-6866 cmb-24">
                  {titleCaseString(paper_title)}
                </div>
              )}

              <div className="text-14-500-18 color-1717 cmb-12">
                Recommendation :
              </div>
              {recommendation && (
                <div className="text-14-300-18 color-6866 cmb-24">
                  {recommendation}
                </div>
              )}

              <div className="text-14-500-18 color-1717 cmb-12">
                General Comments :
              </div>
              {general_comment && (
                <div className="text-14-300-18 color-6866 cmb-24">
                  {general_comment}
                </div>
              )}
            </div>
            <div className="col-lg-5">
              <div className="text-14-500-18 color-1717 cmb-12">
                Followed Rates :
              </div>
              <div className="text-14-300-17 color-6866 cmb-24">
                (1 = Poor) , (2 = Fair) , (3 = Good) , (4 = Excellent)
              </div>
              <div className="border br-5 cpt-25 cpb-16">
                <div className="d-flex align-items-center justify-content-center text-16-500-200 color-new-car border-bottom cpb-16">
                  Evaluation Report
                </div>
                <div className="cpt-23 cpb-16 border-bottom">
                  {scores.map((item, index) => (
                    <div
                      className="d-flex align-items-center cmb-16 text-14-500-18 color-1717 row"
                      key={index}
                    >
                      <div className="d-flex justify-content-end col-lg-6 col-md-6 col-sm-4">
                        {item.label}{" "}
                        <span className="ms-lg-4 ms-md-4 ms-sm-3">:</span>
                      </div>
                      <div className="d-flex justify-content-start gap-3 col-lg-6 col-md-6 col-sm-8 align-items-center">
                        <span className="text-14-500-18 color-1717 input-border">
                          {item?.score}
                        </span>{" "}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="d-flex align-items-center text-14-500-18 color-1717 cpt-16 row">
                  <div className="d-flex justify-content-end col-lg-6 col-md-6 col-sm-4">
                    Overall
                    <span className="ms-lg-4 ms-md-4 ms-sm-3">:</span>
                  </div>
                  <div className="d-flex justify-content-start col-lg-6 col-md-6 col-sm-8">
                    {`${averageScore} ${
                      averageScore > 0.0
                        ? `(${
                            averageScore <= 1
                              ? "Poor"
                              : averageScore <= 2
                              ? "Fair"
                              : averageScore <= 3
                              ? "Good"
                              : "Excellent"
                          })`
                        : ""
                    }`}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {abstract_result && (
            <>
              <div className="text-14-400-18 color-black-olive cmb-18 ">
                Abstract Result :
              </div>
              <div className="cmb-26">{titleCaseString(abstract_result)}</div>
            </>
          )}
        </>
      </Card>
    </>
  );
};

export default MyOldData;

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Login from "pages/Auth/Login";
import Register from "pages/Auth/Signup/Register";
import Selection from "pages/Auth/Signup/Selection";
import ForgotPassword from "pages/Auth/ForgotPassword";
import { commonRoute } from "utils/constants";
import Temp from "../pages/temp";
import TempCancel from "../pages/TempCancel";
import TempSuccess from "../pages/TempSuccess";
import GlobalPost from "pages/GlobalPost";
import EmailRedirection from "components/Layout/EmailRedirection";
import SpeakerRegister from "pages/Auth/Signup/SpeakerRegister/SpeakerRegister";
import NewGlobalResearchProfile from "pages/NewGlobalResearchProfile";
import CampaignLogin from "pages/Auth/CampaignLogin";
import UnSubscribePage from "pages/UnSubscribePage";
// import Success from "../pages/Auth/Signup/Success";

const AuthRoutes = () => {
  const [isCampaignLogin, setIsCampaignLogin] = useState(false);
  const loginRoute = window.location.pathname.includes("admin")
    ? "/admin/login"
    : "/member/login";

  useEffect(() => {
    if (window.location.pathname === "/campaign/login") {
      let urlParams = window.location.search;
      if (urlParams) {
        localStorage.setItem(
          "isCampaign",
          urlParams?.includes("?type=campaign")
        );
        setIsCampaignLogin(urlParams?.includes("?type=campaign"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path={commonRoute.unSubscribe} element={<UnSubscribePage />} />
        <Route
          path={commonRoute.speakerInvitation}
          element={<SpeakerRegister />}
        />

        <Route
          path={commonRoute.login}
          element={isCampaignLogin ? <CampaignLogin /> : <Login />}
        />
        {/* <Route
          path={commonRoute.researchProfile}
          element={<GlobalResearchProfile />}
        /> */}
        <Route
          path={commonRoute.researchProfile}
          element={<NewGlobalResearchProfile />}
        />
        <Route exact path={commonRoute.globalPost} element={<GlobalPost />} />
        {/* <Route path={commonRoute.signup} element={<Success />} /> */}
        <Route
          exact
          path={commonRoute.emailRedirection}
          element={<EmailRedirection />}
        />
        <Route path="/temp" element={<Temp />} />
        <Route path="/success" element={<TempSuccess />} />
        <Route path="/cancel" element={<TempCancel />} />
        <Route path={commonRoute.signup} element={<Selection />} />
        <Route path={commonRoute.register} element={<Register />} />
        <Route path={commonRoute.forgotPassword} element={<ForgotPassword />} />
        <Route
          path="*"
          element={
            <Navigate
              replace
              to={loginRoute}
              state={{
                redirectRoute: `${window.location.pathname}${window.location.search}`,
              }}
            />
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default AuthRoutes;

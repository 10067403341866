import Label from "components/form/Label";
import { icons, limit } from "utils/constants";
import { useEffect, useRef, useState } from "react";
import Loader from "components/Layout/Loader";
import { useDispatch } from "react-redux";
import { fetchEmailReciever, throwError } from "store/slices";
import { omit } from "lodash";
import ListGroup from "react-bootstrap/ListGroup";
import "./NewUserDropdown.scss";

const NewUserDropdown = ({
  id,
  label,
  isRequired,
  labelClass,
  error,
  placeholder,
  handleInvite,
  onChange,
  existingList,
  handleDelete,
  existingAuthor,
}) => {
  const dispatch = useDispatch();
  const listRef = useRef();
  const myRef = useRef();
  const [memberLoading, setMemberLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [timer, setTimer] = useState("");
  const [userDetails, setUserDetails] = useState({
    userList: [],
    offset: 0,
    limit: limit,
    name: "",
    total: 0,
  });

  const handleUserSelect = (e) => {
    const isExistingAuthor = e?.email_id === existingAuthor?.email;

    const userAlreadySelected = existingList?.some(
      (user) => user?.email === e?.email_id
    );

    if (isExistingAuthor || userAlreadySelected) {
      const message = isExistingAuthor
        ? "Co-Author already exists as Author."
        : "Co-Author already selected.";
      dispatch(throwError({ message }));
      return;
    }
    onChange({
      target: {
        id: id,
        value: e,
      },
    });
    setSearchText("");
  };
  const handelSearch = (e) => {
    e.preventDefault();
    const val = e.target.value;
    setSearchText(val.toLowerCase());
    let time = timer;
    clearTimeout(time);
    time = setTimeout(() => {
      let oldData = JSON.parse(JSON.stringify(userDetails));
      oldData.offset = 0;
      oldData.name = val.toLowerCase();
      listRef?.current?.scrollTo(0, 0);
      setUserDetails(oldData);
      getProfiles(oldData, true);
    }, 800);
    setTimer(time);
  };
  const handelScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      let oldData = JSON.parse(JSON.stringify(userDetails));
      oldData.offset = userDetails.offset + limit;
      setUserDetails(oldData);
      getProfiles(oldData);
    }
  };
  const getProfiles = async (obj, isReset) => {
    let queryParams = new URLSearchParams(
      omit(obj, ["userList", "total"])
    ).toString();
    const response = await dispatch(fetchEmailReciever(queryParams));
    const resData = response?.data?.user_data || [];
    setUserDetails((prev) => {
      let newData = isReset ? resData : [...prev.userList, ...resData];
      return {
        ...prev,
        total: response?.data?.result_count,
        userList: newData,
      };
    });
    if (response?.status === 200) {
    }
    setMemberLoading(false);
  };
  const handleClickOutside = (e) => {
    if (
      myRef &&
      myRef?.current &&
      !myRef.current.contains(e.target) &&
      !e?.target?.classList?.contains("list-group-item")
    ) {
      setSearchText("");
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  useEffect(() => {
    getProfiles(userDetails, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { userList, total } = userDetails;
  return (
    <div className="multiple-user-select">
      {label && (
        <Label label={label} required={isRequired} className={labelClass} />
      )}
      <div className="multiple-input-container" ref={myRef}>
        {existingList?.length === 0 && (
          <div className="d-flex mt-1">
            <img src={icons?.userOutline} alt="user" />
          </div>
        )}
        <div className="center-input">
          <div className="d-flex flex-wrap gap-2">
            {existingList?.map((elm, index) => {
              return (
                <div
                  key={index}
                  className="d-flex align-items-center gap-2 text-12-500 color-new-car bg-edff br-2 py-1 px-2"
                >
                  <span>{elm?.name}</span>
                  <span className="d-flex color-new-car text-18-500">
                    <i
                      className="bi bi-x remove-user"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDelete(index);
                      }}
                    />
                  </span>
                </div>
              );
            })}
            <div className="searc-center-input">
              <input
                type="text"
                placeholder={placeholder}
                onChange={handelSearch}
                value={searchText}
              />
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center gap-2">
          <div
            className="invite-block"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleInvite();
            }}
          >
            Invite
          </div>
          {memberLoading ? (
            <Loader size="sm" />
          ) : (
            <i className="bi bi-chevron-down pointer color-gray" />
          )}
        </div>
      </div>
      {searchText && (
        <div
          className="search-list-block shadow rounded iferp-scroll mt-1"
          ref={listRef}
          onScroll={(e) => {
            if (userList.length < total) {
              handelScroll(e);
            }
          }}
        >
          <ListGroup>
            {userList?.map((elem, index) => {
              return (
                <ListGroup.Item
                  key={index}
                  action
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleUserSelect(elem);
                  }}
                >
                  {elem?.name}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </div>
      )}
      {error && (
        <span className="text-13-400 pt-1">
          <span style={{ color: "red" }}>{error}</span>
        </span>
      )}
    </div>
  );
};

export default NewUserDropdown;
// import Loader from "components/Layout/Loader";
// import { cloneDeep, omit } from "lodash";
// import { useEffect, useRef, useState } from "react";
// import ListGroup from "react-bootstrap/ListGroup";
// import { useDispatch } from "react-redux";
// import { fetchEmailReciever, throwError } from "store/slices";
// import { icons, limit } from "utils/constants";
// import Label from "components/form/Label";
// import "./NewUserDropdown.scss";

// const NewUserDropdown = ({
//   id,
//   value,
//   placeholder,
//   error,
//   onChange,
//   existingList,
//   handelInvite,
//   label,
//   isRequired,
//   labelClass,
//   filterID,
//   showOnSearch,
//   IsIcon,
//   authorData,
//   handelCancel,
// }) => {
//   const dispatch = useDispatch();
//   const myRef = useRef();
//   const listRef = useRef();
//   const [isMenu, setIsMenu] = useState(false);
//   const [memberLoading, setMemberLoading] = useState(true);
//   const [searchText, setSearchText] = useState("");
//   const [timer, setTimer] = useState("");
//   const [selectedUsers, setSelectedUsers] = useState(value || []);
// const [userDetails, setUserDetails] = useState({
//   userList: [],
//   offset: 0,
//   limit: limit,
//   name: "",
//   total: 0,
// });
// const handelSearch = (e) => {
//   e.preventDefault();
//   const val = e.target.value;
//   setSearchText(val);
//   let time = timer;
//   clearTimeout(time);
//   time = setTimeout(() => {
//     let oldData = cloneDeep({
//       ...userDetails,
//       offset: 0,
//       name: val.toLowerCase(),
//     });
//     listRef?.current?.scrollTo(0, 0);
//     setUserDetails(oldData);
//     getProfiles(oldData, true);
//   }, 800);
//   setTimer(time);
// };
// const handelScroll = (e) => {
//   const bottom =
//     e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
//   if (bottom) {
//     let oldData = cloneDeep({
//       ...userDetails,
//       offset: userDetails.offset + limit,
//     });
//     setUserDetails(oldData);
//     getProfiles(oldData);
//   }
// };
//   const getProfiles = async (obj, isReset) => {
//     let queryParams = new URLSearchParams(
//       omit(obj, ["userList", "total"])
//     ).toString();
//     const response = await dispatch(fetchEmailReciever(queryParams));
//     if (response?.status === 200) {
//       setUserDetails((prev) => {
//         const apiData = response?.data?.user_data || [];

//         let newData = isReset ? apiData : [...prev.userList, ...apiData];

//         let filterArray = newData?.filter((o) => o?.id !== +value);

//         if (filterID && filterID?.length > 0) {
//           filterArray = newData?.filter((o) => !filterID.includes(o?.id));
//         }

//         return {
//           ...prev,
//           total: response?.data?.result_count,
//           userList: filterArray,
//         };
//       });
//     }
//     setMemberLoading(false);
//   };
//   const handleClickOutside = (e) => {
//     if (myRef && myRef?.current && !myRef.current.contains(e.target)) {
//       setIsMenu(false);
//     }
//   };

//   useEffect(() => {
//     if (value?.length > 0) {
//       setSelectedUsers(value);
//     }
//   }, [value]);

// useEffect(() => {
//   if (!showOnSearch) {
//     getProfiles(userDetails, true);
//   } else {
//     setMemberLoading(false);
//   }
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, []);

// useEffect(() => {
//   document.addEventListener("mousedown", handleClickOutside);
//   return () => document.removeEventListener("mousedown", handleClickOutside);
// });

//   const handleUserSelect = (user) => {
//     if (user?.email_id === authorData?.authors_email) {
//       dispatch(
//         throwError({
//           message: "Author email cannot be added as a co-author.",
//         })
//       );
//       return;
//     }

//     if (!selectedUsers?.find((u) => u?.id === user?.id)) {
//       const updatedSelectedUsers = [...selectedUsers, user];
//       setSelectedUsers(updatedSelectedUsers);
// onChange({
//   target: {
//     id: id,
//     data: updatedSelectedUsers,
//   },
// });
//       setIsMenu(false);
//       setSearchText("");
//     } else {
//       dispatch(
//         throwError({
//           message: "Co-Author already selected.",
//         })
//       );
//     }
//   };

//   const handleRemoveUser = (data) => {
//     const updatedSelectedUsers = selectedUsers?.filter(
//       (user) => user?.email !== data?.email
//     );
//     setSelectedUsers(updatedSelectedUsers);
//     onChange({
//       target: {
//         id: id,
//         data: updatedSelectedUsers,
//       },
//     });
//   };

//   const handelInviteCoAuthor = () => {
//     setSelectedUsers([]);
//     handelInvite({
//       target: {
//         id: id,
//         data: [],
//       },
//     });
//   };

//   const { userList, total } = userDetails;

//   const findVal = [...userList, ...existingList]?.find(
//     (o) => `${o?.id}` === `${value}`
//   );

//   const showSugetion = showOnSearch ? (searchText ? true : false) : isMenu;
//   const isSelectedUser = selectedUsers?.length > 0;
//   const shouldHideInviteUI = existingList && existingList?.length > 0;
//   return (
//     <div className="new-user-dropdown-container" ref={myRef}>
// {label && (
//   <Label label={label} required={isRequired} className={labelClass} />
// )}

//       <div
//         className="search-input-block pointer"
//         style={{ paddingTop: isSelectedUser ? "8px" : "" }}
//       >
//         {!shouldHideInviteUI && IsIcon && (
//           <div
//             className={`${
//               isSelectedUser ? "is-selected-icon-wrapper" : "icon-wrapper"
//             } common-icon-wrapper`}
//             style={{ width: "24px" }}
//           >
//             <img src={icons?.userOutline} alt="user" />
//           </div>
//         )}

//         <div
//           className="selected-users-container"
//           style={{ paddingLeft: "35px" }}
//         >
//           {selectedUsers?.map((user, index) => (
//             <div key={index} className="selected-user">
//               <span className="user-name">{user?.name}</span>

// <i
//   className="bi bi-x remove-user"
//   onClick={(e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     handleRemoveUser(user);
//   }}
// />
//             </div>
//           ))}
//         </div>

//         {!shouldHideInviteUI && (
//           <>
//             <div
//               className={findVal?.name ? "active-text" : "placeholder-color"}
//               onClick={() => {
//                 if (!memberLoading) {
//                   setIsMenu(true);
//                 }
//               }}
//             >
//               <input
//                 type="text"
//                 placeholder={findVal?.name || placeholder}
//                 onChange={handelSearch}
//                 value={searchText}
//                 className={handelInvite ? "elipsis-input" : ""}
//                 style={{
//                   paddingLeft: IsIcon ? "25px" : "14px",
//                   paddingTop: isSelectedUser ? "" : "0px",
//                 }}
//               />
//             </div>

//             <div
//               className={`${
//                 isSelectedUser ? "is-selected-icon-block" : "icon-block"
//               } common-icon-block`}
//               onClick={() => {
//                 if (!memberLoading) {
//                   setIsMenu(!isMenu);
//                 }
//               }}
//             >
//               {memberLoading ? (
//                 <Loader size="sm" />
//               ) : (
//                 <i className="bi bi-chevron-down pointer" />
//               )}
//             </div>
//           </>
//         )}
//         {handelInvite &&
//           (shouldHideInviteUI ? (
//             <div
//               className={`${
//                 isSelectedUser ? "is-selected-invite-block" : "invite-block"
//               } common-invited-block br-4"`}
//               onClick={(e) => {
//                 e.preventDefault();
//                 e.stopPropagation();
//                 setSelectedUsers([]);
//                 handelCancel({
//                   target: {
//                     id: id,
//                     data: [],
//                   },
//                 });
//               }}
//             >
//               Cancel
//             </div>
//           ) : (
//             <div
//               className={`${
//                 isSelectedUser ? "is-selected-invite-block" : "invite-block"
//               } common-invited-block br-4"`}
//               onClick={(e) => {
//                 e.preventDefault();
//                 e.stopPropagation();
//                 handelInviteCoAuthor();
//               }}
//             >
//               Invite
//             </div>
//           ))}
// {error && (
//   <span className="text-13-400 pt-1">
//     <span style={{ color: "red" }}>{error}</span>
//   </span>
// )}
//         {showSugetion && (
//           <div
//             className="search-list-block shadow rounded"
//             style={{ top: isSelectedUser ? "calc(100% + 5px)" : "48px" }}
//           >
//             <div
//               className="max-300 overflow-auto iferp-scroll"
//               ref={listRef}
// onScroll={(e) => {
//   if (userList.length < total) {
//     handelScroll(e);
//   }
// }}
//             >
// <ListGroup>
//   {userList?.map((elem, index) => {
//     return (
//       <ListGroup.Item
//         key={index}
//         action
//         onClick={(e) => {
//           e.preventDefault();
//           e.stopPropagation();
//           handleUserSelect(elem);
//         }}
//       >
//         {elem?.name}
//       </ListGroup.Item>
//     );
//   })}
//   {searchText && userList.length === 0 && (
//     <ListGroup.Item className="text-center text-14-500">
//       User not found, Invite now
//     </ListGroup.Item>
//   )}
// </ListGroup>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };
// export default NewUserDropdown;

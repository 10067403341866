import React, { useEffect, useState } from "react";
import Button from "components/form/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "components/form/TextInput";
import TextEditor from "components/form/TextEditor";
import CreatableDropdown from "components/form/CreatableDropdown";
import { isEqual, map, union, unionBy } from "lodash";
import { objectToFormData, titleCaseString } from "utils/helpers";
import UploadInput from "components/form/UploadInput";
import { useDispatch } from "react-redux";
import {
  addRevision,
  getKeywords,
  throwError,
  throwSuccess,
} from "store/slices";

const RevisionPaperForm = ({
  abstractId,
  onHide,
  handleSuccess,
  isReviewState,
  ref,
}) => {
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);

  const [keywordsList, setKeywordsList] = useState({
    existing: [],
    custom: [],
  });

  const initialValues = {
    paper_title: "",
    paper_abstract: "",
    paper_keywords: "",
    abstract_path: "",
    abstractPathFileName: "",
    abstract_id: +abstractId,
  };

  const validationSchema = Yup.object().shape({
    paper_title: Yup.string().required("Paper title is required."),
    paper_abstract: Yup.string().required("Abstract is required."),
    paper_keywords: Yup.string().test(
      "paper_keywords-length",
      "You must enter at least 3 and up to 10 Keywords",
      function (value) {
        const keywordArray = value
          ? value.split(",").filter((kw) => kw.trim() !== "")
          : [];
        return keywordArray.length >= 3 && keywordArray.length <= 10;
      }
    ),
    abstract_path: Yup.string().required("File is required."),
  });

  const fetchKeywords = async () => {
    const response = await dispatch(getKeywords());
    let listArray = response?.data || [];
    setKeywordsList((prev) => {
      return {
        ...prev,
        existing: map(listArray, (elm) => {
          return { id: elm?.keywords, label: elm?.keywords };
        }),
      };
    });
  };

  const handleSave = async (values) => {
    setBtnLoading(true);
    if (isReviewState) {
      values.reviewer_revision = "1";
    }
    const response = await dispatch(addRevision(objectToFormData(values)));
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      onHide();
      handleSuccess();
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }
    setBtnLoading(false);
  };

  useEffect(() => {
    fetchKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="form-block cps-24 cpt-12 cpe-24 cpb-12">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSave}
      >
        {(props) => {
          const {
            values,
            errors,
            handleChange,
            setFieldValue,
            handleSubmit,
            resetForm,
          } = props;
          return (
            <form>
              <div className="row">
                <div className="col-md-10 cmb-24">
                  <TextInput
                    label="Paper Title*"
                    value={values?.paper_title}
                    error={errors?.paper_title}
                    onChange={handleChange}
                    id="paper_title"
                    placeholder="Enter paper title"
                  />
                </div>
                <div className="col-md-10 cmb-24">
                  <TextEditor
                    label="Abstract*"
                    id="paper_abstract"
                    value={values?.paper_abstract}
                    error={errors?.paper_abstract}
                    onChange={handleChange}
                    placeholder="Enter paper_abstract"
                  />
                </div>
                <div className="col-md-10 cmb-24">
                  <CreatableDropdown
                    label="Keywords"
                    id="paper_keywords"
                    options={unionBy(
                      [...keywordsList?.existing, ...keywordsList?.custom],
                      "id"
                    )}
                    placeholder="Enter Keywords"
                    value={values?.paper_keywords}
                    error={errors?.paper_keywords}
                    onChange={handleChange}
                    onCreateOption={(e) => {
                      let newCreate = [];
                      if (e?.includes(",")) {
                        newCreate = e?.split(",");
                      } else {
                        newCreate = [e];
                      }
                      newCreate = newCreate?.map((o) => {
                        let removedSpace = o?.trim();
                        return titleCaseString(removedSpace);
                      });
                      let strToArray = values?.paper_keywords
                        ? values?.paper_keywords?.split(",")
                        : [];
                      strToArray = union([...strToArray, ...newCreate]);
                      setKeywordsList({
                        ...keywordsList,
                        custom: [
                          ...keywordsList?.custom,
                          ...newCreate?.map((e) => {
                            return { id: e, label: e };
                          }),
                        ],
                      });
                      handleChange({
                        target: {
                          id: "paper_keywords",
                          value: strToArray.join(","),
                        },
                      });
                    }}
                  />
                </div>
                <div className="col-md-10 cmb-24">
                  <UploadInput
                    multiple={false}
                    label="Upload Paper*"
                    id="abstract_path"
                    value={values?.abstract_path}
                    supportedFormats={[
                      "XLS",
                      "XLSX",
                      "PDF",
                      "WORD",
                      "PPT",
                      "PPTX",
                      "DOC",
                      "DOCX",
                    ]}
                    handleChange={(e) => {
                      setFieldValue(
                        "abstractPathFileName",
                        e?.target?.fileName
                      );
                      setFieldValue(e?.target?.id, e?.target?.value);
                      handleChange(e);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex gap-2">
                <Button
                  text="Submit"
                  btnStyle="primary-dark"
                  className="cps-20 cpe-20 h-30"
                  onClick={handleSubmit}
                  btnLoading={btnLoading}
                  disabled={isEqual(values, initialValues)}
                />

                <Button
                  text="Cancel"
                  btnStyle="light-outline"
                  className="cps-20 cpe-20 h-30"
                  onClick={() => {
                    resetForm();
                    onHide();
                  }}
                />
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default RevisionPaperForm;

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../form/Button";
import Modal from "../../Layout/Modal";
import { icons, membershipType } from "utils/constants";
import { getDataFromLocalStorage } from "utils/helpers";
import {
  setIsDiscountPopup,
  setIsMentorPremiumBenefit,
  setIsPremiumPopup,
} from "store/slices";
import "./PremiumPopup.scss";

const UpgradeButton = ({ text }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isPremiumPopup, isMentorPremiumBenefit } = useSelector((state) => ({
    isPremiumPopup: state.global.isPremiumPopup,
    isMentorPremiumBenefit: state.global.isMentorPremiumBenefit,
  }));
  // const benefits = [
  //   "Network with Industry Leaders",
  //   "Connection with international bodies and agencies",
  //   "Interdisciplinary Research Opportunities",
  // ];
  // const mentorBenefits = [
  //   "Professional Premium mentors get very minimal platform fee",
  //   "Premium mentors get their payment settlement every month ",
  //   "Conduct unlimited 1 on 1 mentorship sessions effortlessly",
  // ];

  const newBenefits = [
    {
      point: "Mentoring Opportunities",
      desc: "Share your expertise and earn by mentoring others in your specialized subjects.",
    },
    {
      point: "Paper Reviewing",
      desc: "Contribute to the academic community and gain recognition by reviewing research papers",
    },
    {
      point: "Keynote Speaker Roles",
      desc: "Take the stage as a keynote speaker at prestigious international conferences.",
    },
    {
      point: "Session Chair Positions",
      desc: "Lead and organize sessions, increasing your visibility and influence in your field.",
    },
    {
      point: "Professional Ambassador",
      desc: "Represent IFERP, expand your professional network, and influence the future of research.",
    },
    {
      point: "Funding and Grants",
      desc: "Access exclusive grants and funding opportunities to support your research projects.",
    },
  ];
  const userType = getDataFromLocalStorage("user_type");
  const findType = membershipType.find((o) => o.id === userType)?.type || "";
  // const features = isMentorPremiumBenefit ? mentorBenefits : benefits;
  return (
    <>
      {isPremiumPopup && (
        <Modal
          onHide={() => {
            dispatch(setIsPremiumPopup(false));
            dispatch(setIsMentorPremiumBenefit(false));
            if (localStorage.getItem("userCV")) {
              localStorage.removeItem("userCV");
            }
          }}
          title=""
        >
          <div className="center-flex flex-column cmb-30">
            <div>
              <img src={icons.PremiumPopupCrown} alt="success" />
            </div>
            <div className="popup-heading color-raisin-blck cmt-24 cmb-30 lh-1 test-wrap text-center">
              Elevate Your Career with IFERP Premium Membership!
            </div>
            <div className="text-19-600 color-raisin-blck mb-2 text-center cps-5 cpe-5">
              Unlock a range of exclusive benefits designed to enhance your
              professional journey:
            </div>
          </div>

          <div className="cps-15 cpe-15 cmb-20">
            {newBenefits.map((elem, index) => {
              const { point, desc } = elem;
              return (
                <div className="justify-content-center cmb-15">
                  <div className="">
                    <div key={index} className="text-16-600">
                      <img
                        src={icons.checkCircle}
                        alt="right"
                        className="me-2 text-16-600"
                      />
                      {point}
                    </div>
                  </div>
                  <div className="ms-4 text-15-500">{desc}</div>
                </div>
              );
            })}
          </div>

          <div className="d-flex justify-content-center flex-column mb-3">
            <div className="text-16-600 color-raisin-blck text-center">
              Upgrade to IFERP Premium Membership and gain access to these
              unique opportunities and more. Take the next step in your career
              and join a global community of professionals committed to
              excellence.
            </div>
          </div>

          <div className="center-flex flex-column">
            <div className="text-16-600 color-raisin-blck text-center mb-2">
              Enhance your career. Elevate your impact. Upgrade now!
            </div>
            <div className="d-flex mb-4">
              <Button
                isRounded
                text="Upgrade to Premium"
                btnStyle="primary-dark"
                className="cps-20 cpe-20"
                onClick={() => {
                  if (findType) {
                    navigate(`/${findType}/upgrade`);
                    dispatch(setIsPremiumPopup(false));
                    dispatch(setIsMentorPremiumBenefit(false));
                    if (localStorage.getItem("userCV")) {
                      localStorage.removeItem("userCV");
                    }
                  }
                }}
              />
            </div>
            <div
              className="text-15-400 color-black-olive pointer"
              onClick={() => {
                if (isMentorPremiumBenefit) {
                  dispatch(setIsDiscountPopup(true));
                  dispatch(setIsPremiumPopup(false));
                } else {
                  dispatch(setIsMentorPremiumBenefit(false));
                  dispatch(setIsPremiumPopup(false));
                }
              }}
            >
              {"Skip I’ll do later"}
            </div>
          </div>
          {/* <div className="center-flex flex-column cmb-30">
            <div>
              <img src={icons.successTick} alt="success" />
            </div>
            <div className="text-32-600 color-raisin-blck cmt-24 cmb-30 lh-1 test-wrap">
              Become Premium Member
            </div>
            <div className="text-16-500 color-raisin-blck mb-2">
              Upgrade to Premium student Member & Get
            </div>
            <div className="text-start mt-2">
              {features.map((elem, index) => {
                return (
                  <div key={index} className="cmb-12 text-14-400">
                    <img src={icons.checkCircle} alt="right" className="me-2" />
                    {elem}
                  </div>
                );
              })}
            </div>
            <div className="d-flex mt-5 mb-4">
              <Button
                isRounded
                text="Upgrade to Premium"
                btnStyle="primary-dark"
                className="cps-20 cpe-20"
                onClick={() => {
                  if (findType) {
                    navigate(`/${findType}/upgrade`);
                    dispatch(setIsPremiumPopup(false));
                    dispatch(setIsMentorPremiumBenefit(false));
                    if (localStorage.getItem("userCV")) {
                      localStorage.removeItem("userCV");
                    }
                  }
                }}
              />
            </div>
            <div
              className="text-15-400 color-black-olive pointer"
              onClick={() => {
                if (isMentorPremiumBenefit) {
                  dispatch(setIsDiscountPopup(true));
                  dispatch(setIsPremiumPopup(false));
                } else {
                  dispatch(setIsMentorPremiumBenefit(false));
                  dispatch(setIsPremiumPopup(false));
                }
              }}
            >
              {"Skip I’ll do later"}
            </div>
          </div> */}
        </Modal>
      )}
    </>
  );
};
export default UpgradeButton;

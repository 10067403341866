import Card from "components/Layout/Card";
import Table from "components/Layout/Table";
import Button from "components/form/Button";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchAppliedCareer } from "store/slices";
import { limit } from "utils/constants";
import { getStatus, objectToFormData, titleCaseString } from "utils/helpers";

const SpeakerApplications = () => {
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState({
    total: 0,
    offset: 0,
    limit: limit,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [mySpeakerApplication, setMySpeakerApplication] = useState([]);

  const navigate = useNavigate();

  const getBecomeData = async (obj) => {
    let forData = objectToFormData(obj);
    const response = await dispatch(fetchAppliedCareer(forData));
    if (response?.data?.career_details) {
      const newData = response?.data?.career_details?.filter(
        (o) => o?.career_id === 6
      );
      setMySpeakerApplication(newData || []);
    }
    setFilterData({
      ...obj,
      total: response?.data?.result_count || 0,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getBecomeData({ ...filterData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const header = [
    {
      isSearch: false,
      title: "Conference Name",
    },
    {
      isSearch: false,
      title: "Session Name",
    },
    {
      title: "Submitted on",
    },
    {
      isSearch: false,
      title: "Status",
    },
    {
      isSearch: false,
      title: "Action",
    },
  ];

  let rowData = [];
  mySpeakerApplication?.forEach((elem) => {
    let obj = [
      {
        value: titleCaseString(elem.activity_name),
      },
      {
        value: titleCaseString(elem.spk_session_name),
      },
      {
        value: elem.created_at,
      },
      {
        value: getStatus(elem.status),
      },
      {
        value: (
          <span className="action-icon-buttons gap-2">
            <Button
              onClick={() => {
                navigate(
                  `/professional/career-support/applied-career-support/keynote-speaker/speaker-applications/${elem.id}`
                );
              }}
              text="View"
              btnStyle="primary-outline"
              className="text-14-500 mw-70 me-2"
              isSquare
            />
            {elem.status === "1" && !elem?.welcome_messages && (
              <Button
                isSquare
                text="Upload"
                btnStyle="unset-primary"
                className="me-2"
                onClick={() => {
                  navigate(
                    `/professional/career-support/applied-career-support/keynote-speaker/speaker-applications/${elem.id}`
                  );
                }}
              />
            )}
          </span>
        ),
      },
    ];
    rowData.push({ data: obj });
  });
  return (
    <div id="speaker-application-container">
      <Card className="cps-20 cpe-20 cmb-20 cpt-20 cpb-20">
        <div className="overflow-auto">
          <Table header={header} rowData={rowData} isLoading={isLoading} />
        </div>
      </Card>
    </div>
  );
};

export default SpeakerApplications;

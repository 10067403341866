import Card from "components/Layout/Card";
import Loader from "components/Layout/Loader";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getMemberCount } from "store/slices";
import { icons } from "utils/constants";
import { objectToFormData } from "utils/helpers";

const ResourceCountInCard = ({ commonYear, isReport }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [memberCountData, setMemberCountData] = useState({});

  const fetchMemberCount = async () => {
    setIsLoading(true);
    const payload = { year: commonYear?.memberCountYear };
    let response;
    if (isReport) {
      response = await dispatch(getMemberCount());
    } else {
      response = await dispatch(getMemberCount(objectToFormData(payload)));
    }

    setMemberCountData(response?.data || {});
    setIsLoading(false);
  };

  useEffect(() => {
    fetchMemberCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commonYear]);

  const {
    institution_diamond_members = 0,
    institution_gold_members = 0,
    institution_silver_members = 0,
    institution_members = 0,
    professional_free_members = 0,
    professional_members = 0,
    professional_premium_members = 0,
    student_members = 0,
    student_free_members = 0,
    student_premium_members = 0,
  } = memberCountData;
  return (
    <>
      {isLoading ? (
        <Card className="cpt-80 cpb-80 center-flex">
          <Loader size="md" />
        </Card>
      ) : (
        <>
          <div className="col-md-4 mb-2">
            <Card className="border dashboard-card">
              <div className="center-flex border-b cpt-16 cpb-16">
                <div className="card-profile">
                  <img src={icons.studentMember} alt="img" />
                </div>
                <div className="text-start cps-16">
                  <div className="text-20-600 color-raisin-black">
                    {student_members}
                  </div>
                  <div className="text-15-500 color-black-olive">
                    Allocated Students
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex">
                <div className="col-md-6 col-6 border-r cpt-16 cpb-16 text-center">
                  <div className="text-16-500 color-raisin-black">
                    {student_premium_members}
                  </div>
                  <div className="text-13-400 color-black-olive pt-1">
                    Premium
                  </div>
                </div>
                <div className="col-md-6 col-6 cpt-16 cpb-16 text-center">
                  <div className="text-16-500 color-raisin-black">
                    {student_free_members}
                  </div>
                  <div className="text-13-400 color-black-olive pt-1">Free</div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-4 mb-2">
            <Card className="border dashboard-card">
              <div className="center-flex border-b cpt-16 cpb-16">
                <div className="card-profile">
                  <img src={icons.management} alt="img" />
                </div>
                <div className="text-start cps-16">
                  <div className="text-20-600 color-raisin-black">
                    {professional_members}
                  </div>
                  <div className="text-15-500 color-black-olive">
                    Allocated Professionals
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex">
                <div className="col-md-6 col-6 border-r cpt-16 cpb-16 text-center">
                  <div className="text-16-500 color-raisin-black">
                    {professional_premium_members}
                  </div>
                  <div className="text-13-400 color-black-olive pt-1">
                    Premium
                  </div>
                </div>
                <div className="col-md-6 col-6 cpt-16 cpb-16 text-center">
                  <div className="text-16-500 color-raisin-black">
                    {professional_free_members}
                  </div>
                  <div className="text-13-400 color-black-olive pt-1">Free</div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-md-4 mb-2">
            <Card className="border dashboard-card">
              <div className="center-flex border-b cpt-16 cpb-16">
                <div className="card-profile">
                  <img src={icons.institute} alt="img" />
                </div>
                <div className="text-start cps-16">
                  <div className="text-20-600 color-raisin-black">
                    {institution_members}
                  </div>
                  <div className="text-15-500 color-black-olive">
                    Allocated Institutions
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex">
                <div className="col-md-4 col-4 border-r cpt-16 cpb-16 text-center">
                  <div className="text-16-500 color-raisin-black">
                    {institution_silver_members}
                  </div>
                  <div className="text-13-400 color-black-olive pt-1">
                    Silver
                  </div>
                </div>
                <div className="col-md-4 col-4 border-r cpt-16 cpb-16 text-center">
                  <div className="text-16-500 color-raisin-black">
                    {institution_gold_members}
                  </div>
                  <div className="text-13-400 color-black-olive pt-1">Gold</div>
                </div>
                <div className="col-md-4 col-4 cpt-16 cpb-16 text-center">
                  <div className="text-16-500 color-raisin-black">
                    {institution_diamond_members}
                  </div>
                  <div className="text-13-400 color-black-olive pt-1">
                    Diamond
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </>
      )}
    </>
  );
};

export default ResourceCountInCard;

import Loader from "components/Layout/Loader";
import Profile from "components/Layout/Profile";
import React from "react";
import { icons } from "utils/constants";

const CertificatePreview = ({ access, elem, loadingIndexes, index }) => {
  const { s3File, img, previewUrl } = elem;
  const urlType = img?.split(".").pop();
  return (
    <div className="col-md-3">
      {loadingIndexes?.includes(index) ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Loader />
        </div>
      ) : access?.isUpgradeButton ? (
        <img
          src={icons.membershipCertificate}
          alt="cer"
          className="fit-image fill"
        />
      ) : ["pdf"].includes(urlType) ? (
        <iframe
          src={previewUrl}
          title="certificate"
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      ) : (
        <Profile url={s3File} size="s-136" />
        /* <img src={s3File} alt="cer" className="fit-image fill" /> */
      )}
    </div>
  );
};

export default CertificatePreview;

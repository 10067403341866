import Card from "components/Layout/Card";
import Loader from "components/Layout/Loader";
import PriceRangePopup from "components/Layout/PriceRangePopup";
import Profile from "components/Layout/Profile";
import SelectSkillPopup from "components/Layout/SelectSkillPopup";
import Button from "components/form/Button";
import SeachInput from "components/form/SeachInput";
import { omit } from "lodash";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  applyBecomeMentor,
  fetchAllMentors,
  throwSuccess,
  fetchProfile,
  throwError,
  setIsDiscountPopup,
} from "store/slices";
import { icons, limit } from "utils/constants";
import {
  getDataFromLocalStorage,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";
import { cloneDeep } from "lodash";
import GuideLinePopup from "components/Layout/GuideLinePopup";
import DiscountPopup from "components/Layout/DiscountPopup";
import MentorUploadCVPopup from "./MentorUploadCVPopup";
import MentorProfilePopup from "../MentorProfilePopup";
import "./AllMentors.scss";
import SingleMentorDetails from "./SingleMentorDetails";

const AllMentors = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const userType = params?.memberType;
  const { isDiscountPopup } = useSelector((state) => ({
    isDiscountPopup: state.global.isDiscountPopup,
  }));

  const authUserDetails = getDataFromLocalStorage();
  const { cv } = authUserDetails;
  const { id: login_user_id, mentor_status } = authUserDetails;
  const [isPriceRange, setIsPriceRange] = useState(false);
  const [isSelectSkill, setIsSelectSkill] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [iD, setID] = useState("");
  const [profileData, setProfileData] = useState({});
  const offSetRef = useRef(0);
  const totalDocRef = useRef(0);
  const listRef = useRef();
  const [mentorId, setMentorId] = useState(false);
  const [scrollLoader, setScrollLoader] = useState(false);
  const [isGuidLinePopup, setIsGuidLinePopup] = useState(false);
  const [isLoadAPI, setIsLoadAPI] = useState(true);
  const [isCVPopup, setIsCVPopup] = useState(false);
  const [allMentorsList, setAllMentorsList] = useState({
    search: "",
    key: "",
    min_price: "",
    max_price: "",
    data: [],
    loading: true,
    total: 0,
    offset: 0,
    limit: 10,
  });
  const ApplyMentor = async () => {
    setIsLoading(true);
    const formData = objectToFormData({
      mentor_id: login_user_id,
    });
    const response = await dispatch(applyBecomeMentor(formData));
    if (response?.status === 200) {
      await dispatch(fetchProfile());
      dispatch(throwSuccess("Applied to become mentor successfully !"));
      setIsLoading(false);
    }
  };
  const getAllMentors = async (
    obj,
    isReset,
    isPrice = false,
    isSkill = false
  ) => {
    setScrollLoader(true);
    setIsLoading(true);
    if (isPrice) {
      offSetRef.current = 0;
    }
    if (isSkill) {
      offSetRef.current = 0;
    }
    const payload = omit(
      {
        ...obj,
        offset: offSetRef.current,
      },
      ["data", "loading", "total"]
    );
    const response = await dispatch(fetchAllMentors(objectToFormData(payload)));
    if (response?.status === 200) {
      offSetRef.current = offSetRef.current + obj?.limit;
      totalDocRef.current = response?.data?.result_count;
      setID(response?.data?.result_data?.[0]?.id);
      setAllMentorsList((prev) => {
        let resData = response?.data?.result_data || [];
        let listData = isReset ? resData : [...prev?.data, ...resData];
        return {
          ...prev,
          data: listData,
          total: response?.data?.result_count,
          loading: false,
        };
      });
    } else {
      dispatch(throwError(response?.message));
    }
    setIsLoading(false);
    setScrollLoader(false);
  };

  const handelSearch = (e) => {
    let value = e?.target?.value;
    setSearchText(value);
    let oldData = {
      ...allMentorsList,
      search: value,
      key: "mentor_name",
      loading: true,
    };
    offSetRef.current = 0;
    listRef?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    totalDocRef.current = 0;
    setAllMentorsList(oldData);
    getAllMentors(oldData, true);
  };

  const getProfileData = async () => {
    const response = await dispatch(fetchProfile());
    if (response?.status === 200) {
      setProfileData(response?.data);
    }
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - Math.ceil(e.target.scrollTop) ===
      e.target.clientHeight;
    if (bottom) {
      let oldData = cloneDeep({
        ...allMentorsList,
        offset: allMentorsList.offset + limit,
      });
      setAllMentorsList(oldData);
      getAllMentors(oldData);
    }
  };

  useEffect(() => {
    getAllMentors(allMentorsList, true);
    getProfileData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, total } = allMentorsList;

  useEffect(() => {
    if (localStorage.paymentIntent) {
      dispatch(setIsDiscountPopup(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="mentee-all-mentor-container">
      {mentorId && (
        <MentorProfilePopup
          userID={mentorId}
          onHide={() => {
            setMentorId(false);
          }}
        />
      )}
      {isGuidLinePopup && (
        <GuideLinePopup
          title="Guidelines"
          subTitle="Let's get you started as a mentor!"
          onHide={() => {
            setIsGuidLinePopup(false);
          }}
          applyMentor={ApplyMentor}
        />
      )}

      {isDiscountPopup && <DiscountPopup applyMentor={ApplyMentor} />}

      {isCVPopup && (
        <MentorUploadCVPopup
          onHide={() => {
            setIsCVPopup(false);
          }}
          setIsGuidLinePopup={setIsGuidLinePopup}
          authUserDetails={authUserDetails}
        />
      )}

      <div className="d-flex justify-content-between align-items-center flex-wrap cmb-20">
        <div className="text-16-500-25 color-subtitle-navy">
          All Mentors {`(${allMentorsList?.total})`}
        </div>
        <div className="d-flex gap-3 flex-wrap">
          <div className="position-relatives">
            <Button
              isRounded
              text="Filter Price Range"
              btnStyle="primary-outline"
              className="text-nowrap gap-3"
              rightIcon={
                <img
                  src={isPriceRange ? icons.upArrow : icons.downArrow}
                  alt="arrow"
                />
              }
              onClick={() => {
                setIsPriceRange(true);
              }}
            />
            {isPriceRange && (
              <PriceRangePopup
                isPriceRange={isPriceRange}
                setIsPriceRange={setIsPriceRange}
                onHide={() => {
                  setIsPriceRange(false);
                }}
                allMentorsList={allMentorsList}
                setAllMentorsList={setAllMentorsList}
                getAllMentors={getAllMentors}
              />
            )}
          </div>
          <div className="">
            <Button
              isRounded
              text="Skills"
              btnStyle="primary-outline"
              className="text-nowrap gap-3"
              rightIcon={
                <img
                  src={isSelectSkill ? icons.upArrow : icons.downArrow}
                  alt="arrow"
                />
              }
              onClick={() => {
                setIsSelectSkill(true);
              }}
            />
            {isSelectSkill && (
              <SelectSkillPopup
                isSelectSkill={isSelectSkill}
                setIsSelectSkill={setIsSelectSkill}
                onHide={() => {
                  setIsSelectSkill(false);
                }}
                allMentorsList={allMentorsList}
                setAllMentorsList={setAllMentorsList}
                getAllMentors={getAllMentors}
              />
            )}
          </div>

          {/* <Dropdown
            placeholder="Select for search"
            options={searchOptions}
            optionValue="value"
            optionKey="value"
            value={searchOptionsValue}
            onChange={(e) => {
              setSearchOptionsValue(e?.target?.value);
              setSearchOptionsId(e?.target?.data?.id);
            }}
          /> */}

          <div className="d-flex">
            <SeachInput
              placeholder={
                "Mentor Name" ? "Search mentors" : "Search sessions or topics"
              }
              value={searchText}
              onChange={handelSearch}
              isRounded
            />
          </div>
          {userType !== "student" &&
            mentor_status !== "Accept" &&
            (mentor_status === "" || mentor_status === "Reject" ? (
              <Button
                text="Become Mentor"
                btnStyle="primary-outline"
                className="text-nowrap"
                btnLoading={isLoading}
                onClick={() => {
                  if (cv) {
                    setIsGuidLinePopup(true);
                  } else {
                    setIsCVPopup(true);
                  }
                }}
                // onClick={ApplyMentor}
              />
            ) : (
              <Button
                text="Pending Approval"
                btnStyle="primary-outline"
                className="text-nowrap"
                disabled
              />
            ))}
          {/* <Button
            text="Add Payment"
            btnStyle="primary-outline"
            className="text-nowrap"
            // onClick={() => navigate(`/professional/mentorship/mentor`); dispatch(getPaymentType("payment-account-details"))}
            onClick={() => {
              navigate(`/professional/mentorship/mentor`);
              dispatch(getPaymentType("payment-account-details"));
            }}
          /> */}
        </div>
      </div>

      <div className="row">
        {/* All Mentor's list */}
        <div className="col-md-4 g-0 cps-10 h-100">
          <div
            className="all-mentor-card-container"
            ref={listRef}
            onScroll={(e) => {
              if (data?.length < total) {
                handleScroll(e);
              }
            }}
          >
            {allMentorsList?.loading && (
              <Card className="cpt-30 cpb-50">
                <Loader size="md" />
              </Card>
            )}
            {!allMentorsList?.loading && allMentorsList?.data?.length === 0 ? (
              <Card className="cpt-30 cpb-50 text-center">
                No Records Found.
              </Card>
            ) : (
              allMentorsList?.data?.length > 0 &&
              allMentorsList?.data?.map((elem, index) => {
                const {
                  id,
                  profile,
                  name,
                  institution,
                  designation,
                  country,
                  skills,
                  user_type,
                } = elem;
                const isReasearchProfile = ["2", "5"].includes(user_type);
                return (
                  <Card
                    key={index}
                    className={`${
                      iD === id && "selected-border"
                    } cps-10 cpt-10 cpe-10 cpb-10 cmb-8 pointer`}
                    onClick={() => {
                      if (!isLoadAPI) {
                        setID(id);
                      }
                    }}
                  >
                    <div className="d-flex align-items-center gap-2 cmb-10 flex-wrap">
                      <Profile
                        url={profile}
                        isRounded
                        isS3UserURL
                        text={name}
                      />
                      <div>
                        <div
                          className={`text-16-500-25 ${
                            isReasearchProfile
                              ? "color-new-car pointer"
                              : "color-3146"
                          }`}
                          onClick={(e) => {
                            if (isReasearchProfile) {
                              e.stopPropagation();
                              setMentorId(id);
                            }
                          }}
                        >
                          {titleCaseString(name)}
                        </div>
                        <div className="text-14-400-26 color-3146">
                          {designation}
                        </div>
                      </div>
                    </div>
                    <div className="text-14-400-26 color-3146 cmb-10">
                      {institution}
                      {institution && ","} {country}
                    </div>
                    <div className="d-flex gap-3 flex-wrap align-items-center">
                      {skills &&
                        skills?.slice(0, 3)?.map((data, i) => {
                          return (
                            <div
                              className="text-14-400 key-points color-dark-blue bg-f6fc text-nowrap cps-5 cpt-6 cpe-5 cpb-6 rounded"
                              key={i}
                            >
                              {data}
                            </div>
                          );
                        })}
                      {skills?.length > 3 && `+${skills?.length - 3}`}
                    </div>
                  </Card>
                );
              })
            )}
            {!allMentorsList?.loading && scrollLoader && (
              <div className="cpt-30 cpb-30">
                <Loader size="sm" />
              </div>
            )}
          </div>
        </div>

        {/* Single Mentor's details */}
        <SingleMentorDetails
          allMentorsList={allMentorsList}
          iD={iD}
          profileData={profileData}
          setIsLoadAPI={setIsLoadAPI}
          isLoadAPI={isLoadAPI}
        />
      </div>
    </div>
  );
};

export default AllMentors;

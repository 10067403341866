import Card from "components/Layout/Card";
import Modal from "components/Layout/Modal";
import Profile from "components/Layout/Profile";
import ProfileProgressBar from "components/Layout/ProfileProgressBar";
import React, { useEffect, useState } from "react";
import ProfileChangesDetails from "./ProfileChangesDetails";
import EducationDetails from "./EducationDetails";
import { useDispatch } from "react-redux";
import { fetchUserProfile } from "store/slices";
import {
  getCountryCode,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";
import Loader from "components/Layout/Loader";
import moment from "moment";

const compareArray = [
  {
    module: "Profile",
    keys: [
      "profile_photo_path",
      "first_name",
      "last_name",
      "email_id",
      "country_code",
      "phone_number",
      "date_of_birth",
      "gender",
      "country_name",
    ],
  },
  {
    module: "My Research ID’s",
    keys: ["research_id"],
  },
  {
    module: "Career Objective",
    keys: ["introduction"],
  },
  {
    module: "Disciplines",
    keys: ["disciplines"],
  },
  {
    module: "Skills & Expertise",
    keys: ["area_of_interest"],
  },
  {
    module: "Comment",
    keys: ["comments"],
  },
  {
    module: "Languages",
    keys: ["languages"],
  },
  {
    module: "Educations Details",
    keys: [
      "ug_course",
      "ug_department",
      "ug_university",
      "other_ug_university",
      "ug_institution",
      "other_ug_institution",
      "ug_year_of_completion",
      "pg_course",
      "pg_department",
      "pg_university",
      "other_pg_university",
      "pg_institution",
      "other_pg_institution",
      "pg_year_of_completion",
      "phd_course",
      "phd_department",
      "phd_university",
      "other_phd_university",
      "phd_institution",
      "other_phd_institution",
      "phd_year_of_completion",
    ],
  },
  {
    module: "Current Experience",
    keys: [
      "institution_name",
      "department",
      "designation",
      "address",
      "country",
      "state",
      "industry_experience",
      "professional_experience",
    ],
  },
  {
    module: "Past Experience",
    keys: ["affiliations"],
  },
  {
    module: "Publications",
    keys: ["publication"],
  },
  {
    module: "Current Journal Roles",
    keys: ["current_journal_roles"],
  },
  {
    module: "Achievements",
    keys: ["achievements"],
  },
];

const CampaignDetailsPopup = ({ onHide, userData }) => {
  const dispatch = useDispatch();
  // const [isMoreRemark, setIsMoreRemark] = useState(true);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  const fetchUserDetails = async () => {
    setLoader(true);
    const response = await dispatch(
      fetchUserProfile(
        objectToFormData({
          user_id: userData?.id,
          compaing_id: userData?.compaing_id,
        })
      )
    );
    setData(response?.data);
    setLoader(false);
  };
  useEffect(() => {
    fetchUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    // remark = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi laborum ipsam id, molestias fuga iure tempora delectus, iusto error ad accusantium maxime excepturi  vitae, reiciendis totam. Animi, quos quasi?",
    profile_photo_path,
    first_name,
    last_name,
    email_id,
    country_code,
    phone_number,
    personal_details = {},
    educational_details = {},
    action_logs = {},
  } = data || [];
  const { date_of_birth, gender, country_name } = personal_details;
  const { ug_course_name } = educational_details;
  const {
    after_completion,
    before_completion,
    pending_completion,
    after_update_field,
    before_update_field,
    pending_update_profile,
  } = action_logs || {};

  let beforeFields = [];
  let afterFields = [];
  let pendingFields = [];
  if (before_update_field && typeof before_update_field === "string") {
    try {
      beforeFields = JSON.parse(before_update_field);
    } catch (error) {
      console.error(error);
    }
  }

  if (after_update_field && typeof after_update_field === "string") {
    try {
      afterFields = JSON.parse(after_update_field);
    } catch (error) {
      console.error(error);
    }
  }

  if (pending_update_profile && typeof pending_update_profile === "string") {
    try {
      pendingFields = JSON.parse(pending_update_profile);
    } catch (error) {
      console.error(error);
    }
  }

  const transformEducationDetails = (data) => {
    const levels = ["ug", "pg", "phd"];

    return levels
      .map((level) => ({
        courseType: level,
        courseName: data?.[`${level}_course_name`] || "",
        department: data?.[`${level}_department_name`] || "",
        university:
          data?.[`${level}_university_name`] ||
          data?.[`other_${level}_university`] ||
          "",
        institution: data?.[`${level}_institution_name`] || "",
        yearOfCompletion: data?.[`${level}_year_of_completion`] || "",
      }))
      ?.filter((item) => item?.courseName);
  };

  const getModuleName = (fields) => {
    if (!fields || fields?.length === 0) return [];
    const fieldSet = new Set(fields);
    const matchedModules = [];
    for (const items of compareArray) {
      if (items?.keys?.some((o) => fieldSet?.has(o))) {
        matchedModules.push(items?.module);
      }
    }
    return matchedModules;
  };

  const beforeModulesFields = getModuleName(beforeFields);
  const afterModulesFields = getModuleName(afterFields);
  const pendingModulesFields = getModuleName(pendingFields);

  return (
    <Modal
      titleBesideClose={"Profile Complete"}
      onHide={onHide}
      isTitleBorder
      size="xl"
      width={"100%"}
      isTitleVerticalCenter
    >
      {loader ? (
        <Card className="pt-5 pb-5">
          <Loader size="sm" />
        </Card>
      ) : (
        <Card className="cps-16 cpe-16 cpt-20 cpb-20">
          <div className="row gy-3">
            <div className="col-lg-4">
              <div className="border rounded p-3 h-100">
                <div className="profile-block d-flex flex-column justify-content-center cmb-10 cmt-20">
                  <div className="cmb-20">
                    <Profile
                      isRounded
                      url={profile_photo_path}
                      text={`${first_name} ${last_name}`}
                      size="s-168"
                      isS3UserURL
                      isRoundedBorder
                      isVerifiedIcon
                    />
                  </div>
                  <div className="text-center text-20-500 cmb-15">
                    {titleCaseString(`${first_name} ${last_name}`)}
                  </div>
                  <ProfileProgressBar
                    profileCount={
                      after_completion ? after_completion : before_completion
                    }
                  />
                </div>
                <div className="d-flex align-items-center gap-2 cmb-18 flex-wrap tex-wrap">
                  <i className="bi bi-envelope" /> {email_id ? email_id : "-"}
                </div>
                {phone_number && (
                  <div className="d-flex align-items-center gap-2 cmb-18 flex-wrap tex-wrap">
                    <i className="bi bi-telephone" />
                    {phone_number
                      ? `${getCountryCode(
                          country_code || "IN"
                        )} ${phone_number}`
                      : "-"}
                  </div>
                )}

                <div className="d-flex align-items-center gap-2 cmb-18 flex-wrap tex-wrap">
                  <i className="bi bi-globe" />
                  {country_name ? country_name : "-"}
                </div>

                <div className="row cmb-20">
                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <span>DOB</span>
                      <span className="text-15-600">
                        {date_of_birth
                          ? moment(date_of_birth).format("DD-MM-YYYY")
                          : "-"}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="d-flex flex-column">
                      <span>Gender</span>
                      <span className="text-15-600">
                        {gender ? gender : "-"}
                      </span>
                    </div>
                  </div>
                </div>

                {/* <div>Remarks</div>
              <p className="mb-0">
                {isMoreRemark ? `${remark.substring(0, 100)}...` : remark}
                <span
                  onClick={() => {
                    setIsMoreRemark(!isMoreRemark);
                  }}
                  style={{ color: "blue", cursor: "pointer" }}
                >
                  {isMoreRemark ? " Read more" : " Show less"}
                </span>
              </p> */}
              </div>
            </div>
            <div className="col-lg-8 h-100">
              {ug_course_name && (
                <div className="border rounded p-3 cmb-23">
                  <div className="text-15-600 color-new-car cmb-20">
                    Education Details:
                  </div>
                  <EducationDetails
                    educationDetails={transformEducationDetails(
                      educational_details
                    )}
                  />
                </div>
              )}

              <div className="h-100">
                <div className="row gy-3">
                  <ProfileChangesDetails
                    items={beforeModulesFields}
                    percentage={
                      before_completion ? `${before_completion}%` : "-"
                    }
                    title="Before Changes:"
                  />
                  <ProfileChangesDetails
                    items={afterModulesFields}
                    percentage={after_completion ? `${after_completion}%` : "-"}
                    title="After Changes:"
                  />
                  <ProfileChangesDetails
                    items={pendingModulesFields}
                    percentage={
                      pending_completion ? `${pending_completion}%` : "-"
                    }
                    title="Pending:"
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
      )}
    </Modal>
  );
};

export default CampaignDetailsPopup;

import React, { useEffect, useState } from "react";
import { titleCaseString } from "utils/helpers";

const ReviewReportDetails = ({
  elem,
  paperData,
  isOldReport,
  isNewReportDetails,
  isFinalReport,
}) => {
  const [scores, setScores] = useState([
    { id: "originality", label: "Originality", score: 0 },
    {
      id: "contribution_to_the_field",
      label: "Contribution to the Field",
      score: 0,
    },
    { id: "technical_quality", label: "Technical Quality", score: 0 },
    {
      id: "clarity_of_presentation",
      label: "Clarity of Presentation",
      score: 0,
    },
    { id: "depth_of_research", label: "Depth of Research", score: 0 },
  ]);

  useEffect(() => {
    const data = isNewReportDetails ? elem?.reviewer_result : elem;
    if (data) {
      const updatedScores = scores?.map((s) => {
        if (Object.keys(data)?.includes(s.id)) {
          return {
            ...s,
            score: data[s?.id],
          };
        }
        return s;
      });

      setScores(updatedScores);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewReportDetails ? elem?.reviewer_result : elem]);

  const {
    paperId,
    // paperTitle, reviewer_revision_reasons
  } = paperData || {};
  const {
    general_comment,
    recommendation,
    abstract_result,
    overall,
    reviewer_result,
    revision_remarks,
    paper_title: newPaperTitle,
    review_under_processing,
    admin_status,
  } = elem || {};
  const { overall: newOverall, abstract_result: newAbstractResult } =
    reviewer_result || {};
  const overallScore = isNewReportDetails ? newOverall : overall;
  // const paperName = isNewReportDetails ? newPaperTitle : paperTitle;
  const paperName = isNewReportDetails ? newPaperTitle : newPaperTitle;
  const abstractResultScore = isNewReportDetails
    ? newAbstractResult
    : abstract_result;

  return (
    <div className="form-block cps-24 cpt-12 cpe-24 cpb-12">
      {review_under_processing === "1" ? (
        <div className="text-14-400-17 color-6866 cmb-18 cmt-16">
          Review is under processing
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-md-6">
              <div className="text-14-500-17 color-6866 cmb-12">
                Abstract ID :
              </div>
              {paperId && (
                <div className="text-14-300-17 color-6866 cmb-24">
                  {paperId}
                </div>
              )}

              <div className="text-14-500-17 color-6866 cmb-12">
                Manuscript Title :
              </div>
              {isFinalReport
                ? newPaperTitle
                : paperName && (
                    <div className="text-14-300-17 color-6866 cmb-24">
                      {titleCaseString(
                        isFinalReport ? newPaperTitle : paperName
                      )}
                    </div>
                  )}

              <div className="text-14-500-17 color-6866 cmb-12">
                Recommendation :
              </div>
              {recommendation && (
                <div className="text-14-300-17 color-6866 cmb-24">
                  {titleCaseString(recommendation)}
                </div>
              )}

              <div className="text-14-500-17 color-6866 cmb-12">
                General Comments :
              </div>
              {general_comment && (
                <div className="text-14-300-17 color-6866 cmb-24">
                  {titleCaseString(recommendation)}
                </div>
              )}
            </div>

            <div className="col-md-6">
              <div className="text-14-500-17 color-6866 cmb-12">
                Followed Rates :
              </div>
              <div className="text-14-300-17 color-6866 cmb-24">
                (1 = Poor) , (2 = Fair) , (3 = Good) , (4 = Excellent)
              </div>
              <div className="border br-5 cpt-25 cpb-16">
                <div className="d-flex align-items-center justify-content-center text-16-500-200 color-new-car border-bottom cpb-16">
                  Evaluation Report
                </div>
                <div className="cpt-23 cpb-16 border-bottom">
                  {scores?.map((item, index) => (
                    <div
                      className="d-flex align-items-center cmb-16 text-14-500-17 color-6866 row"
                      key={index}
                    >
                      <div className="d-flex justify-content-end col-lg-8 col-md-8 col-sm-6">
                        {item?.label}{" "}
                        <span className="ms-lg-4 ms-md-4 ms-sm-3">:</span>
                      </div>
                      <div className="d-flex justify-content-start col-lg-4 col-md-4 col-sm-6">
                        {item?.score}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="d-flex align-items-center text-14-500-17 color-6866 cpt-16 row">
                  <div className="d-flex justify-content-end col-lg-8 col-md-8 col-sm-6">
                    Overall<span className="ms-lg-4 ms-md-4 ms-sm-3">:</span>
                  </div>
                  <div className="d-flex justify-content-start col-lg-4 col-md-4 col-sm-6">
                    {`${overallScore} ${
                      overallScore > 0.0
                        ? `(${
                            overallScore <= 1
                              ? "Poor"
                              : overallScore <= 2
                              ? "Fair"
                              : overallScore <= 3
                              ? "Good"
                              : "Excellent"
                          })`
                        : 0
                    }`}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center gap-2 text-14-500-17 color-6866 cmb-15">
            <div>Abstract Result :</div>
            <div>{abstractResultScore}</div>
          </div>

          {(isOldReport || isNewReportDetails) && (
            <>
              <div className="text-20-400-25 color-1717 cmb-13 ">
                {`Your score is ${overallScore} ${
                  overallScore > 0.0
                    ? `out of 5 (${
                        overallScore <= 1
                          ? "Poor"
                          : overallScore <= 2
                          ? "Fair"
                          : overallScore <= 3
                          ? "Good"
                          : "Excellent"
                      })`
                    : 0
                }`}
              </div>

              {revision_remarks && (
                <div className="text-16-500-20 color-1717 cmb-19">
                  Revision Reason: {titleCaseString(revision_remarks)}
                </div>
              )}
            </>
          )}

          {admin_status === "1" && (
            <div className="text-14-400-17 color-6866 cmb-18 cmt-16">
              Review has been Completed !!
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ReviewReportDetails;

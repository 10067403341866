import Modal from "components/Layout/Modal";
import React from "react";
import { titleCaseString } from "utils/helpers";

const UnSubscribedPopup = ({ showContent }) => {
  console.log("showContent", showContent);

  let isSuccess = showContent?.status === 200;
  return (
    <Modal size="md" hideClose className="fadeInUp">
      <div className="d-flex flex-column align-items-center justify-content-center cmb-20 gap-4">
        <div
          style={{ width: "8rem", height: "8rem", borderRadius: "50%" }}
          className={`${
            isSuccess ? "bg-f6ee" : "bg-e4e4"
          }  d-flex align-items-center justify-content-center`}
        >
          <i
            className={`${
              isSuccess ? "bi bi-check2 color-a16d" : "bi bi-x-lg color-8c7e"
            }  `}
            style={{ fontSize: "4.375rem" }}
          />
        </div>
        {isSuccess ? (
          <>
            <div className="text-center text-20-600">
              {titleCaseString(showContent?.message)}
            </div>
            <div className="text-center text-16-400">
              We have informed you from this email that you will not received
              more emails from IFERP.
            </div>
          </>
        ) : (
          <div className="text-center text-20-600">
            {titleCaseString(showContent?.message)}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UnSubscribedPopup;

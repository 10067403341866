import Card from "components/Layout/Card";
import React, { useState } from "react";
import Overall from "./Overall";
import DetailedView from "./DetailedView";
import "./NewSubmittedPapers.scss";

const NewSubmittedPapers = () => {
  const [tabType, setTabType] = useState("0");
  const activeClass = "p-2 color-new-car text-16-400-20 active-border-bottom";
  const inActiveClass = "p-2 color-6866 text-16-400-20 pointer";
  return (
    <>
      <Card className="d-flex gap-4 align-items-center p-1 unset-br mb-3">
        <div
          className={tabType === "0" ? activeClass : inActiveClass}
          onClick={() => {
            setTabType("0");
          }}
        >
          Overall
        </div>
        <div
          className={tabType === "1" ? activeClass : inActiveClass}
          onClick={() => {
            setTabType("1");
          }}
        >
          Detailed View
        </div>
      </Card>
      {tabType === "0" && <Overall />}
      {tabType === "1" && <DetailedView />}
    </>
  );
};

export default NewSubmittedPapers;

import Button from "components/form/Button";
import TextEditor from "components/form/TextEditor";
import Modal from "components/Layout/Modal";
import { isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  throwError,
  throwSuccess,
  updateAbstractOrRevision,
} from "store/slices";
import { objectToFormData } from "utils/helpers";

const UpdateAbstractByResourceForm = ({ onHide, handleSuccess, elem }) => {
  const dispatch = useDispatch();
  const [abstract, setAbstract] = useState(elem?.paper_abstract || "");
  const [btnLoading, setBtnLoading] = useState(false);
  const id =
    elem?.update_type === "Revision" ? elem?.revision_id : elem?.abstract_id;

  const handleChange = (e) => {
    setAbstract(e.target.value);
  };
  const handleSubmit = async () => {
    setBtnLoading(true);
    const payload = { ...elem, paper_abstract: abstract };
    const response = await dispatch(
      updateAbstractOrRevision(id, objectToFormData(payload))
    );
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      onHide();
      handleSuccess();
      setAbstract("");
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }
    setBtnLoading(false);
  };

  useEffect(() => {
    if (elem?.paper_abstract) {
      setAbstract(elem?.paper_abstract);
    }
  }, [elem]);
  return (
    <Modal onHide={onHide}>
      <div className=" cmb-24">
        <TextEditor
          label="Abstract*"
          id="paper_abstract"
          value={abstract}
          onChange={handleChange}
          placeholder="Update paper abstract"
        />
      </div>

      <div className="d-flex">
        <Button
          text="Submit"
          btnStyle="primary-dark"
          className="cps-20 cpe-20 h-35"
          onClick={handleSubmit}
          btnLoading={btnLoading}
          disabled={isEqual(abstract, elem?.paper_abstract)}
        />
      </div>
    </Modal>
  );
};

export default UpdateAbstractByResourceForm;

// import { icons } from "utils/constants";
import "./NoInternet.scss";

const NoInternet = () => {
  return (
    <div
      id="offline-connection-container"
      className="d-flex align-items-center justify-content-center vh-100"
    >
      {/* <img src={icons.noInternet} alt="no-internet" /> */}
      <div className="body-container">
        <div className="image-container">
          {/* <img src={noInternet} alt="no internet" /> */}
          {/* <i className="fa fa-users" /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40.636"
            height="38.074"
            viewBox="0 0 40.636 38.074"
          >
            <g
              id="Group_3807"
              data-name="Group 3807"
              transform="translate(632.14 -18312.355)"
            >
              <g id="Group_3806" data-name="Group 3806">
                <path
                  id="Path_2541"
                  data-name="Path 2541"
                  d="M424.19,362.89s.49,0,1.4-.1a7.34,7.34,0,0,0,3.54-1.58,6.63,6.63,0,0,0,2.54-5.26,7.15,7.15,0,0,0-4-6.25l-.13-.06v-.15a4.87,4.87,0,0,0-8.33-3.62l-.33.33-.1-.46a9.5,9.5,0,0,0-5.84-6.67,8.61,8.61,0,0,0-4.74-.52,9,9,0,0,0-4.16,2,10.331,10.331,0,0,0-2.66,3.53,9,9,0,0,0-.78,4.06v.32l-.3-.08a6.88,6.88,0,0,0-4.58.54,7.52,7.52,0,0,0-3.14,2.82,6.2,6.2,0,0,0-.86,3.61,7.15,7.15,0,0,0,.94,3.07,7.42,7.42,0,0,0,3.29,3.08,12.768,12.768,0,0,0,1.54.54,4.76,4.76,0,0,1-1.6-.41,7.58,7.58,0,0,1-4.5-6.25,6.43,6.43,0,0,1,.86-3.83,7.83,7.83,0,0,1,3.28-3,7.3,7.3,0,0,1,4.88-.6l-.29.24a9.42,9.42,0,0,1,.81-4.29,10.509,10.509,0,0,1,2.78-3.72,9.45,9.45,0,0,1,4.39-2.12,9.12,9.12,0,0,1,5,.54,10,10,0,0,1,6.15,7.06l-.43-.13a5.34,5.34,0,0,1,9.14,4l-.13-.21A7.52,7.52,0,0,1,432,356a6.52,6.52,0,0,1-.85,3.29,7.48,7.48,0,0,1-5.58,3.69,4.8,4.8,0,0,1-1.38-.09Z"
                  transform="translate(-1023.505 17974.43)"
                  fill="#56b093"
                />
                <path
                  id="Path_2542"
                  data-name="Path 2542"
                  d="M409.58,355a4.925,4.925,0,0,1-.38.75l-1.12,2L404,365l-.18-.31h5.3L409,365c-.83,3-1.74,6.39-2.71,9.93l-.45-.23,2.34-2.94,7.88-9.84.18.38-3.39-.05h-.36l.16-.32c1.35-2.65,2.54-5,3.52-6.93l.14.23-5-.13-1.34-.06a1.831,1.831,0,0,1-.47-.05,2.172,2.172,0,0,1,.43,0h6.58l-.12.24c-.95,2-2.1,4.31-3.41,7l-.2-.32h3.88l-.3.38-7.84,9.87L406.28,375l-.79,1,.34-1.22c1-3.54,1.92-6.88,2.76-9.91l.22.29-5-.08h-.35l.18-.3,4.33-7.2c.49-.8.89-1.45,1.2-1.94a5.369,5.369,0,0,1,.41-.64Z"
                  transform="translate(-1023.505 17974.43)"
                  fill="#56b093"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="title-container">Whoops!</div>
        <div className="message-container">
          No internet connection found. Check
          <br />
          your connection or try again.
        </div>
      </div>
    </div>
  );
};
export default NoInternet;

import Card from "components/Layout/Card";
import React from "react";
import { icons } from "utils/constants";

const AbstractRegistrationPass = () => {
  return (
    <div className="bg-new-car-light br-15 cps-13 cpt-13 cpe-13 cpb-13 pass-block">
      <Card className="pass-details br-25 cps-25 cpt-20 cpe-25 cpb-20">
        <div className="row gy-5">
          <div className="col-lg-4 col-md-4">
            <div style={{ maxWidth: "170px", maxHeight: "170px" }}>
              <img
                src={icons?.dummyQRForPass}
                alt="qr"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </div>
          <div className="col-lg-8 col-md-8 user-info">
            <h1 className="text-24-500-30 color-new-car text-center">
              Sri Hari Krishnan
            </h1>
            <p className="text-14-500-23 color-new-car text-center">
              2nd International Conference on Advances in Science, Engineering &
              Technology(ICASET-2024)
            </p>
            <div className="d-flex align-items-center gap-3 cmb-20 justify-content-center">
              <div className="d-flex gap-2 align-items-center text-14-300-17 color-1717">
                <i className="bi bi-calendar3-week" />
                27-July-2024
              </div>
              <div className="d-flex gap-2 align-items-center text-14-300-17 color-1717">
                <i className="bi bi-clock" />
                10am - 11:30am
              </div>
            </div>
            <div className="d-flex align-items-center gap-2 text-14-300-17 color-1717 justify-content-center">
              <i className="bi bi-geo-alt" />
              1st floor, Marina Hall, Singapore
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default AbstractRegistrationPass;

import Card from "components/Layout/Card";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import SessionLogs from "./SessionLogs";
import ActivityLogs from "./ActivityLogs";

const UserActivityManagement = () => {
  const params = useParams();
  const { type } = params;
  const navigate = useNavigate();
  const activeClass = "p-2 bg-new-car color-white text-15-500";
  const inActiveClass = "p-2 color-dark-blue text-15-500 pointer";

  return (
    <div>
      <Card className="d-flex align-items-center justify-content-between p-1 unset-br mb-3 flex-wrap">
        <div className="d-flex align-items-center flex-wrap gap-2">
          <div
            className={type === "activity-logs" ? activeClass : inActiveClass}
            onClick={() => {
              navigate(`/admin/activity-management/activity-logs`);
            }}
          >
            Activity Logs
          </div>
          <div
            className={type === "session-logs" ? activeClass : inActiveClass}
            onClick={() => {
              navigate(`/admin/activity-management/session-logs`);
            }}
          >
            Session Logs
          </div>
        </div>
      </Card>

      {type === "activity-logs" && <ActivityLogs />}
      {type === "session-logs" && <SessionLogs />}
    </div>
  );
};

export default UserActivityManagement;

import Button from "components/form/Button";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { eventPlagiarismDocumentPath, icons } from "utils/constants";
import {
  downloadFile,
  formatDate,
  generatePreSignedUrl,
  titleCaseString,
} from "utils/helpers";

const UserPlagiarismState = ({ data, setIsAddRevisionAbstract, paperData }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { paperType } = params;
  const [isPlagiarismDetails, setIsPlagiarismDetails] = useState(false);
  const [isPlagiarismRevisionDetails, setIsPlagiarismRevisionDetails] =
    useState(null);

  const {
    status,
    paper,
    paperDate,
    plagiarismStatus,
    plagiarismComment,
    plagiarismDocument,
    plagiarismExpired,
    plagiarismAcceptedDate,
    revisionStatus,
    revisionReasons,
  } = data;

  const { plagiarism_revision, submittedPapersStatus } = paperData;
  const { is_plagiarism_done } = submittedPapersStatus || {};

  useEffect(() => {
    if (plagiarism_revision?.length > 0) {
      setIsPlagiarismRevisionDetails(plagiarism_revision?.length - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paperData?.plagiarism_revision]);

  // const plagiarismAccepted = +plagiarismStatus === 1;

  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div className={`steps-block ${status >= 1 ? "active" : "not-active"}`}>
          {is_plagiarism_done === "1" ? (
            <img src={icons?.RightIcon} alt="right" />
          ) : (
            "2"
          )}
          {/* {plagiarismAccepted ? (
            <img src={icons?.RightIcon} alt="right" />
          ) : (
            "2"
          )} */}
        </div>
        <div>
          <div
            className={`text-16-500-20 ${
              status >= 1 ? "color-new-car" : "color-6866"
            }`}
          >
            Plagiarism{" "}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-start">
        <div className="sparator-block">
          <span className="hr-line" />
        </div>

        <div className="cpt-14 cpb-14 w-100">
          {plagiarism_revision?.length === 0 ? (
            <>
              {/*old design */}
              {plagiarismStatus !== "1" && paperDate && (
                <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 ">
                  <img src={icons.calendarCheck} alt="calendar" />
                  {formatDate(paperDate)}
                </div>
              )}
              {plagiarismStatus !== "1" && status < 2 && !plagiarismStatus && (
                <div className="cmb-14 text-14-400-18 color-black-olive">
                  Under processing
                </div>
              )}
              {plagiarismStatus === "3" && plagiarismComment && (
                <div className="text-14-300 color-black-olive">
                  {titleCaseString(plagiarismComment)}
                </div>
              )}

              {revisionReasons?.length > 0 &&
                revisionReasons?.map((revComment, index) => {
                  return (
                    <div
                      className="text-14-300 color-black-olive cmb-16 w-100"
                      key={index}
                    >
                      <div className="d-flex gap-2 w-100">
                        <div className="color-new-car text-nowrap">
                          {`Revision ${index + 1}:`}
                        </div>
                        <div className="d-flex flex-grow-1">
                          {titleCaseString(revComment?.revision_comment)}
                        </div>
                      </div>
                      <div
                        className="cmb-16 cmt-14 text-16-500-20 color-7cff d-flex gap-2 align-items-center pointer"
                        onClick={async () => {
                          if (paper) {
                            let downloadContent = "";
                            if (paperType === "abstract") {
                              downloadContent = await generatePreSignedUrl(
                                plagiarismDocument,
                                eventPlagiarismDocumentPath
                              );
                            }
                            dispatch(downloadFile(downloadContent));
                          }
                        }}
                      >
                        Download Plagiarism Report{" "}
                        <span className="text-14-300 color-black-olive">
                          {`(Report will be available ${plagiarismExpired} days)`}
                        </span>
                      </div>
                    </div>
                  );
                })}
              {/*  ["1", "3"].includes(plagiarismStatus) &&
                  plagiarismComment && (
                    <div className="cmb-14 d-flex gap-2 text-14-300 color-black-olive cmb-16">
                      {titleCaseString(plagiarismComment)}
                    </div>
                  ) */}

              {plagiarismStatus === "2" && revisionStatus === "2" && (
                <div className="d-flex">
                  <Button
                    text={`+ Add Abstract`}
                    btnStyle="primary-dark"
                    className="text-15-500 cps-15 cpe-15 h-30"
                    onClick={() => {
                      setIsAddRevisionAbstract(true);
                    }}
                  />
                </div>
              )}
              {plagiarismStatus === "1" && (
                <>
                  {plagiarismAcceptedDate && (
                    <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 cmt-14">
                      <img src={icons.calendarCheck} alt="calendar" />
                      {formatDate(plagiarismAcceptedDate)}
                    </div>
                  )}
                  {plagiarismComment && (
                    <div className="text-14-300 color-black-olive">
                      {titleCaseString(plagiarismComment)}
                    </div>
                  )}
                  {plagiarismExpired && plagiarismExpired !== 0 && (
                    <div
                      className="cmb-16 cmt-14 text-16-500-20 color-7cff d-flex gap-2 align-items-center pointer"
                      onClick={async () => {
                        if (paper) {
                          let downloadContent = "";
                          if (paperType === "abstract") {
                            downloadContent = await generatePreSignedUrl(
                              plagiarismDocument,
                              eventPlagiarismDocumentPath
                            );
                          }
                          dispatch(downloadFile(downloadContent));
                        }
                      }}
                    >
                      Download Report{" "}
                      <span className="text-14-300 color-black-olive">
                        {`(Report will be available ${plagiarismExpired} days)`}
                      </span>
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {/*new design*/}
              <div className="plagiarism-stat-block border br-5 w-100 cmb-8">
                <div
                  className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer cps-24 cpt-12 cpe-24 cpb-12 bg-f4ff"
                  onClick={() => {
                    setIsPlagiarismDetails(!isPlagiarismDetails);
                  }}
                >
                  <span>Submitted Paper</span>
                  <i
                    className={
                      isPlagiarismDetails
                        ? "bi bi-chevron-up"
                        : "bi bi-chevron-down"
                    }
                    style={{ fontSize: "15px" }}
                  />
                </div>
                {isPlagiarismDetails && (
                  <div className="cps-24 cpt-12 cpe-24 cpb-12">
                    {paperDate && (
                      <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 ">
                        <img src={icons.calendarCheck} alt="calendar" />
                        {formatDate(paperDate)}
                      </div>
                    )}

                    <div className="d-flex gap-2 w-100">
                      <div className="color-new-car text-nowrap">
                        {`Submitted Paper Revision :`}
                      </div>
                      <div className="d-flex flex-grow-1">
                        {titleCaseString(
                          `${revisionReasons?.[0]?.revision_comment}`
                        )}
                      </div>
                    </div>

                    <div
                      className="cmb-16 cmt-14 text-16-500-20 color-7cff d-flex gap-2 align-items-center pointer"
                      onClick={async () => {
                        if (plagiarismDocument) {
                          let downloadContent = "";
                          if (paperType === "abstract") {
                            downloadContent = await generatePreSignedUrl(
                              plagiarismDocument,
                              eventPlagiarismDocumentPath
                            );
                          }
                          dispatch(downloadFile(downloadContent));
                        }
                      }}
                    >
                      Download Report{" "}
                      <span className="text-14-300 color-black-olive">
                        {`(Report will be available ${plagiarismExpired} some days)`}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              {plagiarism_revision?.map((data, index) => {
                const {
                  plagiarism_status,
                  plagiarism_date,
                  plagiarism_comment,
                  revision_paper_submitted_date,
                  plagiarism_document,
                  plagiarism_expired,
                } = data;
                const isOpen = isPlagiarismRevisionDetails === index;
                return (
                  <React.Fragment key={index}>
                    <div
                      className="revision-block border br-5 w-100 cmb-8"
                      key={index + 1}
                    >
                      <div
                        className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12"
                        onClick={() => {
                          setIsPlagiarismRevisionDetails(isOpen ? null : index);
                        }}
                      >
                        <span>Revision {index + 1}</span>
                        <i
                          className={`${
                            isOpen ? "bi bi-chevron-up" : "bi bi-chevron-down"
                          }`}
                          style={{ fontSize: "15px" }}
                        />
                      </div>
                      <div className="cps-24 cpe-24">
                        {isOpen &&
                          (plagiarism_status === "0" ? (
                            <div className="cpt-12 cpb-12">
                              <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 ">
                                <img src={icons.calendarCheck} alt="calendar" />
                                {formatDate(revision_paper_submitted_date)}
                              </div>
                              <div className="cmb-14 text-14-400-18 color-black-olive">
                                Under processing
                              </div>
                            </div>
                          ) : plagiarism_status === "2" ? (
                            <div className="cpt-12 cpb-12">
                              {plagiarism_date && (
                                <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 ">
                                  <img
                                    src={icons.calendarCheck}
                                    alt="calendar"
                                  />
                                  {formatDate(plagiarism_date)}
                                </div>
                              )}
                              <div className="d-flex gap-2 cmb-14">
                                <div className="color-new-car text-nowrap">
                                  {`Revision ${index + 1}:`}
                                </div>
                                <div className="d-flex flex-grow-1">
                                  {titleCaseString(plagiarism_comment)}
                                </div>
                              </div>

                              <div
                                className="cmb-16 cmt-14 text-16-500-20 color-7cff d-flex gap-2 align-items-center pointer"
                                onClick={async () => {
                                  if (plagiarism_document) {
                                    let downloadContent = "";
                                    if (paperType === "abstract") {
                                      downloadContent =
                                        await generatePreSignedUrl(
                                          plagiarism_document,
                                          eventPlagiarismDocumentPath
                                        );
                                    }
                                    dispatch(downloadFile(downloadContent));
                                  }
                                }}
                              >
                                Download Report{" "}
                                <span className="text-14-300 color-black-olive">
                                  {`(Plagiarism Report will be available ${plagiarism_expired} some days)`}
                                </span>
                              </div>

                              {plagiarism_revision?.length - 1 === index && (
                                <div className="d-flex">
                                  <Button
                                    text={`+ Add Abstract`}
                                    btnStyle="primary-dark"
                                    className="text-15-500 cps-15 cpe-15 h-30"
                                    onClick={() => {
                                      setIsAddRevisionAbstract(true);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          ) : plagiarism_status === "1" ? (
                            <div className="cpt-12 cpb-12">
                              <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 ">
                                <img src={icons.calendarCheck} alt="calendar" />
                                {formatDate(plagiarism_date)}
                              </div>

                              <div className="text-14-300 color-black-olive">
                                {titleCaseString(plagiarism_comment)}
                              </div>

                              <div
                                className="cmb-16 cmt-14 text-16-500-20 color-7cff d-flex gap-2 align-items-center pointer"
                                onClick={async () => {
                                  if (plagiarism_document) {
                                    let downloadContent = "";
                                    if (paperType === "abstract") {
                                      downloadContent =
                                        await generatePreSignedUrl(
                                          plagiarism_document,
                                          eventPlagiarismDocumentPath
                                        );
                                    }
                                    dispatch(downloadFile(downloadContent));
                                  }
                                }}
                              >
                                Download Report{" "}
                                <span className="text-14-300 color-black-olive">
                                  {`(Report will be available ${plagiarism_expired} some days)`}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="cpt-12 cpb-12">
                              <div className="date-block d-flex gap-2 align-items-center cmb-14 color-black-olive text-14-300 ">
                                <img src={icons.calendarCheck} alt="calendar" />
                                {formatDate(plagiarism_date)}
                              </div>

                              <div className="text-14-300 color-black-olive">
                                {titleCaseString(plagiarism_comment)}
                              </div>

                              <div
                                className="cmb-16 cmt-14 text-16-500-20 color-7cff d-flex gap-2 align-items-center pointer"
                                onClick={async () => {
                                  if (plagiarism_document) {
                                    let downloadContent = "";
                                    if (paperType === "abstract") {
                                      downloadContent =
                                        await generatePreSignedUrl(
                                          plagiarism_document,
                                          eventPlagiarismDocumentPath
                                        );
                                    }
                                    dispatch(downloadFile(downloadContent));
                                  }
                                }}
                              >
                                Download Report{" "}
                                <span className="text-14-300 color-black-olive">
                                  {`(Report will be available ${plagiarism_expired} some days)`}
                                </span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default UserPlagiarismState;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { icons } from "utils/constants";
import Button from "../../form/Button";
import RadioInput from "../../form/RadioInput/RadioInput";
import Modal from "../Modal";
import CalendarFilterLayout from "../CalendarFilterLayout";
import "./ExportButton.scss";

const ExportButton = ({
  exportAPI,
  payload = "",
  showExportType,
  isFilter,
  setFilterDates,
  filterDates,
  payloadID,
  newHeight,
  buttonText,
}) => {
  const dispatch = useDispatch();
  const [isPicker, setIsPicker] = useState(false);
  const [type, setType] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [isCalenderView, setIsCalenderView] = useState(false);
  const [isExportType, setIsExportType] = useState(false);
  const resetDates = {
    start_date: "",
    end_date: "",
    date_filter: "",
  };
  const handelExport = async () => {
    if (exportAPI) {
      setBtnLoading(true);
      if (payloadID) {
        await dispatch(exportAPI(payloadID, payload));
      } else {
        await dispatch(exportAPI(payload));
      }
      setBtnLoading(false);
      setIsExportType(false);
      setType("");
      if (isFilter) {
        setFilterDates(resetDates);
        setIsCalenderView(false);
      }
    }
  };

  const handelChangeExportPayload = (data) => {
    const { end_date: endDate, start_date: startDate, value } = data?.target;
    if (value === "custom") {
      let newData = {
        start_date: startDate,
        end_date: endDate,
        date_filter: value,
      };
      setFilterDates(newData);
    } else {
      setFilterDates({ date_filter: value });
    }
    // const [startDate, endDate] = range;
    // let newData = {
    //   start_date: startDate,
    //   end_date: endDate,
    // };
    // setFilterDates(newData);
  };

  useEffect(() => {
    return () => {
      setType("");
    };
  }, []);

  let isShowCross = filterDates?.start_date && filterDates?.end_date;
  let dateInputValue =
    filterDates?.start_date && filterDates?.end_date
      ? `${filterDates?.start_date} - ${filterDates?.end_date}`
      : filterDates?.date_filter;

  return (
    <>
      {isPicker && (
        <CalendarFilterLayout
          onHide={() => {
            setIsPicker(false);
          }}
          onChange={handelChangeExportPayload}
          className="position-absolute"
        />
      )}

      <div className="export-button-container">
        {isCalenderView && (
          <Modal
            size={"md"}
            onHide={() => {
              setFilterDates(resetDates);
              setIsCalenderView(false);
            }}
            // title="Export As"
          >
            <div className="d-flex justify-content-center align-items-center flex-column flex-wrap">
              <div className="date-input-container">
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="Select Date Range"
                    className="text-input"
                    value={dateInputValue}
                    onClick={() => {
                      setIsPicker(true);
                    }}
                    readOnly
                  />
                  {isShowCross ? (
                    <span
                      className={`close-icon pointer`}
                      onClick={() => {
                        setFilterDates({
                          start_date: "",
                          end_date: "",
                        });
                      }}
                    >
                      <i className="bi bi-x text-24-500 color-gray" />
                    </span>
                  ) : (
                    <span className={`calender-icon w-fit left-calender-icon`}>
                      <img src={icons.calendar} alt="calender" />
                    </span>
                  )}
                </div>
              </div>

              {/* <DateRangePicker
              placeholder="Select Date Range"
              startDate={filterDates?.start_date}
              endDate={filterDates?.end_date}
              onChange={handleDateRangeChange}
              isClearable
            /> */}

              <div className="d-flex justify-content-center gap-4 cmt-40">
                <Button
                  text="Cancel"
                  isRounded
                  btnStyle="light-outline"
                  className="cps-20 cpe-20"
                  onClick={() => {
                    setFilterDates(resetDates);
                    setIsCalenderView(false);
                  }}
                />
                <Button
                  isRounded
                  text="Submit"
                  btnStyle="primary-dark"
                  className="cps-20 cpe-20"
                  onClick={() => {
                    handelExport();
                  }}
                  type="submit"
                  btnLoading={btnLoading}
                  // disabled={!filterDates?.start_date && !filterDates?.end_date}
                />
              </div>
            </div>
          </Modal>
        )}

        {isExportType && (
          <Modal
            onHide={() => {
              setIsExportType(false);
            }}
            title="Export As"
          >
            <div className="cpt-50 cpb-20">
              <div className="d-flex justify-content-center align-items-center gap-5">
                <div className="d-flex">
                  <RadioInput
                    value={1}
                    onChange={() => {
                      setType(1);
                    }}
                    checked={type === 1}
                  />
                  <div className="text-center type-block-container ms-3">
                    <img src={icons.pdf} alt="pdf" />
                    <div className="text-16-400 color-black-olive mt-2">
                      PDF
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <RadioInput
                    value={2}
                    onChange={() => {
                      setType(2);
                    }}
                    checked={type === 2}
                  />
                  <div className="text-center type-block-container ms-3">
                    <img src={icons.excel} alt="excel" />
                    <div className="text-16-400 color-black-olive mt-2">
                      Excel
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <RadioInput
                    value={3}
                    onChange={() => {
                      setType(3);
                    }}
                    checked={type === 3}
                  />
                  <div className="text-center type-block-container ms-3">
                    <img src={icons.jpg} alt="jpg" />
                    <div className="text-16-400 color-black-olive mt-2">
                      JPG
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center gap-4 cmt-40">
                <Button
                  text="Cancel"
                  isRounded
                  btnStyle="light-outline"
                  className="cps-40 cpe-40"
                  onClick={() => {
                    setIsExportType(false);
                  }}
                />
                <Button
                  isRounded
                  text="Submit"
                  btnStyle="primary-dark"
                  className="cps-40 cpe-40"
                  onClick={() => {
                    handelExport();
                  }}
                  type="submit"
                  btnLoading={btnLoading}
                  disabled={!type}
                />
              </div>
            </div>
          </Modal>
        )}
        <Button
          isSquare
          icon={
            <img src={icons.exportIcon} alt="export" className="pe-1 h-20" />
          }
          text={` ${buttonText ? buttonText : ""} Export`}
          btnStyle="light-outline"
          className={`${newHeight ? newHeight : "h-35"} text-13-400`}
          onClick={() => {
            if (isFilter) {
              setIsCalenderView(true);
            } else {
              showExportType ? setIsExportType(true) : handelExport();
            }
          }}
          btnLoading={!isExportType && btnLoading}
        />
      </div>
    </>
  );
};
export default ExportButton;

import Button from "components/form/Button";
import RadioInput from "components/form/RadioInput";
import TextArea from "components/form/TextArea";
import Card from "components/Layout/Card";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchProfile,
  generateEvolutionReport,
  handleAcceptanceStatusOfTask,
  throwError,
  throwSuccess,
} from "store/slices";
import { icons } from "utils/constants";
import { objectToFormData, titleCaseString } from "utils/helpers";
import CongratulationPopup from "../CongratulationPopup";

const MyNewData = ({
  newElem,
  myTaskDetails,
  handleSuccess,
  isOriginalData,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { id: paperId } = params;
  const [isExpanded, setIsExpanded] = useState(false);
  const [isReject, setIsReject] = useState(false);
  const [reviewStatus, setReviewStatus] = useState("");
  const [btnLoader, setBtnLoader] = useState(false);
  const [abstractStatus, setAbstractStatus] = useState("");
  const [isSubmitPopup, setIsSubmitPopup] = useState(false);
  const [peerData, setPeerData] = useState({
    recommendation: "",
    general_comment: "",
  });

  const [scores, setScores] = useState([
    { id: "originality", label: "Originality", score: 0 },
    {
      id: "contribution_to_the_field",
      label: "Contribution to the Field",
      score: 0,
    },
    { id: "technical_quality", label: "Technical Quality", score: 0 },
    {
      id: "clarity_of_presentation",
      label: "Clarity of Presentation",
      score: 0,
    },
    { id: "depth_of_research", label: "Depth of Research", score: 0 },
  ]);

  const handlePaperRequest = async (payload) => {
    setBtnLoader(payload?.acceptance_status);

    const response = await dispatch(
      handleAcceptanceStatusOfTask(objectToFormData(payload))
    );
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      handleSuccess();
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }
    setBtnLoader("");
  };

  const handleGenerateReport = async (value) => {
    setBtnLoader("submit");
    const formData = new FormData();
    formData.append("id", paperId);
    formData.append("abstract_result", value);
    formData.append("overall", averageScore);
    formData.append("recommendation", peerData?.recommendation);
    formData.append("general_comment", peerData?.general_comment);
    if (!isOriginalData) {
      formData.append("revision_id", newElem?.id);
    }
    scores?.forEach((elem) => {
      formData.append(elem?.id, elem?.score);
    });
    const response = await dispatch(generateEvolutionReport(formData));
    if (response?.status === 200) {
      setIsReject(false);
      setIsSubmitPopup(true);
      handleSuccess();
      dispatch(fetchProfile());
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }

    setBtnLoader("");
  };

  useEffect(() => {
    const newResult = isOriginalData ? myTaskDetails?.result : newElem?.result;
    if (newResult) {
      const updatedScores = scores?.map((s) => {
        if (Object.keys(newResult)?.includes(s.id)) {
          return {
            ...s,
            score: newResult[s?.id], // Update the score with the API value
          };
        }
        return s;
      });

      setScores(updatedScores);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOriginalData ? myTaskDetails?.result : newElem?.result]);

  const handleDecrement = (index) => {
    if (scores[index]?.score > 0) {
      setScores((prev) =>
        prev?.map((item, i) =>
          i === index ? { ...item, score: item?.score - 1 } : item
        )
      );
    }
  };

  const handleIncrement = (index) => {
    if (scores[index]?.score < 4) {
      setScores((prev) =>
        prev?.map((item, i) =>
          i === index ? { ...item, score: item?.score + 1 } : item
        )
      );
    }
  };

  const averageScore = useMemo(() => {
    const totalScore = scores?.reduce((sum, item) => sum + item?.score, 0);
    return (totalScore / scores?.length)?.toFixed(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scores]);

  const toggleReadMore = () => {
    setIsExpanded((prev) => !prev);
  };

  const {
    conference_name,
    paper_title,
    paper_keywords,
    allocated_point,
    paper_abstract,
    allocated_time_limit,
    paper_abstract_id,
    reject_reason,
    acceptance_status,
    abstract_result,
    general_comment,
    recommendation,
  } = myTaskDetails || {};

  const {
    paper_title: newPaperTitle,
    paper_keywords: newPaperKeywords,
    paper_abstract: newPaperAbstract,
    // allocated_point: newAllocatedPoint,
    allocated_time_limit: newAllocatedTimeLimit,
    // paper_abstract_id: newPaperAbstractId,
    reject_reason: newRejectReason,
    general_comment: newGeneralComment,
    recommendation: newRecommendation,
    result,
  } = newElem || {};
  const {
    abstract_result: newAbstractResult,
    //   general_comment: newGeneralComment,
    //   recommendation: newRecommendation,
  } = result || {};

  const paperTitle = isOriginalData ? paper_title : newPaperTitle;
  const keywords = isOriginalData ? paper_keywords : newPaperKeywords;
  const abstract = isOriginalData ? paper_abstract : newPaperAbstract;
  const abstractResult = isOriginalData ? abstract_result : newAbstractResult;
  const allocatePoint = isOriginalData ? allocated_point : allocated_point;
  const allocateTime = isOriginalData
    ? allocated_time_limit
    : newAllocatedTimeLimit;
  const paperAbstractID = paper_abstract_id;
  const rejectReason = isOriginalData ? reject_reason : newRejectReason;
  const acceptStatus = acceptance_status;
  const gComment = isOriginalData ? general_comment : newGeneralComment;
  const recommend = isOriginalData ? recommendation : newRecommendation;

  const abstractText = abstract
    ?.replace(/<\/?p>/g, "")
    ?.replace(/<\/?span[^>]*>/g, "")
    ?.trim();

  const abstractWords = abstractText?.split(" ")?.filter((word) => word);
  const maxWords = 70;

  const isBtnVisible = scores?.some((item) => item?.score !== 0);
  const isHideIncrementDecrementButton = isOriginalData
    ? !abstract_result
    : !newAbstractResult;
  return (
    <>
      {isSubmitPopup && (
        <CongratulationPopup
          onHide={() => {
            setIsSubmitPopup(false);
          }}
        />
      )}
      <Card className="cps-44 cpt-32 cpe-44 cpb-32 cmb-20">
        <h2 className="text-16-400-20 color-new-car cmb-20">
          Conference & Abstract Info :
        </h2>
        <div className="text-14-400-17 color-new-car">Conference Name:</div>
        {conference_name && (
          <p className="text-14-300-24 color-black-olive cmb-20">
            {titleCaseString(conference_name)}
          </p>
        )}

        <div className="row cmb-20">
          <div className="col-lg-6">
            <div className="text-14-400-17 color-new-car">Paper Title:</div>
            {paperTitle && (
              <p className="text-14-300-24 color-black-olive cmb-20">
                {titleCaseString(paperTitle)}
              </p>
            )}
          </div>

          <div className="col-lg-6">
            <div className="text-14-400-17 color-new-car">Allocate Points:</div>
            {allocatePoint && (
              <span className="text-14-300-24 color-black-olive">
                {allocatePoint}
              </span>
            )}
          </div>
        </div>

        {keywords && (
          <div className="d-flex align-items-center gap-4">
            <div className="text-14-400-17 color-new-car">Keywords:</div>
            <div className="d-flex align-items-center gap-2 flex-wrap">
              {keywords?.split(",")?.map((words, index) => {
                return (
                  <span
                    key={index}
                    className="bg-f4ff color-new-car cps-8 cpe-8 cpt-2 cpb-2 br-2 text-14-400-17"
                  >
                    {words}
                  </span>
                );
              })}
            </div>
          </div>
        )}
      </Card>

      <Card className="cps-44 cpt-32 cpe-44 cpb-32">
        <h2 className="text-16-500-20 color-new-car cmb-28">Abstract :</h2>

        <div className="text-14-300-24 color-black-olive cmb-28">
          <div>
            {isExpanded ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: abstract,
                }}
              />
            ) : (
              <>
                <span
                  dangerouslySetInnerHTML={{
                    __html: abstract
                      ?.replace(/<\/?p>/g, "")
                      ?.replace(/<\/?span[^>]*>/g, "")
                      ?.split(" ")
                      ?.slice(0, maxWords)
                      ?.join(" "),
                  }}
                />

                {abstractWords?.length > maxWords && (
                  <>
                    {"... "}
                    <span
                      onClick={toggleReadMore}
                      className="text-14-500-23 color-new-car pointer"
                    >
                      Read More
                    </span>
                  </>
                )}
              </>
            )}
          </div>

          {isExpanded && (
            <span
              onClick={toggleReadMore}
              className="text-14-500-23 color-new-car pointer"
            >
              Read Less
            </span>
          )}
        </div>
        {acceptStatus === "0" && !isReject && (
          <div className="d-flex align-items-center gap-3 cmb-20">
            <div className="text-14-400-18 color-1717">
              Paper Review Request :
            </div>
            <div className="d-flex align-items-center gap-3">
              <Button
                text="Accept"
                btnStyle="primary-dark"
                className="h-35"
                onClick={() => {
                  handlePaperRequest({
                    id: paperId,
                    acceptance_status: "accept",
                  });
                }}
                btnLoading={btnLoader === "accept"}
              />
              <Button
                text="Reject"
                btnStyle="primary-outline"
                className="h-35"
                onClick={() => {
                  setIsReject(true);
                }}
              />
            </div>
          </div>
        )}

        {isReject && (
          <>
            <div className="text-14-400-18 color-1717 cmb-18">
              Select why the paper was not accepted for review * :
            </div>
            <div className="d-flex align-items-center gap-4 cmb-26">
              <RadioInput
                label="Busy"
                labelClassName="text-14-400-18 color-black-olive ms-2"
                checked={reviewStatus === "Busy"}
                onChange={() => {
                  setReviewStatus("Busy");
                }}
              />
              <RadioInput
                label="Not In my area"
                labelClassName="text-14-400-18 color-black-olive ms-2"
                checked={reviewStatus === "Not In my area"}
                onChange={() => {
                  setReviewStatus("Not In my area");
                }}
              />
              <RadioInput
                label="Conflict of interest"
                labelClassName="text-14-400-18 color-black-olive ms-2"
                checked={reviewStatus === "Conflict of interest"}
                onChange={() => {
                  setReviewStatus("Conflict of interest");
                }}
              />
            </div>
            <div className="d-flex align-items-center gap-3">
              <Button
                text="Submit"
                btnStyle="primary-dark"
                className="h-35 cps-15 cpe-15"
                onClick={() => {
                  handlePaperRequest({
                    id: paperId,
                    acceptance_status: "reject",
                    reject_reason: reviewStatus,
                  });
                }}
                btnLoading={btnLoader === "reject"}
                disabled={!reviewStatus}
              />
              <Button
                text="Back"
                btnStyle="primary-outline cps-15 cpe-15"
                className="h-35"
                onClick={() => {
                  setReviewStatus("");
                  setIsReject(false);
                }}
              />
            </div>
          </>
        )}
        {rejectReason && (
          <>
            <div className="text-14-400-18 color-1717 cmb-18">
              Rejected Reason :
            </div>
            <div className="cmb-26">{titleCaseString(rejectReason)}</div>
          </>
        )}

        {acceptStatus === "1" && (
          <>
            {allocateTime && (
              <div className="text-16-400-20 color-1717 cmb-29">
                {`You have to complete the review on or before ${moment(
                  allocateTime
                ).format("DD-MMM-YYYY")}, ${moment(allocateTime).format(
                  "HH:MM A"
                )} and Allocated ${allocatePoint} points for this paper.`}
              </div>
            )}
            <div className="text-16-400-20 color-1717 cpb-16 cmb-24 border-bottom">
              Peer Review Report :
            </div>

            <div className="row gy-3 mb-md-4 mb-lg-0">
              <div className="col-lg-7">
                <div className="text-14-500-18 color-1717 cmb-12">
                  Abstract ID :
                </div>
                {paperAbstractID && (
                  <div className="text-14-300-18 color-6866 cmb-24">
                    {paperAbstractID}
                  </div>
                )}

                <div className="text-14-500-18 color-1717 cmb-12">
                  Manuscript Title :
                </div>
                {paperTitle && (
                  <div className="text-14-300-18 color-6866 cmb-24">
                    {titleCaseString(paperTitle)}
                  </div>
                )}

                <div className="text-14-500-18 color-1717 cmb-12">
                  Recommendation :
                </div>
                {recommend ? (
                  <div className="text-14-300-18 color-6866 cmb-24">
                    {recommend}
                  </div>
                ) : (
                  <div className="cmb-24">
                    <TextArea
                      id="recommendation"
                      value={peerData?.recommendation}
                      placeholder="Enter recommendation"
                      onChange={(e) => {
                        setPeerData((prev) => {
                          return {
                            ...prev,
                            recommendation: e.target.value,
                          };
                        });
                      }}
                    />
                  </div>
                )}

                <div className="text-14-500-18 color-1717 cmb-12">
                  General Comments :
                </div>
                {gComment ? (
                  <div className="text-14-300-18 color-6866 cmb-24">
                    {gComment}
                  </div>
                ) : (
                  <div className="cmb-24">
                    <TextArea
                      id="general_comment"
                      value={peerData?.general_comment}
                      placeholder="Enter comment"
                      onChange={(e) => {
                        setPeerData((prev) => {
                          return {
                            ...prev,
                            general_comment: e.target.value,
                          };
                        });
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="col-lg-5">
                <div className="text-14-500-18 color-1717 cmb-12">
                  Followed Rates :
                </div>
                <div className="text-14-300-17 color-6866 cmb-24">
                  (1 = Poor) , (2 = Fair) , (3 = Good) , (4 = Excellent)
                </div>
                <div className="border br-5 cpt-25 cpb-16">
                  <div className="d-flex align-items-center justify-content-center text-16-500-200 color-new-car border-bottom cpb-16">
                    Evaluation Report
                  </div>
                  <div className="cpt-23 cpb-16 border-bottom">
                    {scores.map((item, index) => (
                      <>
                        <div
                          className="d-flex align-items-center cmb-16 text-14-500-18 color-1717 justify-content-center score-main-block"
                          key={index}
                        >
                          <div className="d-flex justify-content-end score-label">
                            {item.label} <span className="">:</span>
                          </div>
                          <div className="score-value-block">
                            {isHideIncrementDecrementButton && (
                              <button
                                className={`pointer add-remove-button ${
                                  item?.score === 0 ? "disabled" : ""
                                }`}
                                onClick={() => handleDecrement(index)}
                                disabled={item?.score === 0}
                              >
                                <img src={icons?.minusCircle} alt="minus" />
                              </button>
                            )}
                            <span
                              className="text-14-500-18 color-1717 input-border dis"
                              style={{
                                minWidth: "50px",
                                minHeight: "32px",
                                width: "fit-content",
                              }}
                            >
                              {item?.score}
                            </span>{" "}
                            {isHideIncrementDecrementButton && (
                              <button
                                className={`pointer add-remove-button ${
                                  item?.score === 4 ? "disabled" : ""
                                }`}
                                onClick={() => handleIncrement(index)}
                                disabled={item?.score === 4}
                              >
                                <img src={icons?.addCircleOutline} alt="add" />
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    ))}
                  </div>

                  <div
                    className="d-flex align-items-center justify-content-center text-14-500-18 color-1717 cpt-16"
                    style={{ gap: "10px" }}
                  >
                    <div
                      className="d-flex justify-content-end"
                      style={{
                        minWidth: isHideIncrementDecrementButton
                          ? "90px"
                          : "195px",
                        width: "fit-content",
                        gap: "10px",
                      }}
                    >
                      Overall
                      <span className="">:</span>
                    </div>
                    <div className="d-flex justify-content-start">
                      {`${averageScore} ${
                        averageScore > 0.0
                          ? `(${
                              averageScore <= 1
                                ? "Poor"
                                : averageScore <= 2
                                ? "Fair"
                                : averageScore <= 3
                                ? "Good"
                                : "Excellent"
                            })`
                          : ""
                      }`}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {abstractResult ? (
              <>
                <div className="text-14-400-18 color-black-olive cmb-18 ">
                  Abstract Result :
                </div>
                <div className="cmb-26">{titleCaseString(abstractResult)}</div>
              </>
            ) : (
              <>
                <div className="text-14-400-18 color-black-olive cmb-18 ">
                  Abstract Result* :
                </div>
                <div className="d-flex align-items-center gap-4 cmb-26">
                  <RadioInput
                    label="Accept as It Is"
                    labelClassName="text-14-400-18 color-black-olive ms-2"
                    checked={abstractStatus === "Accept as It Is"}
                    onChange={() => {
                      setAbstractStatus("Accept as It Is");
                    }}
                  />
                  <RadioInput
                    label="Requires Minor Revision"
                    labelClassName="text-14-400-18 color-black-olive ms-2"
                    checked={abstractStatus === "Requires Minor Revision"}
                    onChange={() => {
                      setAbstractStatus("Requires Minor Revision");
                    }}
                  />
                  <RadioInput
                    label="Requires Major Revision"
                    labelClassName="text-14-400-18 color-black-olive ms-2"
                    checked={abstractStatus === "Requires Major Revision"}
                    onChange={() => {
                      setAbstractStatus("Requires Major Revision");
                    }}
                  />
                  <RadioInput
                    label="Reject"
                    labelClassName="text-14-400-18 color-black-olive ms-2"
                    checked={abstractStatus === "Reject"}
                    onChange={() => {
                      setAbstractStatus("Reject");
                    }}
                  />
                </div>
                <div className="d-flex align-items-center gap-3">
                  <Button
                    text="Submit"
                    btnStyle="primary-dark"
                    className="h-35 cps-15 cpe-15"
                    onClick={() => {
                      handleGenerateReport(abstractStatus);
                    }}
                    disabled={
                      !abstractStatus ||
                      !isBtnVisible ||
                      !peerData?.general_comment ||
                      !peerData?.recommendation
                    }
                    btnLoading={btnLoader === "submit"}
                  />
                  <Button
                    text="Back"
                    btnStyle="primary-outline cps-15 cpe-15"
                    className="h-35"
                    onClick={() => {
                      setAbstractStatus("");
                    }}
                  />
                </div>
              </>
            )}
          </>
        )}
      </Card>
    </>
  );
};

export default MyNewData;

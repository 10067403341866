import React, { useState } from "react";
import FollowUpsDetails from "../FollowUpsDetails";
import AbstractRegistrationPass from "../AbstractRegistrationPass";

const UserRegistrationState = ({ data }) => {
  const [isFollowUps, setIsFollowUps] = useState(false);

  const { status } = data;

  return (
    <>
      <div className="d-flex justify-content-start align-items-center">
        <div className={`steps-block ${status >= 5 ? "active" : "not-active"}`}>
          5
        </div>
        <div>
          <div
            className={`text-16-500-20 ${
              status >= 5 ? "color-new-car" : "color-6866"
            }`}
          >
            Registration{" "}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        {status === 5 && (
          <div className="w-50">
            <div className="follow-ups-block border br-5 w-100 cmb-8">
              <div
                className="d-flex align-items-center justify-content-between text-14-500-17 color-new-car pointer bg-f4ff cps-24 cpt-12 cpe-24 cpb-12"
                onClick={() => {
                  setIsFollowUps(!isFollowUps);
                }}
              >
                <span>Follow Ups</span>
                <i
                  className={`${
                    isFollowUps ? "bi bi-chevron-up" : "bi bi-chevron-down"
                  }`}
                  style={{ fontSize: "15px" }}
                />
              </div>

              {isFollowUps && <FollowUpsDetails />}
            </div>

            <div className="text-14-400-17 color-6866 cmb-10 cmt-16">
              You have paid successfully !!
            </div>
            <div className="text-14-400-17 color-6866 cmb-18">
              Payment verification has been completed !!
            </div>

            <div className="cmb-16 text-16-500-20 color-7cff d-flex gap-2 align-items-center pointer">
              <span className="border-end cpe-10">View</span>
              <span className="">Download Pass</span>
            </div>
            <AbstractRegistrationPass />
          </div>
        )}
      </div>
    </>
  );
};

export default UserRegistrationState;

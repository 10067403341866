import Card from "components/Layout/Card";
import Button from "components/form/Button";
import React, { useEffect, useRef, useState } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchResearchStatistics,
  fetchUserNetwork,
  setRProfileID,
} from "store/slices";
import { getDataFromLocalStorage, titleCaseString } from "utils/helpers";
import Profile from "components/Layout/Profile";
import Loader from "components/Layout/Loader";
import RequestHandleLayout from "components/Layout/RequestHandleLayout";
import "./TopResearcherBlock.scss";

const TopResearcherBlock = ({ handleRedirect }) => {
  const useDetails = getDataFromLocalStorage();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const reduxData = useSelector((state) => state.global);
  const { networkListData } = reduxData || {};
  const { send_follow_request, following } = networkListData;

  const getTopResearch = async (object) => {
    setIsLoading(true);
    let queryParams = new URLSearchParams(object).toString();
    const response = await dispatch(fetchResearchStatistics(`?${queryParams}`));
    setData(response?.data || {});
    setIsLoading(false);
  };
  const fetchList = async () => {
    await dispatch(fetchUserNetwork(`?user_id=${useDetails?.id}`));
  };

  useEffect(() => {
    getTopResearch({
      // chart_year: moment().format("YYYY"),
      // statistics_year: moment().format("YYYY"),
      user_id: useDetails?.id,
      page: 1,
      limit: 10,
    });
    fetchList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const swiperRef = useRef(null);
  const handleNextSlide = () => {
    if (swiperRef.current !== null) {
      swiperRef.current.slideNext();
    }
  };

  const { trending_researchers = [] } = data;

  return (
    <>
      <div id="top-researcher-block-container">
        <div className="mt-5 text-24-500 color-raisin-black text-center mb-3">
          Top Researchers
        </div>
        {isLoading ? (
          <Card className="cpt-30 cpb-50">
            <Loader size="md" />
          </Card>
        ) : trending_researchers?.length === 0 ? (
          <div className="cpt-30 cpb-50 text-center">No Records Found.</div>
        ) : (
          <>
            <Swiper
              modules={[Navigation]}
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              grabCursor={true}
              slidesPerView={"auto"}
              className="mySwiper row"
              navigation={{
                nextEl: ".next-button",
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
                2400: {
                  slidesPerView: 4,
                  spaceBetween: 10,
                },
              }}
            >
              {trending_researchers?.map((elem, index) => {
                const {
                  id,
                  name,
                  profile_photo_path,
                  designation,
                  institution_name,
                  user_type,
                  university,
                  state_name,
                  country_name,
                } = elem;
                /* const isExist = requestList?.find((o) => `${o?.id}` === `${id}`)
                  ? true
                  : false; */
                const isReasearchProfile = ["2", "5"].includes(user_type);

                const isSelfUser = `${id}` === `${useDetails?.id}`;
                const isExist = send_follow_request?.find(
                  (o) => `${o?.id}` === `${id}`
                )
                  ? true
                  : false;

                const isAlreadyExist = following?.find(
                  (o) => `${o?.id}` === `${id}`
                )
                  ? true
                  : false;

                return (
                  <SwiperSlide
                    key={index}
                    className="swiper-slide"
                    style={{
                      height: "430px",
                      maxHeight: "430px",
                    }}
                  >
                    <div className="d-flex flex-column h-100">
                      <Card className="text-center pb-3 pt-3 ps-3 pe-3 d-flex flex-column flex-grow-1">
                        <Profile
                          text={name}
                          url={profile_photo_path}
                          size="s-200"
                          isS3UserURL
                        />
                        <div className="d-flex flex-column flex-grow-1">
                          <div className="d-flex flex-column flex-grow-1">
                            <div
                              className="text-16-500 color-raisin-black mt-3 mb-3 pointer hover-effect"
                              onClick={() => {
                                if (isReasearchProfile) {
                                  dispatch(setRProfileID(id));
                                }
                              }}
                            >
                              {titleCaseString(name)}
                            </div>
                            <div className="text-16-500 color-new-car mb-3">
                              {designation || institution_name || university
                                ? `${designation ? `${designation} -` : ""} ${
                                    user_type === "2"
                                      ? institution_name !== null
                                        ? institution_name
                                        : ""
                                      : university
                                      ? university
                                      : ""
                                  }`
                                : "-"}
                            </div>
                            <div className="text-14-400 color-black-olive mb-3">
                              {university && state_name && country_name
                                ? `${
                                    user_type === "2"
                                      ? university
                                        ? `${university}`
                                        : ""
                                      : ""
                                  }${state_name || country_name ? " - " : ""} ${
                                    state_name ? `${state_name}, ` : ""
                                  }${country_name || ""}`
                                : "-"}
                            </div>
                          </div>
                          <div className="d-flex justify-content-center">
                            {isSelfUser ? (
                              ""
                            ) : isAlreadyExist || isExist ? (
                              <Button
                                onClick={() => {}}
                                text={
                                  isAlreadyExist
                                    ? "Connected"
                                    : isExist
                                    ? "Request Sent"
                                    : "Try To Connect"
                                }
                                btnStyle="primary-light"
                                className="h-35 text-14-500 cps-20 cpe-20 text-nowrap"
                                disabled
                              />
                            ) : (
                              <>
                                <RequestHandleLayout
                                  receiverId={id}
                                  newSendRequest
                                  btnText="Follow"
                                />
                              </>
                            )}
                            {/* <Button
                              text={isExist ? "Cancel" : "Follow"}
                              btnStyle={
                                isExist ? "primary-light" : "primary-outline"
                              }
                              btnLoading={isFollowLoader === id}
                              className="cps-20 cpe-20 h-35 text-14-500"
                              onClick={() => {
                                handelSendRequest(id);
                              }}
                            /> */}
                          </div>
                        </div>
                      </Card>
                    </div>
                  </SwiperSlide>
                );
              })}
              <div className="next-button">
                <i
                  className="bi bi-chevron-right color-48c0 pointer"
                  onClick={handleNextSlide}
                ></i>
              </div>
            </Swiper>
            <div className="center-flex mt-5">
              <Button
                text="View All"
                btnStyle="primary-dark"
                className="cps-20 cpe-20 h-35 text-15-500"
                onClick={() => {
                  handleRedirect({ type: "Top Researchers" });
                }}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TopResearcherBlock;

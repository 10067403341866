import Button from "components/form/Button";
import TextArea from "components/form/TextArea";
import Modal from "components/Layout/Modal";
import Profile from "components/Layout/Profile";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { feedbackReply, throwError, throwSuccess } from "store/slices";
import {
  objectToFormData,
  titleCaseString,
  trimLeftSpace,
} from "utils/helpers";

const errorMsg = "Please enter reply message!";

const FeedbackReplyPopup = ({
  isFeedBackReply,
  onHide,
  categoryIcon,
  opinionIcon,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [reply, setReply] = useState("");
  const {
    profile_photo_path,
    user_name,
    message,
    category,
    opinion,
    time,
    user_id,
    id,
  } = isFeedBackReply[0] || {};

  const handleReply = async () => {
    setIsLoading(true);
    const payload = {
      message: reply,
      feedback_id: id,
      user_id: user_id,
    };
    const response = await dispatch(feedbackReply(objectToFormData(payload)));
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      setReply("");
      setIsError(false);
      onHide();
    } else {
      dispatch(throwError(response?.message));
    }
    setIsLoading(false);
  };

  return (
    <Modal onHide={onHide} titleBesideClose={"Feedback"} size={"md"}>
      <div className="cmt-30 cmb-30">
        <div className="d-flex align-items-center justify-content-between flex-wrap">
          <span className="d-flex gap-2 align-items-center">
            <span>
              <Profile
                isRounded
                size="s-30"
                isS3UserURL
                url={profile_photo_path}
              />
            </span>
            <span>{titleCaseString(user_name)}</span>
          </span>
          <span>{time}</span>
        </div>
        <div className="cps-35 cpe-35 cmt-20">
          <p className="text-wrap">{message}</p>
          <div className="text-start d-flex align-items-center flex-wrap">
            {category && (
              <span className="pt-1 pb-1 ps-2 pe-2 ms-1 bg-title-navy color-white text-12-400 text-nowrap">
                {categoryIcon[category]}
                {category}
              </span>
            )}
            {opinion && (
              <span className="ms-2 text-18-400">{opinionIcon[opinion]}</span>
            )}
          </div>
        </div>
      </div>
      <div>
        <TextArea
          label="Reply*"
          rows={3}
          labelClass="text-15-400-18 color-black-olive"
          onChange={(e) => {
            setReply(trimLeftSpace(e.target.value));
            if (e?.target?.value) {
              setIsError(false);
            } else {
              setIsError(errorMsg);
            }
          }}
          error={isError}
        />
      </div>
      <div className="d-flex justify-content-end cmt-20 cmb-20">
        <Button
          btnStyle="primary-dark"
          text="Submit"
          className="cps-12 cpe-12 h-40"
          rightIcon={<i className="bi bi-send ms-2" />}
          disabled={!reply}
          btnLoading={isLoading}
          onClick={handleReply}
        />
      </div>
    </Modal>
  );
};

export default FeedbackReplyPopup;

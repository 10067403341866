import Button from "components/form/Button";
import DatePicker from "components/form/DatePicker";
import FileUpload from "components/form/FileUpload";
import TextArea from "components/form/TextArea";
import Profile from "components/Layout/Profile";
import { Formik } from "formik";
import * as Yup from "yup";

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchAllocateTimeAndChangeResultStatus,
  throwError,
  throwSuccess,
  updateEventsSubmittedAbstractStatus,
} from "store/slices";
import {
  getFilenameFromUrl,
  getStatus,
  getStatusColor,
  objectToFormData,
  titleCaseString,
  //   titleCaseString,
} from "utils/helpers";
import { useParams } from "react-router-dom";
import ReviewerDropdown from "../ReviewerDropdown";
// import { getStatus, getStatusColor } from "utils/helpers";

const RevisionReviewReportDetails = ({
  revisionAllocatedReviewer,
  time,
  handleSuccess,
  paperData,
  list,
  setIsDeletePopup,
}) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [chooseTime, setChooseTime] = useState(null);
  const [fieldType, setFieldType] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [btnStatus, setBtnStatus] = useState("");
  const [isReviewerDetails, setIsReviewerDetails] = useState(false);
  const [scores, setScores] = useState([
    { id: "originality", label: "Originality", score: 0 },
    {
      id: "contribution_to_the_field",
      label: "Contribution to the Field",
      score: 0,
    },
    { id: "technical_quality", label: "Technical Quality", score: 0 },
    {
      id: "clarity_of_presentation",
      label: "Clarity of Presentation",
      score: 0,
    },
    { id: "depth_of_research", label: "Depth of Research", score: 0 },
  ]);

  const validationSchema = Yup.object().shape({
    comment: Yup.string().required("Comments are required"),
  });
  const initialValues = {
    comment: "",
    republish_date: "",
    document: "",
    document_file_name: "",
  };
  const {
    // event_id,
    id,
    // allocated_reviewer,
    allocated_time,
    // user_id,
    paperId,
    // paperTitle,
    // new_reviewer_revisoin,
    // old_reviewer_revisoin,
    // submittedPapersStatus,
  } = paperData || {};

  const handleSendToReviewer = async () => {
    setBtnLoader(true);
    const payload = {
      id: id,
      paper_submitted_status: "3",
      allocated_time: chooseTime?.value,
      abstract_action: "Allocate Time",
    };
    const response = await dispatch(
      fetchAllocateTimeAndChangeResultStatus(objectToFormData(payload))
    );
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      handleSuccess();
    } else {
      dispatch(throwError({ message: response?.messsage }));
    }
    setBtnLoader(false);
  };

  const handelSave = (values, btnStatus, userID) => {
    setBtnLoader(btnStatus);
    let payloadData = {
      id: params.paperId,
      paper_submitted_status: "3",
      abstract_action: "Update Reviewer Status",
      reviewer_id: userID ? userID : isReviewerDetails?.user_id,
    };

    switch (btnStatus) {
      case "1":
        payloadData = {
          ...payloadData,
          acceptance_status: "1",
        };
        break;
      case "2":
        payloadData = {
          ...payloadData,
          acceptance_status: "2",
          revision_remarks: values?.comment,
          republish_date: values?.republish_date,
        };
        break;
      case "3":
        payloadData = {
          ...payloadData,
          acceptance_status: "3",
          reject_remarks: values?.comment,
          reject_report: values?.document,
        };
        break;

      default:
        break;
    }
    handelUpdate(payloadData);
  };

  const handelUpdate = async (obj) => {
    const payload = objectToFormData(obj);
    let response = await dispatch(updateEventsSubmittedAbstractStatus(payload));
    if (response?.status === 200) {
      handleSuccess();
    } else {
      setBtnLoader("");
    }
    setBtnLoader("");
  };
  useEffect(() => {
    if (isReviewerDetails?.reviewer_result) {
      const updatedScores = scores?.map((s) => {
        if (Object.keys(isReviewerDetails?.reviewer_result)?.includes(s.id)) {
          return {
            ...s,
            score: isReviewerDetails?.reviewer_result[s?.id],
          };
        }
        return s;
      });

      setScores(updatedScores);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReviewerDetails?.reviewer_result]);

  const allocatedReviewers =
    revisionAllocatedReviewer?.allocated_reviewer || [];
  const previewsAllocatedReviewer =
    revisionAllocatedReviewer?.allocated_reviewer?.[0];
  const {
    name,
    email_id,
    member_id,
    phone_number,
    allocated_review,
    profile_image,
    area_of_interest,
    paper_status,
    user_id,
  } = previewsAllocatedReviewer || {};

  return (
    <div className="cpt-14 cpb-14 cps-24 cpe-24">
      <div className="row cmb-18">
        {allocatedReviewers?.length === 1 ? (
          <>
            <div className="col-lg-4 col-md-6 col-sm-12 gy-4 cmb-29">
              <div
                className={`cps-16 cpt-16 cpe-16 cpb-16 position-relative border rounded h-100`}
              >
                <div className="row">
                  {/* Image Section */}
                  <div className="col-lg-4 col-md-4 col-sm-4 col-4 cmb-15">
                    <div style={{ height: "114px" }}>
                      <Profile
                        url={profile_image}
                        size="s-114"
                        isS3UserURL
                        text={name}
                      />
                    </div>
                  </div>

                  {/* Info Section */}
                  <div className="col-lg-8 col-md-8 col-sm-8 col-8 cmb-15">
                    <div className="d-flex flex-column">
                      <div className="text-14-400-18 color-new-car cmb-15">
                        {name}
                      </div>
                      <div className="text-12-400-15 color-new-car cmb-15">
                        {member_id}
                      </div>
                      <div className="text-14-300-18 color-6866 cmb-15 d-flex align-items-center gap-2 text-wrap  flex-wrap">
                        <i className="bi bi-envelope" /> {email_id}
                      </div>
                      <div className="text-14-300-18 color-6866 d-flex align-items-center gap-2">
                        <i className="bi bi-telephone" /> {phone_number}
                      </div>
                    </div>
                  </div>

                  {/* Allocated Reviews */}
                  <div className="col-lg-4 col-md-6 col-sm-6 col-6 cmb-15">
                    <div className="text-12-400-15 color-6866">
                      Allocated Reviews
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-6 col-sm-6 col-6 cmb-15">
                    <div className="text-12-400-15 color-6866">
                      : {allocated_review}
                    </div>
                  </div>

                  {/* Area of Interest */}
                  <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                    <div className="text-12-400-15 color-6866">
                      Area of Interest
                    </div>
                  </div>
                  <div
                    className={`col-lg-8 col-md-6 col-sm-6 col-6 ${
                      paper_status ? "" : "cmb-13"
                    }`}
                  >
                    <div className="text-12-400-15 color-6866">
                      : {area_of_interest?.split(",")?.join(", ")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 cmb-29"></div>

            <div className="w-50 cpb-20">
              <div className="w-100 d-flex align-items-center gap-3">
                <div className="w-100 position-relative">
                  <ReviewerDropdown
                    paperData={paperData}
                    isPreviousReviewer={allocatedReviewers}
                    // id="allocated_reviewers"
                    label={"Allocate Reviewer"}
                    isRequired
                    options={list}
                    placeholder="Search Reviewer"
                    optionValue="name"
                    optionKey="id"
                    handleSuccess={handleSuccess}
                  />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="w-50 cpb-20">
              <div className="w-100 d-flex align-items-center gap-3">
                <div className="w-100 position-relative">
                  <ReviewerDropdown
                    paperData={paperData}
                    isPreviousReviewer={allocatedReviewers}
                    // id="allocated_reviewers"
                    label={"Allocate Reviewer"}
                    isRequired
                    options={list}
                    placeholder="Search Reviewer"
                    optionValue="name"
                    optionKey="id"
                    handleSuccess={handleSuccess}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12"></div>

            {allocatedReviewers?.map((elem, rI) => {
              const {
                name: new_name,
                email_id: new_email_id,
                member_id: new_member_id,
                phone_number: new_phone_number,
                allocated_review: new_allocated_review,
                profile_image: new_profile_image,
                area_of_interest: new_area_of_interest,
                paper_status: new_paper_status,
                acceptance_status: new_acceptance_status,
                reject_reason: new_reject_reason,
                user_id: new_user_id,
              } = elem || {};
              return (
                <div className="col-lg-4 col-md-6 col-sm-12 gy-4" key={rI}>
                  <div
                    className={`${allocated_time ? "pointer" : ""} ${
                      isReviewerDetails?.id === elem?.id ? "bg-f6ff" : ""
                    } cps-16 cpt-16 cpe-16 cpb-16 position-relative border rounded h-100`}
                  >
                    {!allocated_time && user_id !== new_user_id && (
                      <span
                        className="position-absolute pointer"
                        style={{ right: "20px" }}
                        onClick={() => {
                          setIsDeletePopup(elem);
                        }}
                      >
                        <i className="bi bi-x-lg" />
                      </span>
                    )}
                    <div
                      className="row"
                      onClick={() => {
                        if (allocated_time) {
                          setIsReviewerDetails(elem);
                        }
                      }}
                    >
                      {/* Image Section */}
                      <div className="col-lg-4 col-md-4 col-sm-4 col-4 cmb-15">
                        <div style={{ height: "114px" }}>
                          <Profile
                            url={new_profile_image}
                            size="s-114"
                            isS3UserURL
                            text={new_name}
                          />
                        </div>
                      </div>

                      {/* Info Section */}
                      <div className="col-lg-8 col-md-8 col-sm-8 col-8 cmb-15">
                        <div className="d-flex flex-column">
                          <div className="text-14-400-18 color-new-car cmb-15">
                            {new_name}
                          </div>
                          <div className="text-12-400-15 color-new-car cmb-15">
                            {new_member_id}
                          </div>
                          <div className="text-14-300-18 color-6866 cmb-15 d-flex align-items-center gap-2 text-wrap  flex-wrap">
                            <i className="bi bi-envelope" />
                            {new_email_id}
                          </div>
                          <div className="text-14-300-18 color-6866 d-flex align-items-center gap-2">
                            <i className="bi bi-telephone" />
                            {new_phone_number}
                          </div>
                        </div>
                      </div>

                      {/* Allocated Reviews */}
                      <div className="col-lg-4 col-md-6 col-sm-6 col-6 cmb-15">
                        <div className="text-12-400-15 color-6866">
                          Allocated Reviews
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-6 col-sm-6 col-6 cmb-15">
                        <div className="text-12-400-15 color-6866">
                          : {new_allocated_review}
                        </div>
                      </div>

                      {/* Area of Interest */}
                      <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                        <div className="text-12-400-15 color-6866">
                          Area of Interest
                        </div>
                      </div>
                      <div
                        className={`col-lg-8 col-md-6 col-sm-6 col-6 ${
                          new_paper_status ? "" : "cmb-13"
                        }`}
                      >
                        <div className="text-12-400-15 color-6866">
                          : {new_area_of_interest?.split(",")?.join(", ")}
                        </div>
                      </div>

                      {!["2"].includes(new_acceptance_status) &&
                        [0, 1].includes(new_paper_status) && (
                          <>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-6 cmt-10 cmb-13">
                              <div className="text-14-400-18 color-black-olive">
                                Paper Status
                              </div>
                            </div>
                            <div className="col-lg-8 col-md-6 col-sm-6 col-6 cmt-10 cmb-13">
                              <div className="d-flex align-items-center text-14-400-18">
                                :{" "}
                                <span
                                  className={`${
                                    "color-a35b" === 0
                                      ? "color-5110"
                                      : "color-a35b"
                                  } ms-1`}
                                >
                                  {" "}
                                  {new_paper_status === 0
                                    ? "Pending"
                                    : "Completed"}
                                </span>
                              </div>
                            </div>
                          </>
                        )}

                      {new_acceptance_status && (
                        <>
                          <div className="col-lg-4 col-md-6 col-sm-6 col-6 cmb-13">
                            <div className="text-14-400-18 color-black-olive">
                              Reviewer Status
                            </div>
                          </div>
                          <div className="col-lg-8 col-md-6 col-sm-6 col-6 cmb-13">
                            <div className="d-flex align-items-center text-14-400-18">
                              :{" "}
                              <span
                                className={`${getStatusColor(
                                  new_acceptance_status
                                )} ms-1`}
                              >
                                {" "}
                                {getStatus(new_acceptance_status)}
                              </span>
                            </div>
                          </div>
                        </>
                      )}

                      {new_reject_reason && (
                        <>
                          <div className="col-lg-4 col-md-6 col-sm-6 col-6 cmb-13">
                            <div className="text-14-400-18 color-black-olive">
                              Reason
                            </div>
                          </div>
                          <div className="col-lg-8 col-md-6 col-sm-6 col-6 cmb-13">
                            <div className="d-flex align-items-center text-14-400-18">
                              :{" "}
                              <span className="color-6866 ms-1">
                                {" "}
                                {new_reject_reason}
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

            {!allocated_time && (
              <>
                <div className="d-flex w-100 flex-column cmb-25 cmt-15">
                  <div className="text-14-500-17 color-6866 cmb-12">
                    Allocate Time :
                  </div>
                  <div className="d-flex gap-3">
                    {time?.map((elm) => {
                      return (
                        <span
                          className={`${
                            chooseTime?.id === elm?.id ? "active" : ""
                          } allocate-time-chunks pointer`}
                          key={elm?.id}
                          onClick={() => {
                            setChooseTime(elm);
                          }}
                        >
                          {elm?.value?.includes("hours")
                            ? elm?.value?.replace("hours", "Hrs")
                            : elm?.value}
                        </span>
                      );
                    })}
                  </div>
                </div>
                <div className="d-flex">
                  <Button
                    text="Send to Reviewer"
                    className="h-35 cps-20 cpe-20"
                    btnStyle="primary-dark"
                    onClick={handleSendToReviewer}
                    btnLoading={btnLoader}
                    disabled={!chooseTime?.id}
                  />
                </div>
              </>
            )}

            {isReviewerDetails && (
              <>
                <div className="d-flex align-align-items-center cmb-38 gap-4 cmt-15">
                  <div className="d-flex flex-column">
                    <span className="text-14-500-17 color-6866 cmb-12">
                      Allocated Time :
                    </span>
                    <span className="allocate-time-chunks w-fit-content">
                      {allocated_time?.includes("hours") &&
                        allocated_time?.replace("hours", "Hrs")}
                    </span>
                  </div>
                  {[0, 1].includes(isReviewerDetails?.paper_status) && (
                    <div className="d-flex flex-column">
                      <span className="text-14-500-17 color-6866 cmb-12">
                        Paper Status :
                      </span>
                      <span className="paper-status-chunks w-fit-content">
                        {isReviewerDetails?.paper_status === 0
                          ? "Pending"
                          : "Completed"}
                      </span>
                    </div>
                  )}
                  {isReviewerDetails?.reject_reason && (
                    <div className="flex-column d-flex">
                      <span className="text-14-500-17 color-6866 cmb-12">
                        Reason for paper rejection :
                      </span>
                      <span className="cmt-8">
                        {isReviewerDetails?.reject_reason}
                      </span>
                    </div>
                  )}
                </div>

                {[1]?.includes(isReviewerDetails?.paper_status) && (
                  <>
                    <div className="text-16-400-20 color-new-car border-bottom cpb-16 cmb-24">
                      Peer Review Report :
                    </div>
                    <div className="row gy-3 mb-md-4 mb-lg-0">
                      <div className="col-lg-7">
                        <div className="text-14-500-17 color-6866 cmb-12">
                          Abstract ID :
                        </div>
                        {paperId && (
                          <div className="text-14-300-18 color-6866 cmb-24">
                            {paperId}
                          </div>
                        )}

                        <div className="text-14-500-17 color-6866 cmb-12">
                          Manuscript Title :
                        </div>
                        {isReviewerDetails?.paper_title && (
                          <div className="text-14-300-18 color-6866 cmb-24">
                            {titleCaseString(isReviewerDetails?.paper_title)}
                          </div>
                        )}

                        <div className="text-14-500-17 color-6866 cmb-12">
                          Recommendation :
                        </div>
                        {isReviewerDetails?.recommendation && (
                          <div className="text-14-300-18 color-6866 cmb-24">
                            {titleCaseString(isReviewerDetails?.recommendation)}
                          </div>
                        )}

                        <div className="text-14-500-17 color-6866 cmb-12">
                          General Comments :
                        </div>
                        {isReviewerDetails?.general_comment && (
                          <div className="text-14-300-18 color-6866">
                            {titleCaseString(
                              isReviewerDetails?.general_comment
                            )}
                          </div>
                        )}
                      </div>
                      <div className="col-lg-5">
                        <div className="text-14-500-17 color-6866 cmb-12">
                          Followed Rates :
                        </div>
                        <div className="text-14-300-17 color-6866 cmb-24">
                          (1 = Poor) , (2 = Fair) , (3 = Good) , (4 = Excellent)
                        </div>
                        <div className="border br-5 cpt-25 cpb-16">
                          <div className="d-flex align-items-center justify-content-center text-16-500-200 color-new-car border-bottom cpb-16">
                            Evaluation Report
                          </div>
                          <div className="cpt-23 cpb-16 border-bottom">
                            {scores?.map((item, index) => (
                              <div
                                className="d-flex align-items-center cmb-16 text-14-500-17 color-6866 row"
                                key={index}
                              >
                                <div className="d-flex justify-content-end col-lg-8 col-md-8 col-sm-6">
                                  {item?.label}{" "}
                                  <span className="ms-lg-4 ms-md-4 ms-sm-3">
                                    :
                                  </span>
                                </div>
                                <div className="d-flex justify-content-start col-lg-4 col-md-4 col-sm-6">
                                  {item?.score}
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className="d-flex align-items-center text-14-500-17 color-6866 cpt-16 row">
                            <div className="d-flex justify-content-end col-lg-8 col-md-8 col-sm-6">
                              Overall
                              <span className="ms-lg-4 ms-md-4 ms-sm-3">:</span>
                            </div>
                            <div className="d-flex justify-content-start col-lg-4 col-md-4 col-sm-6">
                              {`${
                                isReviewerDetails?.reviewer_result?.overall
                              } ${
                                isReviewerDetails?.reviewer_result?.overall >
                                0.0
                                  ? `(${
                                      isReviewerDetails?.reviewer_result
                                        ?.overall <= 1
                                        ? "Poor"
                                        : isReviewerDetails?.reviewer_result
                                            ?.overall <= 2
                                        ? "Fair"
                                        : isReviewerDetails?.reviewer_result
                                            ?.overall <= 3
                                        ? "Good"
                                        : "Excellent"
                                    })`
                                  : ""
                              }`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="text-16-500-20 color-6866 cmb-28 d-flex">
                      Abstract Result :
                      <div className="ms-4">
                        {isReviewerDetails?.reviewer_result?.abstract_result}
                      </div>
                    </div>
                    {!fieldType && (
                      <div className="d-flex gap-3 cmb-22">
                        <Button
                          text="Proceed to Next"
                          className="h-35 cps-20 cpe-20"
                          btnStyle="primary-dark"
                          onClick={() => {
                            setBtnStatus("1");
                            handelSave(false, "1");
                          }}
                          btnLoading={btnLoader === btnStatus}
                        />
                        <Button
                          text="Reject"
                          className="h-35 cps-20 cpe-20"
                          btnStyle="primary-dark"
                          onClick={() => {
                            setFieldType("Reject");
                          }}
                        />
                        <Button
                          text="Revision Needed"
                          className="h-35 cps-20 cpe-20"
                          btnStyle="primary-outline"
                          onClick={() => {
                            setFieldType("Revision");
                          }}
                        />
                      </div>
                    )}

                    {fieldType && (
                      <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(
                          values,
                          { setSubmitting, setFieldError }
                        ) => {
                          if (btnStatus === "2") {
                            if (!values?.republish_date) {
                              setFieldError(
                                "republish_date",
                                "Republish date is required for revision"
                              );
                              setSubmitting(false);
                              return;
                            } else {
                              handelSave(values, "2");
                            }
                          } else if (btnStatus === "3") {
                            if (!values?.document) {
                              setFieldError("document", "Report is required");
                              setSubmitting(false);
                              return;
                            } else {
                              handelSave(values, "3");
                            }
                          } else {
                            //nothing
                          }

                          setSubmitting(false);
                        }}
                      >
                        {(props) => {
                          const {
                            values,
                            errors,
                            handleChange,
                            setFieldValue,
                            handleSubmit,
                          } = props;

                          return (
                            <form>
                              <div className="cmb-16">
                                <TextArea
                                  label="Revision Remarks :"
                                  labelClass="text-15-500 mb-1"
                                  placeholder="Give your comments..."
                                  id="comment"
                                  onChange={handleChange}
                                  value={values?.comment}
                                  error={errors?.comment}
                                  rows={3}
                                />
                              </div>
                              {fieldType === "Revision" ? (
                                <div className="cmb-16">
                                  <DatePicker
                                    id="republish_date"
                                    label="Republish Date *"
                                    labelClass="text-15-500 mb-1"
                                    placeholder="DD-MM-YYYY"
                                    minDate={moment()}
                                    onChange={(e) => {
                                      setFieldValue(
                                        "republish_date",
                                        e.target.value
                                      );
                                    }}
                                    value={values?.republish_date}
                                    error={errors?.republish_date}
                                  />
                                </div>
                              ) : (
                                <div className="cmb-16">
                                  <FileUpload
                                    label="Report *"
                                    labelClass="text-15-500 mb-1"
                                    fileText={getFilenameFromUrl(
                                      values?.plagiarism_document_file_name
                                    )}
                                    id="document"
                                    onChange={(e) => {
                                      setFieldValue("document", e.target.value);
                                      setFieldValue(
                                        "document_file_name",
                                        e.target.fileName
                                      );
                                    }}
                                    value={values?.document}
                                    error={errors?.document}
                                  />
                                </div>
                              )}
                              <div className="d-flex align-content-center gap-3">
                                <Button
                                  text={
                                    fieldType === "Revision"
                                      ? "Sent Revision"
                                      : "Reject"
                                  }
                                  className="h-35 cps-20 cpe-20"
                                  btnStyle="primary-dark"
                                  onClick={() => {
                                    setBtnStatus(
                                      fieldType === "Revision" ? "2" : "3"
                                    );

                                    handleSubmit();
                                  }}
                                  btnLoading={btnLoader === btnStatus}
                                />
                                <Button
                                  text="Back"
                                  className="h-35 cps-20 cpe-20"
                                  btnStyle="primary-outline"
                                  onClick={() => {
                                    setFieldType(false);
                                  }}
                                />
                              </div>
                            </form>
                          );
                        }}
                      </Formik>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RevisionReviewReportDetails;

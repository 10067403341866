import React, { useState } from "react";

const ProfileChangesDetails = ({ title, items, percentage = "0" }) => {
  const [isMore, setIsMore] = useState(false);
  const remainingItemsCount = items?.length - 5;

  return (
    <div className="col-lg-4">
      <div className="border rounded p-3 h-100">
        <div className="text-15-600 color-new-car cmb-20">{title}</div>
        <div className="cmb-20">
          {`${title === "Pending:" ? "Pending" : "Complete"} Profile`} :{" "}
          {`${percentage}`}
        </div>
        {items?.slice(0, isMore ? items?.length : 5)?.map((elem, index) => {
          return (
            <li
              key={index}
              style={{
                wordBreak: "break-all",
                overflowWrap: "break-word",
                hyphens: "auto",
              }}
            >
              {elem}
            </li>
          );
        })}

        {items?.length > 5 && (
          <div
            className="d-flex align-items-center gap-2 color-new-car pointer cmt-10"
            onClick={() => {
              setIsMore(!isMore);
            }}
          >
            {isMore
              ? `${remainingItemsCount} less`
              : `${remainingItemsCount} more`}{" "}
            <i
              className={`bi ${
                isMore ? "bi-chevron-up" : "bi-chevron-down"
              } text-15-500`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileChangesDetails;

import Button from "components/form/Button";
import Card from "components/Layout/Card";
import Loader from "components/Layout/Loader";
import Profile from "components/Layout/Profile";
import ShareButton from "components/Layout/ShareButton";
import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  fetchFollowerOrFollowing,
  fetchRequests,
  getMentorDetails,
  sendRequests,
  throwError,
  throwSuccess,
} from "store/slices";
import { icons } from "utils/constants";
import {
  getDataFromLocalStorage,
  getUserType,
  INRtoUSD,
  objectToFormData,
  titleCaseString,
} from "utils/helpers";
import EducationDetails from "../ProfileManagement/CampaignManagement/EducationDetails";
import { useNavigate, useParams } from "react-router-dom";

const SingleMentorDetails = ({
  allMentorsList,
  iD,
  profileData,
  setIsLoadAPI,
  isLoadAPI,
}) => {
  const dispatch = useDispatch();
  const params = useParams();
  const userType = params?.memberType;
  const navigate = useNavigate();
  const memberType = getUserType();
  const [list, setList] = useState([]);
  const [isSelfUser, setIsSelfUser] = useState(false);
  const [isExist, setIsExist] = useState(false);
  const [isAlreadyExist, setIsAlreadyExist] = useState(false);
  const [isConnectLoader, setIsConnectLoader] = useState(false);
  const [requestList, setRequestList] = useState([]);
  const [aboutText, setAboutText] = useState(50);
  const [reviewShowMore, setReviewShowMore] = useState(3);
  const [sessionViewCount, setSessionViewCount] = useState(4);
  const [isProfessionalDetails, setIsProfessionalDetails] = useState(false);
  const [mentorDetailsData, setMentorDetailsData] = useState({
    data: {},
    loading: true,
  });

  const fetchMentorDetails = async (mId) => {
    const response = await dispatch(
      getMentorDetails(objectToFormData({ mentor_id: mId }))
    );
    setMentorDetailsData((prev) => {
      return {
        ...prev,
        data: response?.data || {},
        loading: false,
      };
    });

    setTimeout(() => {
      setIsLoadAPI(false);
    }, 1000);
  };

  const handleConnect = async () => {
    setIsConnectLoader(true);
    let id = mentorDetailsData?.data?.id;
    const response = await dispatch(
      sendRequests(objectToFormData({ receiver_id: id }))
    );
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      let oldList = cloneDeep(requestList);
      if (oldList?.find((o) => `${o.id}` === `${id}`)) {
        oldList = oldList?.filter((o) => `${o.id}` !== `${id}`);
      } else {
        oldList = [...oldList, response?.data];
      }
      setRequestList(oldList);
      setIsConnectLoader(false);
    } else {
      dispatch(throwError(response?.message));
    }
  };

  const getRequest = async () => {
    const response = await dispatch(fetchRequests({ type: "following" }));
    setRequestList(response?.data?.result);
  };

  const getFollowing = async () => {
    const response = await dispatch(
      fetchFollowerOrFollowing(objectToFormData({ type: "following" }))
    );
    setList(response?.data?.result || []);
  };

  useEffect(() => {
    if (iD) {
      setIsLoadAPI(true);
      fetchMentorDetails(iD);
    } else {
      setIsLoadAPI(false);
      setMentorDetailsData((prev) => {
        return {
          ...prev,
          loading: false,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iD]);

  useEffect(() => {
    getFollowing();
    setIsSelfUser(mentorDetailsData?.data?.id === profileData?.id);
    const selfId = mentorDetailsData?.data?.id;
    setIsExist(
      requestList?.find((o) => `${o.id}` === `${selfId}`) ? true : false
    );

    setIsAlreadyExist(
      list?.find((o) => `${o.id}` === `${selfId}`) ? true : false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorDetailsData, requestList]);

  useEffect(() => {
    getRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentorDetailsData]);

  const shareUrl = `${window.location.origin}/member/global-research-profile/${mentorDetailsData?.data?.id}`;

  const transformEducationDetails = (data) => {
    const levels = ["ug", "pg", "phd"];

    return levels
      .map((level) => ({
        courseType: level,
        courseName: data?.[`${level}_course`] || "",
        department: data?.[`${level}_department`] || "",
        university:
          data?.[`${level}_university`] ||
          data?.[`other_${level}_university`] ||
          "",
        institution: data?.[`${level}_institution`] || "",
        yearOfCompletion: data?.[`${level}_year_of_completion`] || "",
      }))
      ?.filter((item) => item?.courseName);
  };

  const mediaData = [
    {
      id: 1,
      title: "Presentations",
      logo: icons.mediaVideo,
      count: mentorDetailsData?.data?.presentation || 0,
    },
    {
      id: 2,
      title: "All Posts",
      logo: icons.mediaGallery,
      count: mentorDetailsData?.data?.post || 0,
    },
    {
      id: 3,
      title: "Likes",
      logo: icons.pinkThumb,
      count: mentorDetailsData?.data?.like || 0,
    },
    {
      id: 4,
      title: "Views",
      logo: icons.blueEye,
      count: mentorDetailsData?.data?.views || 0,
    },
  ];

  const text =
    mentorDetailsData?.data?.about_introduction &&
    mentorDetailsData?.data?.about_introduction;

  const aboutMentorText = text?.slice(0, aboutText);
  if (mentorDetailsData?.data) {
  }

  const authUserDetails = getDataFromLocalStorage();
  const { personal_details, exchange_rate } = authUserDetails;
  const isNational = personal_details?.country_name === "India";

  return (
    <div className="col-md-8 single-mentor-details-container">
      {!allMentorsList?.loading &&
      allMentorsList?.data?.length === 0 &&
      !isLoadAPI ? (
        <Card className="cpt-30 cpb-50 text-center">No Records Found.</Card>
      ) : mentorDetailsData?.loading || isLoadAPI ? (
        <Card className="cpt-30 cpb-50">
          <Loader size="md" />
        </Card>
      ) : (
        <div className="single-mentor-details-container">
          <Card className="cps-10 cpt-20 cpe-10 cpb-20 cmb-8">
            <div className="d-flex justify-content-between flex-wrap cmb-10">
              <div className="d-flex align-items-center gap-2 cmb-20">
                <Profile
                  isS3UserURL
                  url={mentorDetailsData?.data?.profile}
                  text={mentorDetailsData?.data?.name}
                  isRounded
                  size="s-100"
                />
                <div>
                  <div className="text-18-500 color-3146">
                    {titleCaseString(mentorDetailsData?.data?.name)}
                  </div>
                  <div className="text-15-400-25 color-3146">
                    {mentorDetailsData?.data?.designation}
                  </div>
                  <div className="text-15-400-25 color-3146 cmb-10">
                    {mentorDetailsData?.data?.institution}
                    {mentorDetailsData?.data?.institution && ","}{" "}
                    {mentorDetailsData?.data?.country}
                  </div>
                </div>
              </div>
              <div>
                <ShareButton
                  className="color-new-car text-16-400 pointer d-flex align-items-center share"
                  url={mentorDetailsData?.data?.name && shareUrl}
                />
              </div>
            </div>

            <div className="d-flex gap-3 align-items-center">
              {isSelfUser ? (
                ""
              ) : isAlreadyExist || isExist ? (
                <Button
                  onClick={() => {}}
                  text={
                    isAlreadyExist
                      ? "Connected"
                      : isExist
                      ? "Request Sent"
                      : "Try To Connect"
                  }
                  btnStyle="primary-light"
                  className="gap-2"
                  btnLoading={isConnectLoader}
                  disabled
                />
              ) : (
                <Button
                  text="Connect"
                  btnStyle="primary-dark"
                  className="gap-2"
                  icon={<img src={icons.connectUser} alt="user" />}
                  onClick={() => handleConnect()}
                  btnLoading={isConnectLoader}
                />
              )}
              <div className="d-flex gap-2">
                <div>
                  <img src={icons.blackFollowers} alt="followers" />
                </div>
                <div className="text-15-500-26 color-3146">
                  {mentorDetailsData?.data?.followers}
                </div>
                <div className="text-15-400-26 color-3146">Followers</div>
              </div>
            </div>
          </Card>
          <div className="d-flex flex-wrap gap-2">
            {mediaData?.map((elem, index) => {
              const { title, logo, count } = elem;
              return (
                <Card
                  className="cps-10 cpt-10 cpe-10 cpb-5 cmb-8 flex-grow-1"
                  key={index}
                >
                  <div className="d-flex aligns-items-center gap-3">
                    <div>
                      <img src={logo} alt="media" />
                    </div>
                    <div>
                      <div className="text-20-700-23 color-4453">{count}</div>
                      <div className="text-14-400-26 color-3146">{title}</div>
                    </div>
                  </div>
                </Card>
              );
            })}
          </div>
          {aboutMentorText && (
            <Card className="cps-10 cpt-10 cpe-10 cpb-5 cmb-8">
              <div>
                <div className="text-15-500-26 color-4453 cmb-5">
                  About Mentor
                </div>
                <div className="text-15-400-25 color-5068 text-justify cmb-10">
                  {titleCaseString(aboutMentorText)}
                </div>
                {text?.length > 50 && (
                  <div className="d-flex">
                    <div
                      className="d-flex align-items-center gap-2 color-new-car pointer"
                      onClick={() => {
                        if (aboutText === 50) {
                          setAboutText(text?.length);
                        } else {
                          setAboutText(50);
                        }
                      }}
                    >
                      <span className="text-13-400">
                        {aboutText === 50 ? "View more" : "View less"}
                      </span>
                      <span>
                        <img
                          src={
                            aboutText === 50 ? icons.downArrow : icons.upArrow
                          }
                          alt="down-arrow"
                        />
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          )}
          {mentorDetailsData?.data?.skills?.length > 0 && (
            <Card className="cps-10 cpt-10 cpe-10 cpb-5 cmb-8">
              <div>
                <div className="text-15-500-26 color-4453 cmb-10">
                  Skills & Expertise
                </div>
                <div className="d-flex gap-3 flex-wrap align-items-center cmb-10">
                  {mentorDetailsData?.data?.skills &&
                    mentorDetailsData?.data?.skills
                      ?.slice(0, reviewShowMore)
                      ?.map((data, i) => {
                        return (
                          <div
                            className="text-14-400 key-points color-dark-blue bg-f6fc text-nowrap cps-5 cpt-6 cpe-5 cpb-6 rounded"
                            key={i}
                          >
                            {data}
                          </div>
                        );
                      })}
                </div>
                {mentorDetailsData?.data?.skills?.length > 3 && (
                  <div className="d-flex">
                    <div
                      className="text-12-400 color-text-blue d-flex gap-2 pointer"
                      onClick={() => {
                        if (mentorDetailsData?.data?.skills?.length > 0) {
                          if (reviewShowMore === 3) {
                            setReviewShowMore(
                              mentorDetailsData?.data?.skills?.length
                            );
                          } else {
                            setReviewShowMore(3);
                          }
                        }
                      }}
                    >
                      {reviewShowMore === 3 ? "View more" : "View less"}
                      <div className="text-12-400 color-text-blue">
                        <img
                          src={
                            reviewShowMore === 3
                              ? icons.downArrow
                              : icons.upArrow
                          }
                          alt="arrow"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          )}
          {mentorDetailsData?.data?.session?.length > 0 && (
            <Card className="cps-10 cpt-10 cpe-10 cpb-5 cmb-8">
              <div>
                <div className="text-15-500-26 color-4453 cmb-5">Sessions:</div>
                <div className="row g-3">
                  {mentorDetailsData?.data?.session
                    ?.slice(0, sessionViewCount)
                    ?.map((elem, index) => {
                      const {
                        id,
                        session_name,
                        /* rating_reveiw, */
                        rating_star,
                        amount,
                        meeting_duration,
                        meeting_link,
                      } = elem;

                      const price = INRtoUSD(amount, exchange_rate);
                      const newPrice = parseFloat(price?.toFixed(2));

                      return (
                        <div className="col-lg-6" key={index}>
                          <div className="d-flex flex-column cpt-15 cpb-15 cps-15 cpe-15 bg-f6ff h-100 br-10">
                            <span className="cmb-15">
                              {titleCaseString(session_name)}
                            </span>

                            <div className="d-flex align-items-center justify-content-between flex-wrap cmb-15 gap-1">
                              {meeting_link && (
                                <div
                                  className={`d-flex gap-1 align-items-center`}
                                >
                                  <img src={icons.videoMeet} alt="meet" />
                                  <div className="text-14-400 color-dark-blue cpt-2">
                                    1:1 Video Meet
                                  </div>
                                </div>
                              )}
                              {meeting_duration && (
                                <div className="d-flex gap-1 align-items-center">
                                  <img src={icons.clockTime} alt="clock" />
                                  <div className="text-14-400 color-dark-blue">
                                    {meeting_duration}
                                  </div>
                                </div>
                              )}

                              {rating_star && (
                                <div className="d-flex gap-1 align-items-center text-nowrap">
                                  <img src={icons.star} alt="star" />

                                  <span className="text-14-500 color-dark-blue">
                                    {rating_star}
                                  </span>
                                </div>
                              )}
                            </div>

                            <div className="d-flex flex-column flex-grow-1 justify-content-end">
                              <div
                                className={`${
                                  !["0", "6"].includes(userType)
                                    ? "cmb-15 align-items-center"
                                    : ""
                                } d-flex gap-2`}
                              >
                                <span className="text-16-500 color-black">
                                  {isNational ? `₹ ${amount}` : `$ ${newPrice}`}
                                </span>
                                {/* <span className="text-14-400 color-light-gray text-decoration-line-through">
                                {`₹ ${439}`}
                              </span>
                              <span className="text-14-400 color-bc60">
                                10% off
                              </span> */}
                              </div>

                              {!["0", "6"].includes(userType) && (
                                <div className="d-flex">
                                  <Button
                                    text="Book Now"
                                    btnStyle="primary-dark"
                                    className="h-35 text-14-500 cps-14 cpe-15"
                                    onClick={() => {
                                      navigate(
                                        `/${memberType}/mentorship/mentee/book-session/${id}`
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {mentorDetailsData?.data?.session?.length > 4 && (
                  <div className="d-flex justify-content-center cmt-20">
                    <Button
                      text={
                        sessionViewCount === 4 ? "More Session" : "Less Session"
                      }
                      btnStyle="primary-outline"
                      className="h-35 text-14-500 cps-14 cpe-15"
                      onClick={() => {
                        if (sessionViewCount === 4) {
                          setSessionViewCount(
                            mentorDetailsData?.data?.session?.length
                          );
                        } else {
                          setSessionViewCount(4);
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            </Card>
          )}

          <Card className="cmb-8">
            <div className="border rounded">
              <div
                className="d-flex align-items-center justify-content-between text-15-600 color-new-car border-bottom p-3 pointer"
                onClick={() => {
                  setIsProfessionalDetails(!isProfessionalDetails);
                }}
              >
                <div className="text-15-600 color-new-car">
                  Educational & Professional Details
                </div>
                <i
                  className={`${
                    isProfessionalDetails
                      ? "bi bi-chevron-up"
                      : "bi bi-chevron-down"
                  } text-16-600`}
                />
              </div>
              {isProfessionalDetails && (
                <>
                  {mentorDetailsData?.data?.educationDetails?.ug_course && (
                    <div className="p-3">
                      <div className="text-15-600 color-new-car cmb-20">
                        Education Details:
                      </div>
                      <EducationDetails
                        educationDetails={transformEducationDetails(
                          mentorDetailsData?.data?.educationDetails
                        )}
                      />
                    </div>
                  )}
                  {mentorDetailsData?.data?.disciplines?.length > 0 && (
                    <div className="p-3">
                      <div className="text-15-600 color-new-car cmb-5">
                        Disciplines:
                      </div>
                      <div className="d-flex gap-3 flex-wrap align-items-center cmb-10">
                        {mentorDetailsData?.data?.disciplines?.map(
                          (data, i) => {
                            return (
                              <div
                                className="text-14-400 key-points color-dark-blue bg-f6fc text-nowrap cps-5 cpt-6 cpe-5 cpb-6 rounded"
                                key={i}
                              >
                                {data}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                  {mentorDetailsData?.data?.languages?.length > 0 && (
                    <div className="p-3">
                      <div className="text-15-600 color-new-car cmb-5">
                        Languages:
                      </div>
                      <div className="d-flex gap-3 flex-wrap align-items-center cmb-10">
                        {mentorDetailsData?.data?.languages?.map((data, i) => {
                          return (
                            <div
                              className="text-14-400 key-points color-dark-blue bg-f6fc text-nowrap cps-5 cpt-6 cpe-5 cpb-6 rounded"
                              key={i}
                            >
                              {data}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default SingleMentorDetails;

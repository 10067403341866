import React, { useEffect, useState } from "react";

import Card from "components/Layout/Card";
import Button from "components/form/Button";
import { useNavigate, useParams } from "react-router-dom";
import Dropdown from "components/form/Dropdown";
import { getDataFromLocalStorage, getYearList } from "utils/helpers";
import moment from "moment";

import ResourceCountInCard from "pages/Resource/Dashboard/ResourceCountInCard";
import MembersAnalyticsChart from "pages/Admin/Dashboard/MembersAnalyticsChart";
import CountryAnalyticsChart from "pages/Admin/Dashboard/CountryAnalyticsChart";
import AllReports from "pages/Admin/Reports/AllReports";

const ResourceDashboardOverView = ({ isReport }) => {
  const params = useParams();
  const { resource_role } = getDataFromLocalStorage();
  const navigate = useNavigate();
  const [tabType, setTabType] = useState("0");
  const [commonYear, setCommonYear] = useState({
    memberCountYear: moment().format("YYYY"),
    memberYear: moment().format("YYYY"),
    countryWiseYear: moment().format("YYYY"),
  });

  const activeClass = "p-3 bg-new-car color-white text-16-500";
  const inActiveClass = "p-3 color-dark-blue text-16-500 pointer";

  useEffect(() => {
    if (window.location.pathname.includes("reports/overall-report")) {
      setCommonYear({
        memberCountYear: "",
        memberYear: "",
        countryWiseYear: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname.includes("reports/overall-report")]);
  return (
    <>
      <div className="text-24-500 color-raisin-black mb-3 text-center">
        {resource_role === "2"
          ? "Conference Co-ordinator Resource"
          : "Membership Resource"}
      </div>
      {!isReport && (
        <div className="d-flex justify-content-end mb-3 gap-3">
          <div className="d-flex">
            <Button
              text="Overall Report"
              icon={<i className="bi bi-eye" />}
              className="gap-2 cps-15 cpe-15 h-45"
              btnStyle="primary-dark"
              onClick={() => {
                navigate(`/resource/reports/overall-report`);
              }}
            />
          </div>

          <div>
            <Dropdown
              options={getYearList(10).map((o) => {
                return { ...o, name: o.id };
              })}
              optionValue="name"
              onChange={(e) => {
                setCommonYear({
                  ...commonYear,
                  memberCountYear: e.target.value,
                  memberYear: e.target.value,
                  countryWiseYear: e.target.value,
                });
              }}
              value={commonYear?.memberCountYear}
              placeholder="Year"
            />
          </div>
        </div>
      )}
      <div className="row" id="admin-dashboard-container">
        <ResourceCountInCard commonYear={commonYear} isReport={isReport} />

        <div>
          <Card className="mt-3">
            <div className="d-flex align-items-center flex-wrap gap-2">
              <div
                className={tabType === "0" ? activeClass : inActiveClass}
                onClick={() => {
                  setTabType("0");
                }}
              >
                IFERP Member’s Analytics
              </div>
              <div
                className={tabType === "1" ? activeClass : inActiveClass}
                onClick={() => {
                  setTabType("1");
                }}
              >
                Country Wise Analytics
              </div>
            </div>
          </Card>
        </div>

        {tabType === "0" && (
          <MembersAnalyticsChart commonYear={commonYear} isReport={isReport} />
        )}

        {tabType === "1" && (
          <CountryAnalyticsChart commonYear={commonYear} isReport={isReport} />
        )}
      </div>
      {params?.type === "overall-report" && tabType === "1" && <AllReports />}
    </>
  );
};

export default ResourceDashboardOverView;

import Button from "components/form/Button";
import React from "react";

const FollowUpsDetails = () => {
  return (
    <div className="cps-24 cpt-12 cpe-24 cpb-12 follow-up-details-block">
      {/* Early Bird */}
      <div className="d-flex justify-content-start align-items-center">
        <div className="follow-step-block">
          <span className="inner-block"></span>
        </div>
        <div>
          <div className="text-16-500-20 color-new-car">Early Bird</div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <div className="follow-up-sparator-block">
          <span className="hr-line" />
        </div>
        <div className="cmb-19">
          <div className="date-block cmt-14 cmb-14 color-black-olive text-14-300 ">
            01-July-2024
          </div>
          <span className="text-12-400-15 color-1717 br-5 cps-9 cpe-9 cpt-4 cpb-4 bg-f4fc">
            Offer Expired
          </span>
        </div>
      </div>

      {/* Publication Details Few slots left */}
      <div className="d-flex justify-content-start align-items-center">
        <div className="follow-step-block">
          <span className="inner-block"></span>
        </div>
        <div>
          <div className="text-16-500-20 color-new-car">
            Publication Details Few slots left
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <div className="follow-up-sparator-block">
          <span className="hr-line" />
        </div>
        <div className="cmb-19">
          <div className="date-block cmt-14 cmb-14 color-black-olive text-14-300 ">
            02-July-2024
          </div>
          <span className="text-12-400-15 color-1717 br-5 cps-9 cpe-9 cpt-4 cpb-4 bg-f4fc cmb-16">
            Offer Expired
          </span>
        </div>
      </div>

      {/* Keynote speaker */}
      <div className="d-flex justify-content-start align-items-center">
        <div className="follow-step-block">
          <span className="inner-block"></span>
        </div>
        <div>
          <div className="text-16-500-20 color-new-car">Keynote speaker</div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <div className="follow-up-sparator-block">
          <span className="hr-line" />
        </div>
        <div className="cmb-19">
          <div className="date-block cmt-14 cmb-14 color-black-olive text-14-300 ">
            02-July-2024
          </div>
          <span className="text-12-400-15 color-1717 br-5 cps-9 cpe-9 cpt-4 cpb-4 bg-f4fc cmb-16">
            Offer Expired
          </span>
        </div>
      </div>

      {/* Session speaker notification */}
      <div className="d-flex justify-content-start align-items-center">
        <div className="follow-step-block">
          <span className="inner-block"></span>
        </div>
        <div>
          <div className="text-16-500-20 color-new-car">
            Session speaker notification
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-start">
        <div className="d-flex cms-40 cmt-14">
          <Button
            text="Pay Now"
            btnStyle="primary-dark"
            className="cps-15 cpe-15 h-30"
            onClick={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default FollowUpsDetails;

import React, { useState } from "react";
import Modal from "../Modal";
import TextArea from "components/form/TextArea";
import { objectToFormData, trimLeftSpace } from "utils/helpers";
import Button from "components/form/Button";
import { useDispatch } from "react-redux";
import {
  handleDeleteProfileRequest,
  throwError,
  throwSuccess,
} from "store/slices";

const ProfileDeleteRequestPopup = ({ onHide, id, handelSuccess }) => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    const payload = {
      reason_of_delete: reason,
      userid: id,
    };
    const response = await dispatch(
      handleDeleteProfileRequest(objectToFormData(payload))
    );
    if (response?.status === 200) {
      dispatch(throwSuccess(response?.message));
      handelSuccess();
      setReason("");
    } else {
      dispatch(throwError(response?.message));
    }
    setIsLoading(false);
  };

  return (
    <Modal onHide={onHide} titleBesideClose={"Profile Delete Request"}>
      <div className="cps-20 cpe-20 cpb-20 cpt-25">
        <div className="">
          <TextArea
            label="Describe Delete Reason"
            placeholder={"Enter reason"}
            rows={3}
            labelClass="text-15-400-18 color-black-olive"
            onChange={(e) => {
              setReason(trimLeftSpace(e.target.value));
            }}
          />
        </div>
        <div className="cmt-20 d-flex align-items-center gap-3 justify-content-start">
          <Button
            btnStyle="primary-dark"
            text="Submit"
            className="cps-20 cpe-20 h-40"
            disabled={!reason}
            btnLoading={isLoading}
            onClick={handleSubmit}
          />
          <Button
            btnStyle="light-outline"
            text="Back"
            className="cps-12 cpe-12 h-40"
            onClick={() => {
              setReason("");
              onHide();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ProfileDeleteRequestPopup;

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { forEach } from "lodash";
import Dropdown from "components/form/Dropdown";
import Card from "components/Layout/Card";
import { fetchAllEvents, fetchJournals, getDashboard } from "store/slices";
import { findOverallMax, getYearList, objectToFormData } from "utils/helpers";
import Loader from "components/Layout/Loader";
import ReactApexChart from "react-apexcharts";
import ResourceDashboardOverView from "./ResourceDashboardOverView";
import "./Dashboard.scss";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [pageLoading, setPageLoading] = useState(true);
  const [eventLoading, setEventLoading] = useState(true);
  const [journalLoading, setJournalLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState({});
  const [journalList, setJournalList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [year, setYear] = useState({
    members_analytic_year: moment().format("YYYY"),
    event_id: "",
    journal_id: "",
  });
  const getReviewerJournals = async () => {
    const response = await dispatch(fetchJournals(objectToFormData()));
    setJournalList(response?.data?.users || []);
    setJournalLoading(false);
  };
  const getReviewerEvents = async () => {
    const response = await dispatch(fetchAllEvents(objectToFormData()));
    setEventList(response?.data?.events || []);
    setEventLoading(false);
  };
  const fetchDashboardData = async (yearData) => {
    let queryParams = new URLSearchParams(yearData).toString();
    const response = await dispatch(getDashboard(`?${queryParams}`));
    setDashboardData(response?.data || {});
    setPageLoading(false);
  };

  useEffect(() => {
    getReviewerEvents();
    getReviewerJournals();
    fetchDashboardData(year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { members_analytics, abstract_statistics, journal_statistics } =
    dashboardData;

  // new code
  const newEventData = {
    series: [
      {
        data: [
          abstract_statistics?.event_pending,
          abstract_statistics?.event_plagiarized,
          abstract_statistics?.event_accepted,
          abstract_statistics?.event_registered,
          abstract_statistics?.event_un_registered,
          abstract_statistics?.event_rejected,
        ],
      },
    ],
  };
  const newJournalData = {
    series: [
      {
        data: [
          journal_statistics?.journal_pending,
          journal_statistics?.journal_plagiarized,
          journal_statistics?.journal_accepted,
          journal_statistics?.journal_registered,
          journal_statistics?.journal_un_registered,
          journal_statistics?.journal_rejected,
        ],
      },
    ],
  };

  let newMemberChart = {
    categories: [],
    data: [
      { name: "Student Members", data: [] },
      { name: "Professional Members", data: [] },
      { name: "Institutional Members", data: [] },
    ],
  };

  forEach(members_analytics, (items, key) => {
    if (items?.all_students !== undefined) {
      newMemberChart?.categories.push(
        `${moment()
          .month(key - 1)
          .format("MMM")} ${year?.members_analytic_year}`
      );
      newMemberChart?.data[0]?.data?.push(items?.all_students);
      newMemberChart?.data[1]?.data?.push(items?.all_professionals);
      newMemberChart?.data[2]?.data?.push(items?.all_institutions);
    }
  });

  const overallMaxForMember = findOverallMax(newMemberChart?.data, 0);

  return (
    <>
      <ResourceDashboardOverView />
      <div className="row" id="resourcse-dashboard-container">
        <div className="mt-3">
          {pageLoading ? (
            <Card className="cps-30 cpe-30 cpt-80 cpb-80">
              <Loader size="md" />
            </Card>
          ) : (
            <Card className="cps-30 cpe-30 cpt-30 cpb-30">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="text-18-600 color-black-olive">
                  Abstract Statistics
                </div>
                <div>
                  <Dropdown
                    options={eventList}
                    optionValue="event_name"
                    placeholder="Event"
                    value={year?.event_id}
                    isLoading={eventLoading}
                    onChange={(e) => {
                      let oldData = {
                        ...year,
                        event_id: e.target.value,
                      };
                      setYear(oldData);
                      fetchDashboardData(oldData);
                    }}
                  />
                </div>
              </div>
              <ReactApexChart
                options={{
                  height: 400,
                  type: "bar",
                  chart: {
                    toolbar: {
                      show: false,
                    },
                  },
                  plotOptions: {
                    bar: {
                      columnWidth: "75%",
                      distributed: true,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  legend: {
                    show: false,
                  },
                  colors: [
                    "#00B4CC",
                    "#00A0E7",
                    "#1E4CDA",
                    "#00B2FF",
                    "#6C56F0",
                    "#0543FF",
                  ],

                  xaxis: {
                    categories: [
                      "Pending",
                      "Plagarised",
                      "Accepted",
                      "Registered",
                      "Unregistered",
                      "Rejected",
                    ],
                    labels: {
                      style: {
                        colors: [
                          "#00B4CC",
                          "#00A0E7",
                          "#1E4CDA",
                          "#00B2FF",
                          "#6C56F0",
                          "#0543FF",
                        ],
                        fontSize: "15px",
                      },
                    },
                  },
                  yaxis: {
                    min: 0,
                    max: Math.max(...newEventData?.series[0]?.data) || 50,
                  },
                }}
                series={newEventData?.series}
                type="bar"
                height={400}
              />
            </Card>
          )}
        </div>
        <div className="mt-3">
          {pageLoading ? (
            <Card className="cps-30 cpe-30 cpt-80 cpb-80">
              <Loader size="md" />
            </Card>
          ) : (
            <Card className="cps-30 cpe-30 cpt-30 cpb-30">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="text-18-600 color-black-olive">
                  Journal Statistics
                </div>
                <div>
                  <Dropdown
                    options={journalList}
                    placeholder="Journal"
                    optionValue="name"
                    value={year?.journal_id}
                    isLoading={journalLoading}
                    onChange={(e) => {
                      let oldData = {
                        ...year,
                        journal_id: e.target.value,
                      };
                      setYear(oldData);
                      fetchDashboardData(oldData);
                    }}
                  />
                </div>
              </div>
              <ReactApexChart
                options={{
                  height: 400,
                  type: "bar",
                  chart: {
                    toolbar: {
                      show: false,
                    },
                  },
                  plotOptions: {
                    bar: {
                      columnWidth: "75%",
                      distributed: true,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  legend: {
                    show: false,
                  },
                  colors: [
                    "#00B4CC",
                    "#00A0E7",
                    "#1E4CDA",
                    "#00B2FF",
                    "#6C56F0",
                    "#0543FF",
                  ],

                  xaxis: {
                    categories: [
                      "Pending",
                      "Plagarised",
                      "Accepted",
                      "Registered",
                      "Unregistered",
                      "Rejected",
                    ],
                    labels: {
                      style: {
                        colors: [
                          "#00B4CC",
                          "#00A0E7",
                          "#1E4CDA",
                          "#00B2FF",
                          "#6C56F0",
                          "#0543FF",
                        ],
                        fontSize: "15px",
                      },
                    },
                  },
                  yaxis: {
                    min: 0,
                    max: Math.max(...newJournalData?.series[0]?.data) || 50,
                  },
                }}
                series={newJournalData?.series}
                type="bar"
                height={400}
              />
            </Card>
          )}
        </div>

        <div className="mt-3">
          <Card className="cps-30 cpe-30 cpt-30 cpb-30">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div className="text-18-600 color-black-olive">
                IFERP Member’s Analytics
              </div>
              <div className="d-flex">
                <Dropdown
                  options={getYearList(10).map((o) => {
                    return { ...o, name: o.id };
                  })}
                  optionValue="name"
                  onChange={(e) => {
                    let oldData = {
                      ...year,
                      members_analytic_year: e.target.value,
                    };
                    setYear(oldData);
                    fetchDashboardData(oldData);
                  }}
                  value={year?.members_analytic_year}
                  placeholder="Year"
                />
              </div>
            </div>
            <ReactApexChart
              options={{
                height: 400,
                type: "line",
                chart: {
                  toolbar: {
                    show: false,
                  },
                },
                dropShadow: {
                  enabled: true,
                  color: "#000",
                  top: 18,
                  left: 7,
                  blur: 10,
                  opacity: 0.2,
                },
                colors: ["#2479E0", "#55C7FA", "#8992e0"],
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "smooth",
                  width: 3,
                },
                xaxis: {
                  categories: newMemberChart?.categories,
                },
                yaxis: {
                  min: 0,
                  max: overallMaxForMember || 500,
                },
                legend: { show: true },
              }}
              series={newMemberChart?.data}
              type="line"
              height={400}
            />
          </Card>
        </div>
      </div>
    </>
  );
};
export default Dashboard;

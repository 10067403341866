import Card from "components/Layout/Card";
import React from "react";
import { getDataFromLocalStorage, getEventDate } from "utils/helpers";

const NewAbstractBasicDetails = ({ eventData }) => {
  const educationalDetails = getDataFromLocalStorage("educational_details");
  const { event_name, start_date, end_date } = eventData;
  const {
    phd_university_name,
    phd_department_name,
    phd_course_name,
    pg_university_name,
    pg_department_name,
    pg_course_name,
    ug_university_name,
    ug_department_name,
    ug_course_name,
  } = educationalDetails;

  const university =
    phd_university_name || pg_university_name || ug_university_name;
  const department =
    phd_department_name || pg_department_name || ug_department_name;
  const course = phd_course_name || pg_course_name || ug_course_name;
  return (
    <div className="col-md-4">
      <Card className="cps-20 cpe-20 cpt-30 cpb-20">
        <div className="text-14-400-18 color-new-car">Conference Name:</div>
        <p className="text-14-400-24 color-black-olive">{event_name}</p>
        <div className="text-14-400-18 color-new-car">Date:</div>
        <p className="text-14-400-24 color-black-olive">
          {getEventDate(start_date, end_date)}
        </p>
        <div className="text-14-400-18 color-new-car">University:</div>
        <p className="text-14-400-24 color-black-olive">{university}</p>
        <div className="text-14-400-18 color-new-car">Course:</div>
        <p className="text-14-400-24 color-black-olive">{course}</p>
        <div className="text-14-400-18 color-new-car">Department:</div>
        <p className="text-14-400-24 color-black-olive">{department}</p>
      </Card>
    </div>
  );
};

export default NewAbstractBasicDetails;
